import { useEffect, useState, } from 'react'
import moment from 'moment'
import { getEndDate, getStartDate, } from '../utilits/dataUtilit'

const getValueByType = (type, value, excludeField, currentItem,) => {
	if (type === 'number') return { [currentItem.id]: parseInt(value, 10,), }
	if (type === 'date') {
		if (value && typeof value === 'string') {
			if (currentItem.format) {
				return { [currentItem.id]: moment(value, currentItem.format,).format(), }
			}
			return { [currentItem.id]: moment(new Date(value,),).format(), }
		}
		if (value) {
			return { [currentItem.id]: typeof value === 'string' ? moment(new Date(value,),).format() : value.format(), }
		}
		return { [currentItem.id]: null, }
	}
	if (type === 'range') {
		const keys = []
		const values = currentItem.id.split('&',).reduce((acc, key, idx,) => {
			keys.push(key,)
			if (value[idx]) {
				return [...acc, typeof value[idx] === 'string' ? moment(new Date(value[idx],),).format() : value[idx].format(), ]
			}
			return acc
		}, [],)
		if (!values[0]) return keys.reduce((acc, key,) => ({ ...acc, [key]: null, }), {},)
		if (values.length === 2) {
			return keys.reduce((acc, key, idx,) => ({ ...acc, [key]: values[idx], }), {},)
		}
		return keys.reduce((acc, key, idx,) => {
			const val = values[0]
			const formatDate = idx === 0 ? getStartDate : getEndDate
			return ({
				...acc,
				[key]: formatDate(moment(val,),).format(),
			})
		}, {},)
	}
	if (type === 'dateSwitch') {
		if (value) {
			return { [currentItem.id]: typeof value === 'string' ? moment(new Date(value,),).format() : value.format(), }
		}
		return null
	}

	if (type === 'kit') {
		return {
			[currentItem.id]: value.reduce((acc, content,) => [
				...acc,
				currentItem.fields.reduce((fieldAcc, item,) => ({
					...fieldAcc,
					// eslint-disable-next-line max-len
					...getValueByType(item.type, content[item.id], item.excludeField, item,),
				}), {},),
			], [],),
		}
	}

	if (excludeField && value && Array.isArray(value,)) {
		return {
			[currentItem.id]: value.map((item,) => {
				const val = { ...item, }
				excludeField.forEach((field,) => delete val[field],)
				return val
			},),
		}
	}
	if (excludeField && value && !Array.isArray(value,)) {
		const val = { ...value, }
		excludeField.forEach((field,) => delete val[field],)
		return { [currentItem.id]: val, }
	}

	if (excludeField && value && Array.isArray(value,)) {
		return {
			[currentItem.id]: value.map((item,) => {
				const val = { ...item, }
				excludeField.forEach((field,) => delete val[field],)
				return val
			},),
		}
	}

	return { [currentItem.id]: value, }
}

export const useInputs = (defaultState, onSubmit, onUpdate,) => {
	const [state, updateState, ] = useState([...defaultState, ],)
	const [isDisabled, updateDisabled, ] = useState(true,)

	const onPressSubmit = (e,) => {
		if (e) e.preventDefault()
		if (e) e.stopPropagation()
		let isErrorExist = false
		updateState(state.reduce((acc, item,) => {
			let error = item.validator ? item.validator(item,) : ''

			if (item.notRequired && !item.value) {
				error = ''
			}

			if (error) isErrorExist = true
			return [...acc, {
				...item,
				error,
			}, ]
		}, [],),)
		if (!isErrorExist && !isDisabled) {
			onSubmit(
				state.reduce((acc, item,) => (item.notRequired && !item.value ? acc : ({
					...acc,
					...getValueByType(item.type, item.value, item.excludedField, item,),
				})), {},),
			)
		}
	}

	const onKeyPress = (event,) => {
		if (event.key === 'Enter') {
			onPressSubmit()
		}
	}

	const onDisabled = (newState,) => {
		// eslint-disable-next-line max-len
		updateDisabled(newState.reduce((acc, item,) => (acc || item.validator ? JSON.stringify(item.value,) === JSON.stringify(item.defaultValue,) : false), false,),)
	}

	const onChange = (value, id,) => {
		const newState = state.reduce((acc, item,) => [...acc, {
			...item,
			value: item.id === id ? value : item.value,
			error: item.id === id ? '' : item.error,
		}, ], [],)
		updateState(newState,)
		onDisabled(newState,)
	}

	const onChangeFew = (values, ids,) => {
		const newState = state.reduce((acc, item,) => [...acc, {
			...item,
			value: ids.indexOf(item.id,) >= 0 ? values[ids.indexOf(item.id,)] : item.value,
			error: ids.indexOf(item.id,) >= 0 ? '' : item.error,
		}, ], [],)
		updateState(newState,)
		onDisabled(newState,)
	}

	const onValidate = (value, id,) => {
		updateState(state.reduce((acc, item,) => {
			let error = ''
			if (item.type === 'kit') {
				error = item.validator ? item.validator(item,) : ''
			} else if (item.type === 'date') {
				error = item.validator ? item.validator(item,) : ''
			} else if (item.type === 'dateSwitch') {
				error = item.validator ? item.validator(item,) : ''
			} else {
				error = item.validator ? item.validator(item,) : ''
			}

			return [...acc, {
				...item,
				error: item.id === id ? error : item.error,
			}, ]
		}, [],),)
	}

	const onSave = (values,) => {
		updateState(state.reduce((acc, item,) => [...acc, {
			...item,
			value: values[item.id] !== undefined ? values[item.id] : item.value,
			error: '',
		}, ], [],),)
	}

	const onAdd = (val, id,) => {
		const item = state.reduce((acc, variable,) => (variable.id === id ? variable : acc), null,)
		onUpdate('add', getValueByType(item.type, val, item.excludedField, item,)[id], id,)
	}

	const onDelete = (currentId, id,) => {
		onUpdate('delete', currentId, id,)
	}

	const onUpdateState = (content,) => {
		updateState(content,)
	}

	useEffect(() => {
		onDisabled(defaultState,)
	}, [defaultState, ],)

	return {
		fields: state,
		onChange,
		onChangeFew,
		onKeyPress,
		onPressSubmit,
		onUpdateState,
		onValidate,
		onSave,
		onAdd,
		onDelete,
		isDisabled,
	}
}
