// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import moment from 'moment'
import { isDayAfter, isDayBefore, isDayEqual, isDayRange, formatDate, } from '../utils/DateUtil'
import { DatePickerDefaults, } from '../common/Constant'
import Picker from './Picker'
import RangePickerInput from './RangePickerInput'
import Calendar from './Calendar'
import { ifExistCall, } from '../utils/FunctionUtil'

const RangeDatePicker = ({
	dateFormat = DatePickerDefaults.dateFormat,
	portal = false,
	includeTime = true,
	initialDate = moment(),
	showMonthCnt = 2,
	startText = '',
	endText = '',
	initialStartDate,
	initialEndDate,
	customDayText,
	customDayClass,
	onChange,
	placeholder,
	readOnly,
	disabled,
	clear,
	wrapper,
	direction,
	...props
},) => {
	const [state, updateState, ] = useState({
		start: initialStartDate,
		end: initialEndDate,
		startValue: formatDate(initialStartDate, dateFormat,),
		endValue: formatDate(initialEndDate, dateFormat,),
	},)

	const handleDateChange = (actions,) => async (date,) => {
		const { start, end, } = state
		let startDate = start
		let endDate = end

		if (!start) {
			startDate = date
		} else if (end) {
			startDate = date
			endDate = undefined
		} else if (!isDayBefore(date, start,)) {
			endDate = date
		} else {
			startDate = date
		}

		ifExistCall(onChange, [startDate, endDate, ],)

		await updateState(
			{
				...state,
				start: startDate,
				end: endDate,
				startValue: formatDate(startDate, dateFormat,),
				endValue: formatDate(endDate, dateFormat,),
			},
		)
		if (state.start && state.end) {
			actions.hide()
		}
	}

	const handleInputChange = (fieldType, value,) => updateState({
		...state,
		[fieldType === 'START' ? 'startValue' : 'endValue']: value,
	},)

	const handleMouseOver = (date,) => updateState({
		...state,
		hoverDate: date,
	},)

	const handleClear = () => {
		updateState({
			start: initialStartDate,
			end: initialEndDate,
			startValue: formatDate(initialStartDate, dateFormat,),
			endValue: formatDate(initialEndDate, dateFormat,),
		},)
		ifExistCall(onChange, [],)
	}

	const handleInputBlur = (fieldType, value,) => {
		const { start, end, } = state
		const parsedDate = moment(value, dateFormat,)
		let startDate = start
		let endDate = end

		if (parsedDate.isValid() && dateFormat.length === value.length) {
			if (fieldType === 'END') {
				endDate = parsedDate
			} else if (fieldType === 'START') {
				startDate = parsedDate
			}
		}

		if (startDate && endDate) {
			if (isDayBefore(endDate, startDate,) || isDayAfter(startDate, endDate,)) {
				// Swapping Date
				const temp = startDate
				startDate = endDate
				endDate = temp
			}
		}

		updateState({
			...state,
			start: startDate,
			end: endDate,
			startValue: formatDate(startDate, dateFormat,),
			endValue: formatDate(endDate, dateFormat,),
		},)
	}

	const handleCalendarText = (date,) => {
		const { start, end, } = state
		if (isDayEqual(start, date,)) return startText
		if (isDayEqual(end, date,)) return endText
		ifExistCall(customDayText, date,)
		return ''
	}

	const handleCalendarClass = (date,) => {
		const { start, end, hoverDate, } = state
		if (start && !end && hoverDate) {
			if (isDayRange(date, start, hoverDate,)) {
				return 'calendar__day--range'
			}
		}
		ifExistCall(customDayClass, date,)
		return ''
	}

	const renderRangePickerInput = () => (
		<RangePickerInput
			placeholder={placeholder}
			readOnly={readOnly}
			disabled={disabled}
			clear={clear}
			startValue={state.startValue}
			endValue={state.endValue}
			onChange={handleInputChange}
			onBlur={handleInputBlur}
			onClear={handleClear}
		/>
	)

	const renderCalendar = (actions,) => {
		let component

		const calendar = (
			// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
			<Calendar
				{...{
					dateFormat,
					portal,
					initialDate,
					showMonthCnt,
					startText,
					endText,
					initialStartDate,
					initialEndDate,
					customDayText,
					customDayClass,
					onChange,
					readOnly,
					disabled,
					clear,
					wrapper,
					direction,
					includeTime,
					...props,
				}}
				base={state.start || initialDate}
				startDay={state.start}
				endDay={state.end}
				showMonthCnt={showMonthCnt}
				onChange={handleDateChange(actions,)}
				onMouseOver={handleMouseOver}
				customDayText={handleCalendarText}
				customDayClass={handleCalendarClass}
			/>
		)

		component = calendar

		if (wrapper) {
			component = wrapper(calendar,)
		}

		return component
	}

	return (
		<Picker
			portal={portal}
			direction={direction}
			readOnly={readOnly}
			disabled={disabled}
			className="picker-range"
			renderTrigger={() => renderRangePickerInput()}
			renderContents={({ actions, },) => renderCalendar(actions,)}
		/>
	)
}

export default RangeDatePicker
