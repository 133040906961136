import { gql, } from 'apollo-boost'

export const GET_WHO_AM_I_API = gql`
	query whoiamBlock {
		user: whoiam {
			user {
				id
				firstName
				middleName
				lastName
				phone
				email
				blockedDate
				status
				rights
			}
			contact {
				firstName
				middleName
				lastName
				phone
				email
			}
			company {
				name
				balanceValue
				balanceLimit
				postpay
				placesUsed
				placesTotal
				cardUsed
				cardTotal
				blockedDate
				tariff {
					area
					cost
					interval
				}
				contact {
					firstName
					middleName
					lastName
					phone
					email
				}
			}
		}
	}
`

export const GET_USERS_API = gql`
	query getUsersBlocks($from: Int!, $size: Int!, $sort: UserSort) {
		users: userSearch(pagination: {from: $from, size: $size}, sort: $sort) {
			items {
				id
				firstName
				middleName
				lastName
				phone
				email
				blockedDate
				status
				rights
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const GET_USER_ACTIONS_API = gql`
	query getUserActionsBlocks(
		$name: String,
		$inputFrom: Time,
		$inputTo: Time,
		$actionType: [ActionType!],
		$objectType: [ObjectType!],
		$from: Int!,
		$size: Int!,
		$field: UserActionSortField!,
		$direction: SortDirection!,
	) {
		users: userActions(
			input: {
				name: $name,
				from: $inputFrom,
				to: $inputTo,
				actionType: $actionType,
				objectType: $objectType,
			},
			pagination: {from: $from, size: $size},
			sort: {
				field: $field,
				direction: $direction,
			},
		) {
			items {
				createdAt
				userFirstName
				userLastName
				userMiddleName
				actionType
				objectType
				data {
					fieldName
					oldValue
					newValue
				}
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const ADD_USER_API = gql`
	mutation addUser(
		$firstName:   String!
		$middleName:  String!
		$lastName:    String!
		$phone:       String!
		$email:       String!
		$password:    String
		$blockedDate: Time
		$rights:      [UserRight!]!
	) {
		user: addUser(input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			phone: $phone
			email: $email
			password: $password
			blockedDate: $blockedDate
			status: true
			rights: $rights
		}) {
			id
		}
	}
`
export const UPDATE_USER_API = gql`
	mutation updateUser(
		$id:   Int!
		$firstName:   String!
		$middleName:  String!
		$lastName:    String!
		$phone:       String!
		$email:       String!
		$password:    String
		$blockedDate: Time
		$status:      Boolean!
		$rights:      [UserRight!]!
	) {
		user: updateUser(id: $id, input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			phone: $phone
			email: $email
			blockedDate: $blockedDate
			status: $status
			rights: $rights
			password: $password
		}) {
			id
			firstName
			middleName
			lastName
			phone
			email
			blockedDate
			status
			rights
		}
	}
`

export const DELETE_USER_API = gql`
	mutation updateUser(
		$id:   Int!
	) {
		user: deleteUser(id: $id)
	}
`
