// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, } from 'react'
import cn from 'classnames'
import styles from './boolButton.module.css'

const 	BoolButtonElement = ({
	id,
	value,
	className,
	placeholder,
	onChange,
},) => (
	<div
		className={cn(
			styles.wrapper,
			value && styles.active,
			className,
		)}
	>
		{/* eslint-disable-next-line max-len */}
		{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
		<div className={cn(styles.button,)} onClick={() => onChange(!value ? true : null, id,)}>
			{placeholder}
		</div>
	</div>
)

export default BoolButtonElement
