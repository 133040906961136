import { gql, } from 'apollo-boost'

export const GET_BOOKING_TYPE_API = gql`
	query getBookingTypeListBlocks {
		cards: getBookingTypeList {
			id
			name
			description
		}
	}
`

export const ADD_GUEST_API = gql`
	mutation registerBooking(
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$email: String!
		$vehicleModel: String!
		$vehicleLicensePlate: String!
		$comment:       String!
		$validUntil:    Time
		$bookingTypeId: Int!
	) {
		guest: registerBooking(input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			email: $email
			vehicleModel: $vehicleModel
			vehicleLicensePlate: $vehicleLicensePlate
			comment: $comment
			validUntil: $validUntil
			bookingTypeId: $bookingTypeId
		}) {
			id
			qrCode
		}
	}
`
export const UPDATE_GUEST_API = gql`
	mutation updateBooking(
		$id: Int!
		$createdAt: Time!
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$email: String!
		$vehicleModel: String!
		$vehicleLicensePlate: String!
		$comment:       String!
		$validUntil:    Time
		$bookingTypeId: Int!
	) {
		guest: updateBooking(id: $id, createdAt: $createdAt, input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			email: $email
			vehicleModel: $vehicleModel
			vehicleLicensePlate: $vehicleLicensePlate
			comment: $comment
			validUntil: $validUntil
			bookingTypeId: $bookingTypeId
		}) {
			id
			qrCode
		}
	}
`

export const GET_GUEST_API = gql`
	query guestSearchBlocks(
		$name: String # Фамилия клиента,
		$licensePlate: String # Номер ТС,
		$clientType: [ClientType!] # Тип клиента,
		$createdAtFrom: Time # Начало периода создания,
		$createdAtTo: Time # Окончание периода создания,
		$startSessionFrom: Time # Начало периода въезда,
		$startSessionTo: Time # Окончание периода въезда,
		$endSessionFrom: Time # Начало периода выезда,
		$endSessionTo: Time # Окончание периода выезда,
		$from: Int!,
		$size: Int!,
		$field: SessionSearchSortField!,
		$direction: SortDirection!,
	) {
		guests: guestSearch(
			input: {
				name: $name,
				licensePlate: $licensePlate,
				clientType: $clientType,
				createdAtFrom: $createdAtFrom,
				createdAtTo: $createdAtTo,
				startSessionFrom: $startSessionFrom,
				startSessionTo: $startSessionTo,
				endSessionFrom: $endSessionFrom,
				endSessionTo: $endSessionTo,
			},
			pagination: {
				from: $from,
				size: $size
			},
			sort: {
				field: $field,
				direction: $direction,
			},
		) {
			items {
				i
				id
				clientType
				start
				end
				companyPayment
				transfer {
					datetime
					terminalType
					terminal
					area
					paymentAmount
					licensePlate
					clientIdType
					clientId
					photo
				}
				balance {
					value
					comment
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
				}
				booking {
					name
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
					description
					id
					guest {
						firstName
						middleName
						lastName
						email
					}
					vehicle {
						model
						licensePlate
					}
					comment
					validUntil
					bookingTypeId
					qrCode
					area
				}
				discount {
					name
					comment
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
					description
					discountTypeId
				}
				employee {
					firstName
					middleName
					lastName
				}
				vehicle {
					model
					licensePlate
					cardId
					rfidId
				}
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const GET_SESSION_API = gql`
	query sessionSearchBlocks(
		$name: String # Фамилия клиента,
		$licensePlate: String # Номер ТС,
		$clientType: [ClientType!] # Тип клиента,
		$createdAtFrom: Time # Начало периода создания,
		$createdAtTo: Time # Окончание периода создания,
		$startSessionFrom: Time # Начало периода въезда,
		$startSessionTo: Time # Окончание периода въезда,
		$endSessionFrom: Time # Начало периода выезда,
		$endSessionTo: Time # Окончание периода выезда,
		$from: Int!,
		$size: Int!,
		$field: SessionSearchSortField!,
		$direction: SortDirection!,
	) {
		guests: sessionSearch(
			input: {
				name: $name,
				licensePlate: $licensePlate,
				clientType: $clientType,
				createdAtFrom: $createdAtFrom,
				createdAtTo: $createdAtTo,
				startSessionFrom: $startSessionFrom,
				startSessionTo: $startSessionTo,
				endSessionFrom: $endSessionFrom,
				endSessionTo: $endSessionTo,
			},
			pagination: {
				from: $from,
				size: $size
			}
			sort: {
				field: $field,
				direction: $direction,
			},
		) {
			items {
				i
				id
				clientType
				start
				end
				companyPayment
				transfer {
					datetime
					terminalType
					terminal
					area
					paymentAmount
					licensePlate
					clientIdType
					clientId
					photo
				}
				balance {
					value
					comment
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
				}
				booking {
					name
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
					description
					id
					guest {
						firstName
						middleName
						lastName
						email
					}
					vehicle {
						model
						licensePlate
					}
					comment
					validUntil
					bookingTypeId
					qrCode
				}
				discount {
					name
					comment
					createdAt
					createdBy {
						firstName
						middleName
						lastName
					}
					description
					discountTypeId
				}
				employee {
					firstName
					middleName
					lastName
				}
				vehicle {
					model
					licensePlate
					cardId
					rfidId
				}
			}
			pagination {
				from
				size
				total
			}
		}
	}
`
