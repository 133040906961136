// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import moment from 'moment'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import IconElement from '../../elements/icon/IconElement'
import Camera from '../../assets/images/camera.png'
import {
	ICON_DELETE,
	ICON_EDIT, ICON_MAIL, ICON_REFRESH,
	ICON_SESSION_1,
	ICON_SESSION_2,
	ICON_SESSION_3,
	ICON_SESSION_4,
} from '../../constants/iconConstants'
import { numToPriceUtil, } from '../../utilits/numToPriceUtilits'
import { imageLink, } from '../../utilits/envUtilit'
import styles from './currentGuest.module.css'
import TextModalComponent from '../modal/TextModalComponent'
import TooltipElement from '../../elements/tooltip/TooltipElement'
import { SESSIONS_BOOKING, } from '../../constants/sessionsConstants'

const HeaderElement = ({
	fields,
},) => (
	<div className={styles.list}>
		{fields.map((item, index,) => (
			// eslint-disable-next-line react/jsx-key,react/no-array-index-key
			<table key={index}>
				{item.map(({ name, value, },) => (
					<tr key={name}>
						<td className={styles.key}>{name}</td>
						<td className={styles.value}>{value}</td>
					</tr>
				),)}
			</table>
		),)}
	</div>
)

const getContent = (id, vehicle,) => {
	if (id === 'CARD') return `Карта: ${vehicle.cardId}`
	if (id === 'RFID') return `RFID: ${vehicle.rfidId}`
	if (id === 'LICENSE_PLATE') return `Номер ТС: ${vehicle.licensePlate}`
	return ''
}

const TransfersElement = ({
	transfer = [],
	vehicle = {},
	id,
},) => {
	const [openedUrl, updateOpenedUrl, ] = useState(null,)
	return (
		<div className={styles.list}>
			{!!openedUrl && (
				<Lightbox
					mainSrc={openedUrl}
					onCloseRequest={() => updateOpenedUrl(null,)}
				/>
			)}
			<div className={styles.session}>
				<div className={styles.title}>Сессия</div>
				<table>
					<thead>
						<tr>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<th />
							<th>Время</th>
							<th>Место действия</th>
							<th>Зона</th>
							<th>Стоимость</th>
							<th>Номер ТС</th>
							<th>Фото</th>
						</tr>
					</thead>
					<tbody>
						{transfer.map((item,) => (
							<tr key={item.datetime}>
								<td className={styles.type}>
									<TooltipElement title={getContent(item.clientIdType, vehicle,)}>
										<span>
											{item.terminalType === 'ENTRY' && (<IconElement name={ICON_SESSION_1} />)}
											{item.terminalType === 'EXIT' && (<IconElement name={ICON_SESSION_4} />)}
											{item.terminalType === 'TRANSIT' && (<IconElement name={ICON_SESSION_2} />)}
											{item.terminalType === 'PAYMENT' && (<IconElement name={ICON_SESSION_3} />)}
										</span>
									</TooltipElement>
								</td>
								<td>
									{item.datetime ? moment(item.datetime,)
										.format('DD.MM.YYYY HH:mm:ss',) : 'Нет'}
								</td>
								<td>{item.terminal}</td>
								<td>{item.area}</td>
								<td>{numToPriceUtil(item.paymentAmount, '₽',)}</td>
								<td>{item.licensePlate}</td>
								<td className={styles.photo}>
									{!!item.photo && (
										// eslint-disable-next-line max-len
										// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
										<img
											onClick={() => updateOpenedUrl(imageLink(id, item.photo,),)}
											src={Camera}
											className={styles.image}
											alt=""
										/>
									)}
									{!item.photo && (
										<img src={Camera} className={styles.disabledImage} alt="" />
									)}
								</td>
							</tr>
						),)}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const HeaderControls = ({ onDelete, onEdit, onRecreate, onResend, isEnd, isActive, },) => {
	const [modalOpen, updateStateModal, ] = useState(null,)
	return (
		<ul className={styles.options}>
			<TextModalComponent
				isOpen={modalOpen === 'delete'}
				onClose={() => updateStateModal(null,)}
				title="Удалить приглашение?"
				textButton="Удалить"
				cancelButton="Отмена"
				onClick={() => {
					onDelete()
					updateStateModal(null,)
				}}
			/>
			<TextModalComponent
				isOpen={modalOpen === 'resend'}
				onClose={() => updateStateModal(null,)}
				title="Повторно отправить приглашение?"
				textButton="Отправить"
				cancelButton="Отмена"
				onClick={() => {
					onResend()
					updateStateModal(null,)
				}}
			/>

			{isActive && (
				<>
					{isEnd && (
						<li>
							{/* eslint-disable-next-line max-len */}
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
							<span onClick={onRecreate} className={styles.isEdit}><IconElement name={ICON_REFRESH} /></span>
						</li>
					)}
					{!isEnd && (
						<>
							<li>
								{/* eslint-disable-next-line max-len */}
								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
								<span onClick={() => updateStateModal('resend',)} className={styles.isEdit}><IconElement name={ICON_MAIL} /></span>
							</li>
							<li>
								{/* eslint-disable-next-line max-len */}
								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
								<span onClick={onEdit} className={styles.isEdit}><IconElement name={ICON_EDIT} /></span>
							</li>
						</>
					)}
					<li>
						{/* eslint-disable-next-line max-len */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
						<span onClick={() => updateStateModal('delete',)} className={styles.isDelete}><IconElement name={ICON_DELETE} /></span>
					</li>
				</>
			)}
		</ul>
	)
}

const 	CurrentGuestComponent = ({
	transfer,
	fields,
	type,
	content,
	vehicle,
	currentId,
	onEdit,
	onDelete,
	onRecreate,
	onResend,
},) => (
	<div className={styles.row}>
		{type === SESSIONS_BOOKING && (
			<HeaderControls
				isEnd={!!content.end}
				isActive={!!content.isActive}
				onEdit={onEdit}
				onDelete={onDelete}
				onRecreate={onRecreate}
				onResend={onResend}
			/>
		)}
		<HeaderElement fields={fields} />
		{!!transfer.length && (
			<TransfersElement transfer={transfer} id={currentId} vehicle={vehicle} />
		)}
	</div>
)
export default CurrentGuestComponent
