// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import TimeInput from './TimeInput'
import { ifExistCall, } from '../utils/FunctionUtil'

const TimeContainer = ({
	hour,
	minute,
	onBlur,
	onChange,
},) => {
	const [state, updateState, ] = useState({
		hour: hour || 0,
		minute: minute || 0,
	},)

	const handleBlur = () => {
		console.log(state.hour, state.minute,)
		ifExistCall(onBlur, state.hour, state.minute,)
	}

	const invokeOnChange = (content,) => {
		console.log(content.hour, content.minute,)
		ifExistCall(onChange, content.hour, content.minute,)
	}

	const handleChange = (item,) => async (e,) => {
		const min = 0
		const max = item === 'hour' ? 23 : 59
		let value = parseInt(e.currentTarget.value, 10,)

		// eslint-disable-next-line no-restricted-globals
		if (isNaN(value,)) {
			value = 0
		}

		if (max < value) {
			value = max
		}

		if (min > value) {
			value = min
		}
		const content = {
			...state,
			[item]: value,
		}

		await updateState(content,)

		invokeOnChange(content,)
	}

	return (
		<div className="time__container">
			<TimeInput
				onChange={handleChange('hour',)}
				onBlur={handleBlur}
				value={state.hour}
			/>
			<div className="time__container__div">:</div>
			<TimeInput
				onChange={handleChange('minute',)}
				onBlur={handleBlur}
				value={state.minute}
			/>
		</div>
	)
}

export default TimeContainer
