export const ACTION_NEW = 'NEW'
export const ACTION_EDIT = 'EDIT'
export const ACTION_DELETE = 'DELETE'
export const ACTION_ACTIVATION = 'ACTIVATION'
export const ACTION_DEACTIVATION = 'DEACTIVATION'
export const ACTION_LOST = 'LOST'

export const ACTION_DISCOUNT = 'DISCOUNT'
export const ACTION_BOOKING = 'BOOKING'
export const ACTION_EMPLOYEE = 'EMPLOYEE'
export const ACTION_VEHICLE = 'VEHICLE'
export const ACTION_USER = 'USER'
