// eslint-disable-next-line no-unused-vars
import React from 'react'
import FormContentContainer from '../../containers/form/FormContentContainer'
import styles from './filter.module.css'

const FilterElement = ({
	onDownload,
	onUpdate = () => {},
	fields,
	buttonName,
},) => (
	<FormContentContainer
		content={fields}
		onSubmit={onUpdate}
		onClickNext={onDownload}
		formClassName={styles.filter}
		buttonWrapperClassName={styles.filterRight}
		inputWrapperClassName={styles.filterContainer}
		buttonText={fields.length ? (buttonName || 'Поиск') : ''}
		nextButtonText="Выгрузить"
	/>
)

export default FilterElement
