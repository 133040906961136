// eslint-disable-next-line no-unused-vars
import React from 'react'
import classNames from 'classnames'

const TodayPanel = ({ today, show, onClick, },) => (
	<div className={classNames('calendar__panel--today', { 'calendar__panel--show': show, },)}>
		{/* eslint-disable-next-line max-len */}
		{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
		<h2 onClick={onClick}>{today}</h2>
	</div>
)

export default TodayPanel
