// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import cn from 'classnames'
import IconElement from '../icon/IconElement'
import styles from './range.module.css'
import RangeDatePicker from '../../components/DateTimePicker/components/RangeDatePicker'
import globalStyles from '../../assets/global.module.css'

const 	RangeElement = ({
	id,
	name,
	value,
	label,
	disabled,
	className,
	iconName,
	format = 'DD.MM.YYYY HH:mm',
	error,
	placeholder,
	onChange,
},) => {
	const onChangeElement = (val,) => onChange(val, id,)
	return (
		<div className={cn(className, styles.input, label && styles.labelWrapper,)}>
			{iconName && <IconElement className={cn(styles.inputIcon,)} name={iconName} />}
			{label && <div className={styles.label}>{label}</div>}
			<RangeDatePicker
				onChange={onChangeElement}
				dateFormat={format}
				disabled={disabled}
				placeholder={placeholder}
				value={value}
				portal={false}
				clear
			/>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}

export default RangeElement
