// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, } from 'react'
import cn from 'classnames'
import IconElement from '../icon/IconElement'
import { ICON_ARROW, } from '../../constants/iconConstants'
import { useOnClickOutside, } from '../../hooks/useOnClickOutside'
import styles from './checkbox.module.css'
import globalStyles from '../../assets/global.module.css'

const getValues = (values,) => values.reduce((acc, item,) => ({ ...acc, [item]: true, }), {},)

const 	CheckboxElement = ({
	id,
	value,
	className,
	isButton = false,
	label,
	options,
	error,
	placeholder,
	onChange,
},) => {
	const [state, updateState, ] = useState(getValues(value,),)
	const [isActive, updateActive, ] = useState(false,)
	const ref = useRef(null,)

	useEffect(() => onChange(
		Object.keys(state,).reduce((acc, key,) => (state[key] ? [...acc, key, ] : acc), [],), id,
	), [state, ],)

	useOnClickOutside(ref, () => updateActive(false,),)

	return (
		<div
			className={cn(
				styles.wrapper,
				isActive && styles.active,
				label && styles.labelWrapper,
				className,
			)}
			ref={isButton ? ref : null}
		>
			{label && <div className={styles.label}>{label}</div>}
			{isButton && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<div className={cn(styles.button,)} onClick={() => updateActive(!isActive,)}>
					<div>{placeholder}</div>
					<IconElement
						className={styles.inputIcon}
						name={ICON_ARROW}
					/>
				</div>
			)}
			<div
				className={cn(
					styles.checkboxInner,
					isButton && styles.checkboxDropdown,
				)}
			>
				{options.map(({ key, val, },) => (
					<div key={key} className={styles.checkbox}>
						<input
							type="checkbox"
							id={key}
							name={key}
							value={!!state[key]}
							checked={!!state[key]}
							onChange={() => updateState({ ...state, [key]: !state[key], },)}
						/>
						<label className={cn(styles.error,)} htmlFor={key}>
							{val}
						</label>
					</div>
				),)}
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				{!!error && (
					// eslint-disable-next-line jsx-a11y/label-has-associated-control
					<label className={cn(globalStyles.labelError,)}>
						{error}
					</label>
				)}
			</div>
		</div>
	)
}

export default CheckboxElement
