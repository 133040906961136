import * as moment from 'moment'
import { getVehicleMass, } from './getVehicleUtilit'

export const getCustomDataUtil = (data,) => (fields,) => fields.map((content,) => {
	if (content.type === 'date') {
		return ({
			...content,
			value: data[content.id] ? moment(data[content.id],).format('DD.MM.YYYY',) : content.value,
		})
	}
	if (content.type === 'rows') {
		return ({
			...content,
			// eslint-disable-next-line max-len
			value: Object.keys(content.value,).reduce((acc, key,) => ({ ...acc, [key]: data[key], }), {},),
		})
	}

	return ({
		...content,
		value: data[content.id] || content.value,
	})
},)

// eslint-disable-next-line max-len
export const getEmployeeDataUtil = (cards, data, { variables, validation, addVehicle, updateVehicle, },) => (fields,) => fields.map((content,) => {
	if (content.type === 'kit') {
		return ({
			...content,
			value: data.vehicleList ? [...data.vehicleList, ] : [...content.value, ],
			error: variables && variables.vehicleList.map((item, index,) => {
				// eslint-disable-next-line max-len
				if (addVehicle[index] !== undefined && validation.addVehicle[addVehicle[index]]) {
					return {
						// eslint-disable-next-line max-len
						car: validation.addVehicle[addVehicle[index]].licensePlate || validation.addVehicle[addVehicle[index]].model
							? `${validation.addVehicle[addVehicle[index]].licensePlate || ''} ${validation.addVehicle[addVehicle[index]].model || ''}` : '',
						cardId: validation.addVehicle[addVehicle[index]].cardId || '',
					}
				}
				return {
					car: '',
					cardId: '',
				}
			},),
			fields: content.fields.map((item, index,) => {
				if (item.type === 'carMini') {
					if (validation && validation.addVehicle && validation.addVehicle[index]) {
						return {
							...item,
							error: Object.values(validation.addVehicle[index],).reduce((acc, val,) => `${acc} ${val}`, '',),
						}
					}
					return item
				}
				if (item.type !== 'select') return item
				return {
					...item,
					options: [
						...cards.map((key,) => ({ key, val: key, }),),
					],
				}
			},),
		})
	}
	return ({
		...content,
		error: validation && validation[content.id] ? validation[content.id] : '',
		// eslint-disable-next-line max-len
		value: variables && variables[content.id] ? variables[content.id] : data[content.id] || content.value,
	})
},)

// eslint-disable-next-line max-len
export const getEmployeesDataUtil = (employee, cards, { variables, validation, addVehicle, updateVehicle, },) => (fields,) => fields.map((content,) => {
	if (content.type === 'date') {
		return ({
			...content,
			error: validation && validation[content.id] ? validation[content.id] : '',
			// eslint-disable-next-line no-nested-ternary
			value: variables && variables[content.id] ? (variables[content.id] ? moment(employee[content.id],).format(content.format || 'DD.MM.YYYY HH:mm',) : content.value) : (employee[content.id] ? moment(employee[content.id],).format(content.format || 'DD.MM.YYYY HH:MM',) : content.value),
		})
	}

	if (content.type === 'kit') {
		if (variables) {
			return ({
				...content,
				value: [...variables.vehicleList, ...content.value, ],
				// eslint-disable-next-line max-len
				disabledFields: [...variables.vehicleList, ...content.value, ].map((item,) => ({ cardLostDate: !item.cardId, }),),
				// eslint-disable-next-line max-len
				disabledIndex: [...variables.vehicleList, ...content.value, ].reduce((acc, item, index,) => (getVehicleMass(item,) === 1 ? [...acc, index, ] : acc), [],),
				fields: content.fields.map((item,) => {
					const currentOptions = [...cards.map((key,) => ({ key, val: key, }),), ]
					if (item.type !== 'select' && item.type !== 'dateSwitch') return item
					if (item.id === 'cardId') {
						(employee.vehicleList || []).forEach(({ cardId, },) => {
							if (cardId) {
								currentOptions.push({ key: cardId, val: cardId, },)
							}
						},)
					}
					return {
						...item,
						options: currentOptions,
					}
				},),
				error: variables.vehicleList.map((item, index,) => {
					// eslint-disable-next-line max-len
					if (addVehicle && validation.addVehicle && addVehicle[index] !== undefined && validation.addVehicle[addVehicle[index]]) return validation.addVehicle[addVehicle[index]]
					// eslint-disable-next-line max-len
					if (updateVehicle && validation.updateVehicle && updateVehicle[index] !== undefined && validation.updateVehicle[updateVehicle[index]]) return validation.updateVehicle[updateVehicle[index]]
					return {}
				},),
			})
		}
		return ({
			...content,
			value: [...employee.vehicles, ...content.value, ],
			error: [...employee.vehicles, ...content.value, ].map(() => ({}),),
			// eslint-disable-next-line max-len
			disabledFields: [...employee.vehicles, ...content.value, ].map((item,) => ({ cardLostDate: !item.cardId, }),),
			// eslint-disable-next-line max-len
			disabledIndex: [...employee.vehicles, ...content.value, ].reduce((acc, item, index,) => (getVehicleMass(item,) === 1 ? [...acc, index, ] : acc), [],),
			fields: content.fields.map((item,) => {
				const currentOptions = [...cards.map((key,) => ({ key, val: key, }),), ]
				if (item.type !== 'select' && item.type !== 'dateSwitch') return item
				if (item.id === 'cardId') {
					if (item.id === 'cardId') {
						employee.vehicles.forEach(({ cardId, },) => {
							if (cardId) {
								currentOptions.push({ key: cardId, val: cardId, },)
							}
						},)
					}
				}
				return {
					...item,
					options: currentOptions,
				}
			},),
		})
	}

	return ({
		...content,
		error: validation && validation[content.id] ? validation[content.id] : '',
		// eslint-disable-next-line max-len
		value: variables && variables[content.id] ? variables[content.id] : employee[content.id] || content.value,
	})
},)

export const getGuestDataUtil = (cards, data, errors,) => (fields,) => fields.map((content,) => {
	if (content.id === 'bookingTypeId') {
		return ({
			...content,
			error: errors[content.id] ? errors[content.id] : '',
			value: data[content.id] || content.value,
			options: cards.map(({
				id,
				name,
				description,
			},) => ({
				key: id,
				val: name,
				description,
			}),),
		})
	}

	if (content.type === 'date') {
		// eslint-disable-next-line max-len
		return ({
			...content,
			error: errors[content.id] ? errors[content.id] : '',
			value: data[content.id] || content.value,
		})
	}

	if (content.type === 'carMini') {
		return ({
			...content,
			error: Object.keys(content.value,).reduce((acc, key,) => `${acc}${errors[key] ? errors[key] : ''}`, '',),
			// eslint-disable-next-line max-len
			value: Object.keys(content.value,).reduce((acc, key,) => ({ ...acc, [key]: data[key], }), {},),
		})
	}

	return ({
		...content,
		error: errors[content.id] ? errors[content.id] : '',
		value: data[content.id] || content.value,
	})
},)

export const getUserDataUtil = (data, errors,) => (fields,) => fields.map((content,) => {
	if (content.type === 'date') {
		// eslint-disable-next-line max-len
		return ({
			...content,
			error: errors[content.id] ? errors[content.id] : '',
			value: data[content.id] || content.value,
		})
	}

	return ({
		...content,
		error: errors[content.id] ? errors[content.id] : '',
		value: data[content.id] || content.value,
	})
},)

export const getSaleDataUtil = (data, errors,) => (fields,) => fields.map((content,) => ({
	...content,
	error: errors[content.id] ? errors[content.id] : '',
	value: data[content.id] || content.value,
}),)

export const getDefaultDataUtil = (data, errors,) => (fields,) => fields.map((content,) => ({
	...content,
	error: errors[content.id] ? errors[content.id] : '',
	value: data[content.id] || content.value,
}),)

export const getDefaultFilterUtil = (data,) => (fields,) => fields.map((content,) => {
	if (content.type === 'range') {
		return ({
			...content,
			value: content.id.split('&',).reduce((acc, key,) => [...acc, data[key], ], [],),
		})
	}
	return {
		...content,
		value: data[content.id] || content.value,
	}
},)

export const getEmployersFilterUtil = (data, areas,) => (fields,) => fields.map((content,) => {
	if (content.type === 'range') {
		return ({
			...content,
			value: content.id.split('&',).reduce((acc, key,) => [...acc, data[key], ], [],),
		})
	}
	if (content.type === 'select') {
		return ({
			...content,
			value: data[content.id] ? `${data[content.id]}` : content.value,
			options: areas.reduce((acc, { id, name, },) => [...acc, {
				key: id,
				val: name,
			}, ], [],),
		})
	}
	return {
		...content,
		value: data[content.id] || content.value,
	}
},)
