// eslint-disable-next-line no-unused-vars
import React, { useEffect, } from 'react'
import cn from 'classnames'

import { useInputs, } from '../../hooks/useInputs'
import InputElement, { InputMaskElement, } from '../../elements/input/InputElement'
import ButtonElement from '../../elements/button/ButtonElement'
import { ICON_DELETE, ICON_INDEX_ITEM_ARROW, } from '../../constants/iconConstants'
import CarElement from '../../elements/car/CarElement'
import SelectElement from '../../elements/select/SelectElement'
import DateElement from '../../elements/date/DateElement'
import RangeElement from '../../elements/range/RangeElement'
import CheckboxElement from '../../elements/checkbox/CheckboxElement'
import SmallSwitchElement from '../../elements/smallSwitch/SmallSwitchElement'
import globalStyles from '../../assets/global.module.css'
import CarMiniElement from '../../elements/carMini/CarMiniElement'
import IconElement from '../../elements/icon/IconElement'
import DateSwitchElement from '../../elements/dateSwitch/DateSwitchElement'
import BoolButtonElement from '../../elements/boolButton/BoolButtonElement'
import InputAndCardReader from '../../elements/inputAndCardReader/InputAndCardReader'

const FormField = (field,) => {
	switch (field.type) {
	case 'date': {
		return <DateElement {...field} />
	}
	case 'range': {
		return <RangeElement {...field} />
	}
	case 'car': {
		return <CarElement {...field} />
	}
	case 'carMini': {
		return <CarMiniElement {...field} />
	}
	case 'select': {
		return <SelectElement {...field} />
	}
	case 'switch': {
		return <SmallSwitchElement {...field} />
	}
	case 'dateSwitch': {
		return <DateSwitchElement {...field} />
	}
	case 'boolButton': {
		return <BoolButtonElement {...field} />
	}
	case 'checkbox': {
		return <CheckboxElement {...field} />
	}
	case 'kit': {
		return <KitContainer {...field} />
	}
	case 'rows': {
		return <RowsContainer {...field} />
	}
	case 'cardReader': {
		return <InputAndCardReader {...field} />
	}
	case 'mask': {
		return <InputMaskElement {...field} />
	}
	case 'hidden': {
		return null
	}
	default:
		return <InputElement {...field} />
	}
}

// eslint-disable-next-line max-len
const KitContainer = ({ onChange, onChangeFew, value, fields, id, buttonText, buttonClassName, className, isEdit, error, disabledFields, disabledIndex, },) => {
	const onChangeByIndex = (val, fieldId, index,) => {
		onChange(value.map((item, i,) => (i === index ? { ...item, [fieldId]: val, } : item),), id,)
	}
	const onChangeFewByIndex = (values, fieldIds, index,) => {
		onChange(value.map((item, i,) => (i === index ? {
			...item, ...fieldIds.reduce((acc, name, ind,) => ({ ...acc, [name]: values[ind], }), {},),
		} : item),), id,)
	}

	const onAdd = () => {
		onChange([
			...value.map((item,) => ({ ...item, }),),
			fields.reduce((acc, item,) => ({ ...acc, [item.id]: item.value, }), {},),
		], id,)
	}

	const onDelete = (i,) => {
		onChange(value.filter((tag, index,) => index !== i,), id,)
	}

	return (
		<>
			{value.map((item, index,) => {
				const isDisabled = (disabledIndex || []).indexOf(index,) >= 0
				return (
				// eslint-disable-next-line react/no-array-index-key
					<div className={cn(className, globalStyles.kit,)} key={index}>
						{fields.map((props,) => (
							<FormField
								{...props}
								value={item[props.id]}
								key={props.id}
								error={error && error[index] && error[index][props.id] ? error[index][props.id] : ''}
								onChange={(fieldVal, fieldId,) => onChangeByIndex(fieldVal, fieldId, index,)}
								onChangeFew={(values, fieldIds,) => onChangeFewByIndex(values, fieldIds, index,)}
								// eslint-disable-next-line max-len
								disabled={isDisabled || (disabledFields && disabledFields[index] && disabledFields[index][props.id]) || false}
							/>
						),)}
						{(index + 1 >= value.length || isEdit) && (
						// eslint-disable-next-line max-len
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
							<span
								onClick={() => onDelete(index,)}
								className={cn(globalStyles.kitRemove, isEdit && globalStyles.kitRemoveEdited,)}
							>
								<IconElement name={ICON_DELETE} />
							</span>
						)}
					</div>
				)
			},)}
			<div className={buttonClassName}>
				<ButtonElement
					type="button"
					onClick={() => onAdd()}
					isAdd
					text={buttonText}
				/>
			</div>
		</>
	)
}
const RowsContainer = ({ onChange, value, fields, id, },) => {
	const onChangeByIndex = (val, fieldId,) => {
		onChange({
			...value,
			[fieldId]: val,
		}, id,)
	}
	return (
		<div className={globalStyles.row}>
			{fields.map((props,) => (
				<div className={globalStyles.col6} key={props.id}>
					<FormField
						{...props}
						value={value[props.id]}
						onChange={(fieldVal, fieldId,) => onChangeByIndex(fieldVal, fieldId,)}
					/>
				</div>
			),)}
		</div>
	)
}
const FormContentContainer = ({
	content,
	onSubmit,
	wrapperClassName,
	formClassName,
	buttonWrapperClassName,
	inputWrapperClassName,
	buttonClassName,
	buttonText,
	previewButtonText,
	previewButtonGray = false,
	nextButtonText,
	loading,
	onUpdate,
	children,
	disabled,
	onClickPreview = () => {},
	onClickNext = () => {},
},) => {
	const {
		fields,
		onChange,
		onChangeFew,
		onKeyPress,
		onPressSubmit,
		onSave,
		onAdd,
		onDelete,
		onUpdateState,
		isDisabled,
	} = useInputs(
		content,
		onSubmit,
		onUpdate,
		disabled,
	)

	useEffect(() => {
		onUpdateState(content,)
	}, [content, ],)

	return (
		<div className={cn(wrapperClassName, 'global-form', disabled && globalStyles.disabledForm,)}>
			<form className={formClassName} onSubmit={onPressSubmit}>
				<div className={inputWrapperClassName}>
					{fields.map((props,) => (
						<FormField
							{...props}
							key={props.id}
							onChange={onChange}
							onChangeFew={onChangeFew}
							onKeyPress={onKeyPress}
							onSave={onSave}
							onAdd={onAdd}
							onDelete={onDelete}
							isUpdate={!!onUpdate}
							disabled={disabled}
						/>
					),)}
				</div>
				{children}
				{!disabled && (
					<div className={cn(buttonWrapperClassName, globalStyles.formButtonWrapper,)}>
						{!!previewButtonText && (
							<ButtonElement
								type="button"
								className={cn(globalStyles.btnSubmit, buttonClassName,)}
								iconName={ICON_INDEX_ITEM_ARROW}
								isPrimary={previewButtonGray}
								disabled={loading || isDisabled}
								text={previewButtonText}
								onClick={onClickPreview}
							/>
						)}
						{!!buttonText && (
							<ButtonElement
								type="submit"
								className={cn(globalStyles.btnSubmit, buttonClassName,)}
								iconName={ICON_INDEX_ITEM_ARROW}
								disabled={loading || isDisabled}
								text={buttonText}
							/>
						)}
						{!!nextButtonText && (
							<ButtonElement
								type="button"
								className={cn(globalStyles.btnSubmit, buttonClassName,)}
								iconName={ICON_INDEX_ITEM_ARROW}
								disabled={loading || isDisabled}
								text={nextButtonText}
								onClick={onClickNext}
							/>
						)}
					</div>
				)}
			</form>
		</div>
	)
}

export default FormContentContainer
