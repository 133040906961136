// eslint-disable-next-line no-unused-vars
import React from 'react'
import cn from 'classnames'
import styles from './car.module.css'
import globalStyles from '../../assets/global.module.css'

const 	CarElement = ({
	id,
	name,
	value,
	type = 'text',
	validator,
	disabled,
	error,
	placeholder,
	label,
	onChange,
	onKeyPress,
},) => (
	<div className={globalStyles.row}>
		<div className={globalStyles.col6}>
			<div className={styles.input}>
				<div className={styles.inputLabel}>{label.licensePlate}</div>
				<div className={styles.inputCarNumber}>
					<input
						onChange={(e,) => {
							onChange({ ...value, licensePlate: e.target.value.toUpperCase(), }, id,)
						}}
						onKeyPress={onKeyPress}
						type={type}
						name={name}
						value={value.licensePlate}
						disabled={disabled}
						placeholder={placeholder.licensePlate}
						required={!!validator}
					/>
					<input
						onChange={(e,) => onChange({ ...value, region: e.target.value.toUpperCase(), }, id,)}
						onKeyPress={onKeyPress}
						type={type}
						name={name}
						value={value.region}
						disabled={disabled}
						placeholder={placeholder.region}
						required={!!validator}
					/>
				</div>
			</div>
		</div>
		<div className={globalStyles.col6}>
			<div className={styles.input}>
				<div className={styles.inputLabel}>{label.model}</div>
				<input
					onChange={(e,) => onChange({ ...value, model: e.target.value, }, id,)}
					onKeyPress={onKeyPress}
					type={type}
					name={name}
					value={value.model}
					placeholder={placeholder.model}
					required={!!validator}
				/>
			</div>
		</div>
		{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
		{!!error && (
			<label className={cn(globalStyles.labelError,)} htmlFor={name}>
				{error}
			</label>
		)}
	</div>
)
export default CarElement
