// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Calendar, Clear, Down, LeftArrow, RightArrow, Time, Up, } from './Icons'

const SVGIcon = ({ id, ...props },) => {
	const iconMap = {
		calendar: Calendar,
	  clear: Clear,
		time: Time,
		'left-arrow': LeftArrow,
	  'right-arrow': RightArrow,
	  down: Down,
	  up: Up,
	}

	const Icon = iconMap[id]

	return <Icon className={`icon-${id}`} {...props} />
}

SVGIcon.defaultProps = {
	size: '16',
	color: 'currentColor',
}

export default SVGIcon
