export const RIGHTS_BOOKING = 'BOOKING'
export const RIGHTS_DISCOUNT = 'DISCOUNT'
export const RIGHTS_GUEST = 'GUEST'
export const RIGHTS_EMPLOYEE = 'EMPLOYEE'
export const RIGHTS_HISTORY = 'HISTORY'
export const RIGHTS_LOGS = 'LOGS'
export const RIGHTS_USERS = 'USERS'

export const rightsContent = {
	[RIGHTS_BOOKING]: 'Гостевой пропуск',
	[RIGHTS_DISCOUNT]: 'Скидка',
	[RIGHTS_GUEST]: 'Гости',
	[RIGHTS_EMPLOYEE]: 'Сотрудники',
	[RIGHTS_HISTORY]: 'Начисления',
	[RIGHTS_LOGS]: 'Действия пользователей',
	[RIGHTS_USERS]: 'Пользователи',
}
