// eslint-disable-next-line no-unused-vars
import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { DatePickerDefaults, } from '../common/Constant'
import TableCell from './TableCell'
import TableMatrixView from './TableMatrixView'
import { ifExistCall, } from '../utils/FunctionUtil'
import { getWeekDays, } from '../utils/LocaleUtil'

import { getDayMatrix, isDayEqual, isDayRange, } from '../utils/DateUtil'

const DayView = ({
	current,
	customDayClass,
	startDay,
	endDay,
	selected,
	disableDay,
	customDayText,
	onClick,
	onMouseOver,
	locale,
	minDate,
	maxDate,
},) => {
	const isIncludeDay = (date, dates,) => {
		if (dates === undefined) {
			return false
		}
		return dates.some((v,) => isDayEqual(moment(current,).date(parseInt(date, 10,),), v,),)
	}

	const getDayClass = (date,) => {
		const currentDate = moment(current,).date(parseInt(date, 10,),)

		let classArr = []

		if (!date.trim()) {
			return ''
		}

		if (customDayClass !== undefined) {
			const customClass = customDayClass(currentDate,)
			classArr = classArr.concat(typeof customClass === 'string' ? [customClass, ] : customClass,)
		}

		const dayClass = classNames(
			'calendar__day',
			`calendar__day--${moment(currentDate,).day()}`,
			classArr,
			isDayEqual(currentDate, endDay,) && 'calendar__day--end',
			isDayRange(currentDate, startDay, endDay,) && 'calendar__day--range',
			isIncludeDay(date, selected,) && 'calendar__day--selected',
			(disableDay ? disableDay(currentDate,) : false) && 'calendar__day--disabled',
			isDayEqual(currentDate, startDay,) && 'calendar__day--start',
			isDayEqual(currentDate, moment(),) && 'calendar__day--today',
			((!!minDate && !currentDate.isAfter(minDate,)) || (!!maxDate && currentDate.isAfter(maxDate,))) && 'calendar__day--disabled',
		)

		return dayClass
	}

	const getCustomText = (date,) => {
		const currentDate = moment(current,).date(parseInt(date, 10,),)

		if (!date.trim()) {
			return ''
		}
		if (!customDayText) {
			return ''
		}

		return customDayText(currentDate,)
	}

	const handleClick = (date,) => {
		const currentDate = moment(current,).date(parseInt(date, 10,),)
		// eslint-disable-next-line max-len
		if (!(disableDay && disableDay(currentDate,)) && !((!!minDate && !currentDate.isAfter(minDate,)) || (!!maxDate && currentDate.isAfter(maxDate,)))) {
			ifExistCall(onClick, date,)
		}
	}

	const handleMouseOver = (date,) => {
		ifExistCall(onMouseOver, moment(current,).date(parseInt(date, 10,),),)
	}

	const dayMatrix = getDayMatrix(moment(current,).year(), moment(current,).month(), locale,)
	const weekdays = getWeekDays(locale,)

	return (
		<TableMatrixView
			headers={weekdays}
			matrix={dayMatrix}
			cell={(date, key,) => (
				// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
				<TableCell
					className={getDayClass(date,)}
					subText={getCustomText(date,)}
					onClick={handleClick}
					onMouseOver={handleMouseOver}
					text={date}
					key={key}
				/>
			)}
		/>
	)
}

DayView.defaultProps = {
	locale: DatePickerDefaults.locale,
}

export default DayView
