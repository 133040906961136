// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import CalendarBody from './CalendarBody'
import CalendarHead from './CalendarHead'
import TodayPanel from './TodayPanel'
import { ifExistCall, } from '../utils/FunctionUtil'
import { DatePickerDefaults, } from '../common/Constant'
import { getToday, } from '../utils/LocaleUtil'

const CalendarContainer = ({
	current = moment(),
	base = moment(),
	show = true,
	showMonthCnt = 1,
	showToday = false,
	locale = DatePickerDefaults.locale,
	onChange,
	setBase,
	customDayClass,
	customDayText,
	disableDay,
	selected,
	startDay,
	endDay,
	minDate,
	maxDate,
	onMouseOver,
},) => {
	const [viewMode, updateViewMode, ] = useState('DAY',)
	const getHeaderTitle = () => {
		const year = parseInt(moment(current,).format('YYYY',), 10,)
		return {
			YEAR: { title: `${year - 4} - ${year + 5}`, },
			MONTH: { title: `${year}`, },
			DAY: {
				year,
				month: moment(current,).format('MMMM',),
			},
		}[viewMode]
	}
	const handleTitleClick = () => {
		let changedMode = viewMode
		if (viewMode === 'MONTH') {
			changedMode = 'YEAR'
		} else if (viewMode === 'DAY') {
			changedMode = 'MONTH'
		}
		updateViewMode(showMonthCnt > 1 ? 'DAY' : changedMode,)
	}

	const handleChange = (value,) => {
		if (!value.trim()) return
		if (showMonthCnt > 1) {
			const date = moment(current,)
				.date(parseInt(value, 10,),)
				.toDate()
			ifExistCall(onChange, date,)
			return
		}

		if (viewMode === 'YEAR') {
			setBase(moment(base,).year(parseInt(value, 10,),),)
			updateViewMode('MONTH',)
		} else if (viewMode === 'MONTH') {
			setBase(moment(base,).month(parseInt(value, 10,),),)
			updateViewMode('DAY',)
		} else {
			const date = moment(current,).date(parseInt(value, 10,),)
			ifExistCall(onChange, date,)
		}
	}

	const handleBase = (method,) => () => {
		const date = moment(base,)
		if (viewMode === 'YEAR') {
			setBase(date[method](10, 'year',),)
		} else if (viewMode === 'MONTH') {
			setBase(date[method](1, 'year',),)
		} else {
			setBase(date[method](1, 'month',),)
		}
	}

	const handleToday = () => {
		setBase(moment(),)
	}

	// eslint-disable-next-line max-len
	const renderTodayPane = () => <TodayPanel today={getToday(locale,)} onClick={handleToday} show={showToday} />

	const calendarClass = classNames('calendar__container', {
		'calendar--show': show,
	},)

	return (
		<div className={calendarClass}>
			<CalendarHead
				onPrev={handleBase('subtract',)}
				onNext={handleBase('add',)}
				onTitleClick={handleTitleClick}
				{...getHeaderTitle()}
			/>
			{showToday && renderTodayPane()}
			{/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
			<CalendarBody
				viewMode={viewMode}
				current={current}
				selected={selected}
				startDay={startDay}
				endDay={endDay}
				disableDay={disableDay}
				onClick={handleChange}
				onMouseOver={onMouseOver}
				customDayClass={customDayClass}
				customDayText={customDayText}
				locale={locale}
				minDate={minDate}
				maxDate={maxDate}
			/>
		</div>
	)
}

export default CalendarContainer
