// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
// import styles from './guestPage.module.css'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import moment from 'moment'
import { toast, } from 'react-toastify'
import QRCode from 'react-qr-code'
import MainComponent from '../../components/main/MainComponent'
import FormContentContainer from '../../containers/form/FormContentContainer'
import {
	dateValidation,
	emailValidation, miniCarValidation,
	nameValidation,
	selectValidation,
} from '../../utilits/validateUtilit'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onGuestAction, } from '../../store/actions/contentActions'
import { GET_BOOKING_TYPE_API, ADD_GUEST_API, UPDATE_GUEST_API, } from '../../api/guest/guestQuery'
import { getGuestDataUtil, } from '../../utilits/getMainDataUtilit'
import TextModalComponent from '../../components/modal/TextModalComponent'
import {
	getCurrentGuestUtil,
	getFormatGuestDateUtil,
	getFormatGuestUtil,
} from '../../utilits/getGuestUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import styles from './guestPage.module.css'

const FIELDS = [
	{
		id: 'id',
		value: '',
		type: 'hidden',
		error: '',
	},
	{
		id: 'createdAt',
		value: '',
		type: 'hidden',
		error: '',
	},
	{
		id: 'lastName',
		value: '',
		defaultValue: '',
		placeholder: 'Фамилия',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'firstName',
		value: '',
		defaultValue: '',
		placeholder: 'Имя',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'middleName',
		value: '',
		defaultValue: '',
		placeholder: 'Отчество',
		type: 'text',
		error: '',
	},
	{
		id: 'email',
		value: '',
		defaultValue: '',
		placeholder: 'Email',
		validator: emailValidation,
		type: 'phone',
		error: '',
	},
	{
		id: 'cars',
		value: {
			vehicleLicensePlate: '',
			vehicleModel: '',
		},
		defaultValue: {
			vehicleLicensePlate: '',
			vehicleModel: '',
		},
		label: {
			vehicleLicensePlate: 'Гос. номер ТС',
			vehicleModel: 'Марка ТС',
		},
		placeholder: {
			vehicleLicensePlate: 'A000AA000',
			vehicleModel: 'BMW',
		},
		validator: miniCarValidation,
		type: 'carMini',
		error: '',
	},
	{
		id: 'validUntil',
		value: '',
		label: 'Активировать до',
		placeholder: 'ДД.ММ.ГГГГ ЧЧ:ММ',
		validator: dateValidation,
		nullExist: true,
		format: 'DD.MM.YYYY HH:mm',
		minDate: moment(),
		type: 'date',
		error: '',
	},
	{
		id: 'bookingTypeId',
		value: '',
		defaultValue: '',
		placeholder: 'Выберите приглашение',
		validator: selectValidation,
		type: 'select',
		notFound: 'Нет доступных приглашений',
		hasDescription: true,
		options: [],
		error: '',
	},
	{
		id: 'comment',
		value: '',
		defaultValue: '',
		placeholder: 'Комментарии',
		type: 'text',
		error: '',
	},
]
const GuestPageComponent = ({ loading, cards, onAdd, modalConfig, state, onClickModal, },) => (
	<MainComponent
		isSideBarExist
		isWhite
		isClosed
		title="Гостевой пропуск"
	>
		<TextModalComponent
			isOpen={!!modalConfig}
			onClose={onClickModal}
			textButton="Готово"
			onClick={onClickModal}
		>
			{(modalConfig || {}).qr && (
				<div className={styles.modal}>
					<div className={styles.title}>
						Приглашение для
						<br />
						{`${(modalConfig || {}).firstName || ''} ${(modalConfig || {}).lastName || ''}`}
						<br />
						успешно отправлено на e-mail:
						<br />
						{(modalConfig || {}).email}
					</div>

					<div className={styles.qr}>
						<div className={styles.qrTitle}>
							Код для вьезда
						</div>
						<QRCode value={modalConfig.qr} fgColor="#0000bc" level="L" size={128} />
					</div>
				</div>
			)}
		</TextModalComponent>
		{!(loading) && (
			<FormContentContainer
				content={getGuestDataUtil(cards, state.item, state.errors,)(FIELDS,)}
				onSubmit={onAdd}
				wrapperClassName={styles.wrapper}
				loading={false}
				buttonText={state.type === 'edit' ? 'Обновить' : 'Добавить'}
			/>
		)}
	</MainComponent>
)

const Loader = ({ onCallActionUser, location, history, ...props },) => {
	const [cards, updateCards, ] = useState([],)
	const [addGuest, ] = useMutation(ADD_GUEST_API,)
	const [updateGuest, ] = useMutation(UPDATE_GUEST_API,)
	const [modalConfig, updateStateModal, ] = useState(null,)
	const [state, updateState, ] = useState({
		item: location.state && location.state.item ? getFormatGuestUtil(location.state.item,) : {},
		errors: {},
		type: location.state && location.state.type ? location.state.type : 'create',
	},)
	const onAdd = async (variables,) => {
		try {
			const action = state.type === 'edit' ? updateGuest : addGuest
			const content = await onCallActionUser(action,)(getCurrentGuestUtil(variables,),)
			updateStateModal({
				qr: content.qrCode,
				email: variables.email,
				firstName: variables.firstName,
				lastName: variables.lastName,
			},)
			if (location && location.state && location.state.item) {
				history.replace(location.path, {
					...location.state,
					isEdited: true,
				},)
			}
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			updateState({
				...state,
				item: getFormatGuestDateUtil(getCurrentGuestUtil(variables,),),
				errors: e.validation || {},
			},)
			console.log(e,)
		}
	}
	useEffect(() => {
		if (location.state && location.state.isEdited) {
			history.goBack()
		}
	}, [],)
	const onClickModal = () => {
		history.push('/guests',)
		updateStateModal(null,)
	}
	const { loading, } = useQuery(GET_BOOKING_TYPE_API,
		{
			onCompleted: (data,) => {
				updateCards(data.cards,)
			},
			notifyOnNetworkStatusChange: true,
		},)

	return (
		<GuestPageComponent
			loading={loading}
			cards={cards}
			state={state}
			onAdd={onAdd}
			onClickModal={onClickModal}
			modalConfig={modalConfig}
			{...props}
		/>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onCallActionUser: (action,) => (variables,) => dispatch(onGuestAction(action, variables,),),
	}),
)(Loader,)
