const convertPx = (value,) => `${value}px`
export const getDivPosition = (
	node,
	direction = 'BOTTOM',
	height,
	distance = 5,
) => {
	if (!node) return { left: '', top: '', bottom: '', }

	let top = 0
	let left = 0

	// eslint-disable-next-line default-case
	switch (direction) {
	case 'BOTTOM':
		top = node.offsetTop + node.offsetHeight + distance
		left = node.offsetLeft
		break
	case 'TOP':
		top = node.offsetTop - height - distance
		left = node.offsetLeft
		break
	}

	return {
		top: convertPx(top,),
		left: convertPx(left,),
	}
}

export const getDomHeight = (node,) => (node ? node.clientHeight : 0)
