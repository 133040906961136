// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import cn from 'classnames'
import moment from 'moment'
import styles from './dateSwitch.module.css'
import TextModalComponent from '../../components/modal/TextModalComponent'
import DateElement from '../date/DateElement'
import SelectElement from '../select/SelectElement'

const DateSwitchElement = ({
	id,
	updateFieldId,
	value,
	placeholder,
	disabled,
	className,
	classNameLabel,
	format,
	options,
	onChangeFew,
},) => {
	const [isModalOpen, updateStateModal, ] = useState(false,)
	const [currentValue, updateCurrentValue, ] = useState(value,)
	const [selectValue, updateSelectValue, ] = useState(value,)
	const [boolValue, updateBoolValue, ] = useState(!!value,)
	useEffect(() => {
		if (boolValue && !value) {
			updateStateModal(true,)
		}
	}, [boolValue, ],)

	useEffect(() => {
		updateCurrentValue(value,)
	}, [value, ],)

	const onCancel = () => {
		updateStateModal(false,)
		updateBoolValue(false,)
	}

	const onUpdate = (val,) => {
		updateCurrentValue(val,)
	}

	const onSet = () => {
		onChangeFew([currentValue, selectValue, ], [id, updateFieldId, ],)
		updateStateModal(false,)
	}

	if (disabled) return null
	return (
		<>
			<TextModalComponent
				isOpen={isModalOpen}
				title="Карта потеряна?"
				textButton="Подтвердить"
				cancelButton="Отменить"
				onClose={onCancel}
				onClick={onSet}
			>
				<DateElement
					id="date"
					value={currentValue}
					label="Укажите примерную дату утери карты."
					disabled={false}
					className={styles.date}
					isBefore
					placeholder="дд.мм.гггг"
					maxDate={moment()}
					format={format}
					notNeedTime
					onChange={onUpdate}
				/>
				{!!options.length && (
					<SelectElement
						id={updateFieldId}
						value={selectValue}
						isMulti={false}
						label="Выберите новую карту"
						options={options}
						placeholder="Карта"
						onChange={(val,) => updateSelectValue(val,)}
					/>
				)}
			</TextModalComponent>
			<div className={cn(styles.input, className,)}>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
				<label
					className={cn(styles.smallSwitch, styles.smallSwitchInRow, classNameLabel,)}
				>
					<input type="checkbox" checked={boolValue} onChange={() => (value ? null : updateBoolValue(!boolValue,))} />
					<div className={styles.smallSwitchIcon}><i /></div>
					<div className={styles.smallSwitchLabel}>{placeholder}</div>
				</label>
			</div>
		</>
	)
}

export default DateSwitchElement
