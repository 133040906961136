// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import {
	dateValidation,
	emailValidation,
	nameValidation, passwordValidation,
	phoneValidation,
	validationByError,
} from '../../utilits/validateUtilit'
import IconElement from '../../elements/icon/IconElement'
import { ICON_DELETE, ICON_EDIT, ICON_LOGIN_PASSWORD, } from '../../constants/iconConstants'
import FormContentContainer from '../../containers/form/FormContentContainer'
import { getCustomDataUtil, } from '../../utilits/getMainDataUtilit'
import styles from './currentUser.module.css'
import TextModalComponent from '../modal/TextModalComponent'
import {
	RIGHTS_BOOKING,
	RIGHTS_DISCOUNT,
	RIGHTS_EMPLOYEE,
	RIGHTS_GUEST,
	RIGHTS_HISTORY,
	RIGHTS_LOGS,
} from '../../constants/rightsConstants'

const USER_FIELDS = [
	{
		id: 'id',
		value: '',
		type: 'hidden',
		error: '',
	},
	{
		id: 'lastName',
		value: '',
		defaultValue: '',
		label: 'Фамилия',
		placeholder: 'Фамилия',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'firstName',
		value: '',
		defaultValue: '',
		label: 'Имя',
		placeholder: 'Имя',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'middleName',
		value: '',
		defaultValue: '',
		label: 'Отчество',
		placeholder: 'Отчество',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'phone',
		value: '',
		defaultValue: '',
		label: 'Телефон',
		placeholder: 'Телефон',
		validator: phoneValidation,
		disabled: true,
		type: 'tel',
		error: '',
	},
	{
		id: 'email',
		value: '',
		defaultValue: '',
		label: 'Email',
		placeholder: 'Email',
		validator: emailValidation,
		type: 'phone',
		error: '',
	},
	{
		id: 'rights',
		value: ['BOOKING', ],
		defaultValue: ['BOOKING', ],
		placeholder: 'Выберите разделы',
		type: 'checkbox',
		isButton: true,
		label: 'Доступные разделы',
		options: [
			{
				key: RIGHTS_BOOKING,
				val: 'Гостевой пропуск',
			},
			{
				key: RIGHTS_DISCOUNT,
				val: 'Скидка',
			},
			{
				key: RIGHTS_GUEST,
				val: 'Гости',
			},
			{
				key: RIGHTS_EMPLOYEE,
				val: 'Сотрудники',
			},
			{
				key: RIGHTS_HISTORY,
				val: 'Начисления',
			},
			{
				key: RIGHTS_LOGS,
				val: 'Действия пользователей',
			},
		],
		error: '',
	},
	{
		id: 'blockedDate',
		value: '',
		label: 'Срок действия',
		placeholder: 'Срок действия',
		type: 'date',
		nullExist: true,
		validator: dateValidation,
		format: 'DD.MM.YYYY HH:mm',
		error: '',
	},
	{
		id: 'password',
		value: '',
		label: 'Пароль',
		placeholder: 'Пароль',
		iconName: ICON_LOGIN_PASSWORD,
		validator: validationByError(passwordValidation, 'Указан не верный пароль. Проверьте правльность ввода',),
		notRequired: true,
		type: 'password',
		error: '',
	},
	{
		id: 'status',
		value: false,
		defaultValue: false,
		placeholder: 'Активен',
		type: 'switch',
		error: '',
	},
]

const 	CurrentUserComponent = ({
	content,
	onUpdate,
	onDelete,
},) => {
	const [isEdit, editUpdate, ] = useState(false,)
	const [isModalOpen, updateStateModal, ] = useState(false,)
	const onCurrentUserUpdate = async (variables,) => {
		try {
			await onUpdate(variables,)
			editUpdate(false,)
		} catch (e) {
			console.log(e,)
		}
	}
	return (
		<div className={styles.row}>
			<TextModalComponent
				isOpen={isModalOpen}
				onClose={() => updateStateModal(false,)}
				title="Удалить пользователя?"
				textButton="Удалить"
				onClick={() => {
					onDelete()
					updateStateModal(false,)
				}}
			/>
			{!(content.rights.indexOf('users',) >= 0) && (
				<ul className={styles.options}>
					{!isEdit && (
						<li>
							{/* eslint-disable-next-line max-len */}
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
							<span onClick={() => editUpdate(true,)} className={styles.isEdit}><IconElement name={ICON_EDIT} /></span>
						</li>
					)}
					<li>
						{/* eslint-disable-next-line max-len */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
						<span onClick={() => updateStateModal(true,)} className={styles.isDelete}><IconElement name={ICON_DELETE} /></span>
					</li>
				</ul>
			)}
			<FormContentContainer
				content={getCustomDataUtil(content,)(USER_FIELDS,)}
				onSubmit={onCurrentUserUpdate}
				wrapperClassName={styles.wrapper}
				inputWrapperClassName={styles.input}
				buttonWrapperClassName={styles.buttons}
				buttonText="Сохранить"
				previewButtonText="Отмена"
				onClickPreview={() => editUpdate(false,)}
				disabled={!isEdit}
				loading={false}
			/>
		</div>
	)
}
export default CurrentUserComponent
