import { gql, } from 'apollo-boost'

export const GET_FREE_CARDS_API = gql`
	query getFreeCardsBlocks {
		cards: freeCardList
	}
`
export const GET_AREA_LIST_API = gql`
	query getAreaListBlocks {
		areas: getAreaList {
			id
			name
		}
	}
`

export const GET_EMPLOYEE_API = gql`
	query getEmployeeBlocks(
		$name: String,
		$area: Int,
		$includedInCalculation: Boolean,
		$blockedDateFrom: Time,
		$blockedDateTo: Time,
		$from: Int!,
		$size: Int!,
		$field: EmployeeSortField!,
		$direction: SortDirection!,
	) {
		employees: employeeSearch(
			input: {
				name: $name,
				area: $area,
				includedInCalculation: $includedInCalculation,
				blockedDateFrom: $blockedDateFrom,
				blockedDateTo: $blockedDateTo,
			},
			pagination: {
				from: $from,
				size: $size
			},
			sort: {
				field: $field,
				direction: $direction,
			},
		) {
			items {
				id
				firstName
				middleName
				lastName
				phone
				email
				blockedDate
				status
				systemStatus
				vehicles {
					id
					model
					licensePlateId
					licensePlate
					cardId
					cardLostDate
					rfidId
					area
					includedInCalculation
					systemStatus
					status
					fullAccess
					accessAreas
				}
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const ADD_EMPLOYEE_API = gql`
	mutation addEmployee(
		$firstName:   String!
		$middleName:  String!
		$lastName:    String!
		$phone:       String!
		$email:       String!
		$vehicleList: [EmployeeVehicleInput!]
	) {
		employee: addEmployee(input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			phone: $phone
			email: $email
			status: true
			addVehicle: $vehicleList
		}) {
			id
			firstName
			middleName
			lastName
			phone
			email
			status
			systemStatus
			blockedDate
		}
	}
`

export const UPDATE_EMPLOYEE_API = gql`
	mutation updateEmployee(
		$id:   Int!
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$phone: String!
		$email: String!
		$status: Boolean!
		$blockedDate: Time
		$addVehicle: [EmployeeVehicleInput!] # Набор машин для добавления
		$updateVehicle: [EmployeeVehicleUpdateInput!] # Набор машин для обновления
		$deleteVehicle: [Int!] # Набор машин для удаления
	) {
		user: updateEmployee(id: $id, input: {
			firstName: $firstName
			middleName: $middleName
			lastName: $lastName
			phone: $phone
			email: $email
			status: $status
			blockedDate: $blockedDate
			addVehicle: $addVehicle
			updateVehicle: $updateVehicle
			deleteVehicle: $deleteVehicle
		}) {
			id
			firstName
			middleName
			lastName
			phone
			email
			status
			systemStatus
			blockedDate
			vehicles {
				id
				model
				licensePlateId
				licensePlate
				cardId
				cardLostDate
				rfidId
				area
				includedInCalculation
				systemStatus
				status
				fullAccess
				accessAreas
			}
		}
	}
`

export const DELETE_EMPLOYEE_API = gql`
	mutation deleteEmployee(
		$id:   Int!
	) {
		user: deleteEmployee(id: $id)
	}
`
