// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import cn from 'classnames'
import moment from 'moment'
import IconElement from '../icon/IconElement'
import styles from './date.module.css'
import DatePicker from '../../components/DateTimePicker/components/DatePicker'
import globalStyles from '../../assets/global.module.css'

const 	DateElement = ({
	id,
	name,
	value,
	label,
	disabled,
	className,
	iconName,
	format = 'DD.MM.YYYY HH:mm',
	notNeedTime = false,
	minDate,
	maxDate,
	error,
	placeholder,
	onChange,
},) => {
	const onChangeElement = (val,) => onChange(val, id,)
	return (
		<div className={cn(className, styles.input, label && styles.labelWrapper,)}>
			{iconName && <IconElement className={cn(styles.inputIcon,)} name={iconName} />}
			{label && <div className={styles.label}>{label}</div>}
			<DatePicker
				onChange={onChangeElement}
				includeTime={!notNeedTime}
				dateFormat={format}
				disabled={disabled}
				placeholder={placeholder}
				value={value ? moment(value, format,) : null}
				minDate={minDate}
				maxDate={maxDate}
				clear
			/>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}

export default DateElement
