// eslint-disable-next-line no-unused-vars
import React from 'react'
import classNames from 'classnames'

const Backdrop = ({ invert, show, onClick, },) => (
	<React.Fragment>
		{/* eslint-disable-next-line max-len */}
		{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
		{show && <div onClick={onClick} className={classNames('rc-backdrop', { invert, },)} />}
	</React.Fragment>
)

export default Backdrop
