// eslint-disable-next-line no-unused-vars
import React from 'react'
import cn from 'classnames'
import styles from './carMini.module.css'
import globalStyles from '../../assets/global.module.css'

const 	CarMiniElement = ({
	id,
	name,
	value,
	type = 'text',
	validator,
	disabled,
	error,
	placeholder,
	label,
	onChange,
	onKeyPress,
},) => (
	<div className={globalStyles.row}>
		<div className={globalStyles.col6}>
			<div className={styles.input}>
				<div className={styles.inputLabel}>{label.vehicleLicensePlate}</div>
				<div className={styles.inputCarNumber}>
					<input
						onChange={(e,) => onChange({
							...value, vehicleLicensePlate: e.target.value.toUpperCase(),
						}, id,)}
						onKeyPress={onKeyPress}
						type={type}
						name={name}
						value={value.vehicleLicensePlate}
						disabled={disabled}
						placeholder={placeholder.vehicleLicensePlate}
						required={!!validator}
					/>
				</div>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				{!!error && (
					<label className={cn(globalStyles.labelError,)} htmlFor={name}>
						{error}
					</label>
				)}
			</div>
		</div>
		<div className={globalStyles.col6}>
			<div className={styles.input}>
				<div className={styles.inputLabel}>{label.vehicleModel}</div>
				<input
					onChange={(e,) => onChange({ ...value, vehicleModel: e.target.value, }, id,)}
					onKeyPress={onKeyPress}
					type={type}
					name={name}
					value={value.vehicleModel}
					placeholder={placeholder.vehicleModel}
					required={!!validator}
				/>
			</div>
		</div>
	</div>
)
export default CarMiniElement
