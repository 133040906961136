import moment from 'moment'

export const getStartDate = (date,) => {
	// eslint-disable-next-line no-param-reassign
	if (!date) date = moment()
	return moment(date.startOf('day',),)
}

export const getEndDate = (date,) => {
	// eslint-disable-next-line no-param-reassign
	if (!date) date = moment()
	return moment(date.endOf('day',),)
}

export const getDatesByQuery = (dates,) => [
	getStartDate(moment(dates[0], 'YYYY-MM-DD',),),
	getEndDate(moment(dates[1], 'YYYY-MM-DD',),),
]
