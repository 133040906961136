// eslint-disable-next-line no-unused-vars
import React, { useEffect, } from 'react'
import {
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import { ConnectedRouter, } from 'connected-react-router'

import { connect, } from 'react-redux'
import { ToastContainer, } from 'react-toastify'
import LoginPageComponent from './LoginAndForgotPage/LoginPageComponent'
import ForgotPageComponent from './LoginAndForgotPage/ForgotPageComponent'
import MainPageComponent from './MainPage/MainPageComponent'
import GuestPageComponent from './GuestPage/GuestPageComponent'
import history from '../store/history'
import HeaderComponent from '../components/header/HeaderComponent'
import { getTokenSelector, } from '../store/selectors/userSelector'
import EmployeePageComponent from './EmployeePage/EmployeePageComponent'
import UserPageComponent from './UserPage/UserPageComponent'
import UsersPageComponent from './UsersPage/UsersPageComponent'
import EmployersPageComponent from './EmployersPage/EmployersPageComponent'
import GuestsPageComponent from './GuestsPage/GuestsPageComponent'
import LogsPageComponent from './LogsPage/LogsPageComponent'
import { getUserAction, } from '../store/actions/userActions'
import SalePageComponent from './SalePage/SalePageComponent'

const MainPages = ({ match, },) => (
	<HeaderComponent>
		<Switch>
			<Route
				path={`${match.url}`}
				exact
				render={(props,) => <MainPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}guest`}
				exact
				render={(props,) => <GuestPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}employee`}
				exact
				render={(props,) => <EmployeePageComponent {...props} />}
			/>
			<Route
				path={`${match.url}user`}
				exact
				render={(props,) => <UserPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}sale`}
				exact
				render={(props,) => <SalePageComponent {...props} />}
			/>
			<Route
				path={`${match.url}users`}
				exact
				render={(props,) => <UsersPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}employers`}
				exact
				render={(props,) => <EmployersPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}guests`}
				exact
				render={(props,) => <GuestsPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}history`}
				exact
				render={(props,) => <GuestsPageComponent {...props} />}
			/>
			<Route
				path={`${match.url}logs`}
				exact
				render={(props,) => <LogsPageComponent {...props} />}
			/>
		</Switch>
	</HeaderComponent>
)

const AuthPages = ({ match, },) => (
	<Switch>
		<Route exact path={`${match.url}/login`} component={LoginPageComponent} />
		<Route exact path={`${match.url}/forgot`} component={ForgotPageComponent} />
		<Redirect to="/error" />
	</Switch>
)

const AuthRoute = ({ component: Component, token, ...rest },) => (
	<Route
		{...rest}
		render={(props,) => (token ? (
			<Component {...props} />
		) : (
			<Redirect
				to={{
					pathname: '/auth/login',
					state: { from: props.location, },
				}}
			/>
		))}
	/>
)
const Routes = ({ token, onGetUser, },) => {
	useEffect(() => {
		if (token) {
			onGetUser()
		}
	}, [],)
	return (
		<ConnectedRouter history={history}>
			<ToastContainer />
			<Switch>
				<Route
					path="/auth"
					render={(props,) => <AuthPages {...props} />}
				/>
				<AuthRoute
					path="/"
					token={token}
					component={MainPages}
				/>
			</Switch>
		</ConnectedRouter>
	)
}

export default connect(
	(state,) => ({
		token: getTokenSelector(state,),
	}),
	(dispatch,) => ({
		onGetUser: () => dispatch(getUserAction(),),
	}),
)(Routes,)
