import cookie from 'js-cookie'

const fallback = {}

const getSendName = (storageType, name,) => {
	switch (storageType) {
	case 'cookie': return name
	default: return `${name}Item`
	}
}

const getStorage = (storageType,) => {
	switch (storageType) {
	case 'cookie': return cookie
	default: return window[storageType]
	}
}

export default {
	STORAGE_TYPE_LOCAL: 'localStorage',
	STORAGE_TYPE_SECTION: 'localStorage',
	set (key, value, storage = this.STORAGE_TYPE_LOCAL,) {
		const name = getSendName(storage, 'set',)
		const payload = JSON.stringify(value,)

		try {
			getStorage(storage,)[name](key, payload,)
		} catch (e) {
			fallback[key] = payload
		}
	},
	setByExpire (key, value, expire, storage = this.STORAGE_TYPE_LOCAL,) {
		const name = getSendName(storage, 'set',)
		const payload = JSON.stringify(value,)

		try {
			getStorage(storage,)[name](key, payload, expire,)
		} catch (e) {
			fallback[key] = payload
		}
	},
	get (key, storage = this.STORAGE_TYPE_LOCAL,) {
		try {
			const name = getSendName(storage, 'get',)
			const payload = getStorage(storage,)[name](key,)
			return payload && JSON.parse(payload,)
		} catch (e) {
			console.log(e,)
			return fallback[key] === undefined ? null : fallback[key]
		}
	},
	remove (key, storage = this.STORAGE_TYPE_LOCAL,) {
		try {
			const name = getSendName(storage, 'remove',)
			getStorage(storage,)[name](key,)
		} catch (e) {
			delete fallback[key]
		}
	},
}
