window.$ = window.jQuery = require('jquery')

// eslint-disable-next-line no-unused-vars
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider, } from 'react-redux'
import { ApolloProvider, } from '@apollo/react-hooks'
import Routes from '../pages/routes'
import store from '../store/store'
import * as serviceWorker from './serviceWorker'
import clientGQL from '../api/graphql'

ReactDOM.render((
	<Provider store={store}>
		<ApolloProvider client={clientGQL}>
			<Routes />
		</ApolloProvider>
	</Provider>
), document.getElementById('root',),)

serviceWorker.unregister()
