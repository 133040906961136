// eslint-disable-next-line no-unused-vars
import React from 'react'
import cn from 'classnames'
import styles from './saleHeaderElement.module.css'
import { numToPriceUtil, } from '../../utilits/numToPriceUtilits'
import { secondsToTime, } from '../../utilits/secondsUtilit'

const SaleHeaderElement = ({
	applicationArea,
	paymentAmount,
	area,
	transfer,
	licensePlate,
	description,
},) => (
	<div className={styles.content}>
		{description && (<h5>{description}</h5>)}
		<ul className={styles.info}>
			<li>
				<div className={styles.key}>Текущая Зона:</div>
				<div className={styles.value}>{area}</div>
			</li>
			<li>
				<div className={styles.key}>Госномер ТС:</div>
				<div className={styles.value}>{licensePlate}</div>
			</li>
			<li>
				<div className={styles.key}>К оплате:</div>
				<div className={styles.value}>{numToPriceUtil(paymentAmount, '₽',)}</div>
			</li>
		</ul>
		<table>
			<thead>
				<tr>
					<th>Зона</th>
					<th>Время</th>
					<th>Начислено</th>
				</tr>
			</thead>
			<tbody>
				{transfer.map((item,) => (
					<tr
						className={cn(
							'staff__item',
							(applicationArea && applicationArea !== item.area) && 'staff__item--disabled',
						)}
						key={item.datetime}
					>
						<td>{item.area}</td>
						<td>{secondsToTime(item.duration,)}</td>
						<td>{numToPriceUtil(item.paymentAmount, '₽',)}</td>
					</tr>
				),)}
			</tbody>
		</table>
	</div>
)

export default SaleHeaderElement
