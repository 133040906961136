// eslint-disable-next-line no-unused-vars
import React, { useRef, useState, } from 'react'
import cn from 'classnames'
import Select2 from 'react-select2-wrapper'
import styles from './select.module.css'
import globalStyles from '../../assets/global.module.css'

const 	SelectElement = ({
	id,
	name,
	value,
	type = 'text',
	className,
	isMulti = false,
	hasDescription = false,
	label,
	options,
	notFound = 'Нет доступных',
	validator,
	error,
	disabled,
	placeholder,
	onChange,
},) => {
	const ref = useRef(null,)
	const isSelect = (Array.isArray(value,) && value.length > 0) || !!value
	return (
		<div
			className={cn(
				className,
				styles.select,
				label && styles.labelWrapper,
				hasDescription && styles.description,
			)}
		>
			{label && <div className={styles.label}>{label}</div>}
			{isMulti && <div className={styles.label}>{placeholder}</div>}
			<div className={cn(styles.selectContainer, isSelect && styles.selected,)}>
				<Select2
					multiple={isMulti}
					ref={ref}
					disabled={disabled}
					onChange={(e,) => {
						if (isMulti) {
							onChange(ref.current.el.val(), id,)
						} else {
							onChange(e.target.value, id,)
						}
					}}
					data={options.map(({ key, val, },) => ({
						text: val,
						id: key,
					}),)}
					type={type}
					name={name}
					options={{
						placeholder,
						allowClear: true,
						language: {
							noResults: () => notFound,
						},
					}}
					value={value}
					placeholder={placeholder}
					required={!!validator}
				/>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				{!!error && (
					<label className={cn(globalStyles.labelError,)} htmlFor={name}>
						{error}
					</label>
				)}
			</div>
			{hasDescription && (
				<div className={styles.descriptionText}>
					{options.reduce((acc, { key, description, },) => (`${key}` === `${value}` ? description : acc), '',)}
				</div>
			)}
		</div>
	)
}

export default SelectElement
