// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import cn from 'classnames'
import IconElement from '../icon/IconElement'
import clientGQL from '../../api/graphql'
import { GET_DISCOUNT_CARD_API, } from '../../api/employee/searchQuery'
import styles from './autocomplete.module.css'
import globalStyles from '../../assets/global.module.css'

const AutocompleteElement = ({
	id,
	name,
	value,
	label,
	type = 'text',
	notRequired,
	className,
	iconName,
	validator,
	disabled,
	error,
	placeholder,
	onChange,
},) => {
	// eslint-disable-next-line no-unused-vars
	const [suggestions, updateSuggestions, ] = useState([],)
	const [currentType, updateCurrentType, ] = useState(type,)

	const [state, setState, ] = useState({
		activeSuggestion: 0,
		filteredSuggestions: [],
		showSuggestions: false,
		userInput: value,
	},)

	useEffect(() => {
		setState({
			activeSuggestion: 0,
			filteredSuggestions: [],
			showSuggestions: false,
			userInput: value,
		},)
	}, [value, ],)

	const onChangeContent = async (e,) => {
		const userInput = e.currentTarget.value
		let filteredSuggestions = []

		if (userInput.length > 2) {
			try {
				const { data, } = await clientGQL.query({
					query: GET_DISCOUNT_CARD_API,
					variables: {
						pattern: userInput.toLowerCase(),
					},
				},)
				filteredSuggestions = data.cards
			} catch (err) {
				console.log(err,)
			}
		}
		setState({
			activeSuggestion: 0,
			filteredSuggestions,
			showSuggestions: true,
			userInput,
		},)
	}

	const onClick = (e,) => {
		setState({
			activeSuggestion: 0,
			filteredSuggestions: [],
			showSuggestions: false,
			userInput: e.currentTarget.innerText,
		},)

		onChange(e.currentTarget.innerText,)
	}

	const onKeyDown = (e,) => {
		if (e.keyCode === 13) {
			if (state.filteredSuggestions[state.activeSuggestion]) {
				setState({
					activeSuggestion: 0,
					filteredSuggestions: [],
					showSuggestions: false,
					userInput: state.filteredSuggestions[state.activeSuggestion],
				},)
				onChange(state.filteredSuggestions[state.activeSuggestion],)
			}
		} else if (e.keyCode === 38) {
			if (state.activeSuggestion === 0) {
				return
			}

			setState({
				...state,
				activeSuggestion: state.activeSuggestion - 1,
			},)
		} else if (e.keyCode === 40) {
			if (state.activeSuggestion - 1 === state.filteredSuggestions.length) {
				return
			}

			setState({
				...state,
				activeSuggestion: state.activeSuggestion + 1,
			},)
		}
	}

	let suggestionsListComponent

	if (state.showSuggestions && state.userInput) {
		if (state.filteredSuggestions.length) {
			suggestionsListComponent = (
				<ul className={styles.suggestions}>
					{state.filteredSuggestions.map((suggestion, index,) => (
						// eslint-disable-next-line max-len
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
						<li className={index === state.activeSuggestion ? styles.suggestionActive : ''} key={suggestion} onClick={onClick}>
							{suggestion}
						</li>
					),)}
				</ul>
			)
		}
	}

	return (
		<div className={cn(styles.input, label && styles.labelWrapper, className,)}>
			{iconName && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span
					className={cn(styles.inputIcon, type === 'password' && styles.active,)}
					onClick={() => (type === 'password' ? updateCurrentType(currentType === 'password' ? 'text' : 'password',) : '')}
				>
					<IconElement
						name={iconName}
					/>
				</span>
			)}
			{label && <div className={styles.label}>{label}</div>}
			<input
				onChange={onChangeContent}
				onKeyDown={onKeyDown}
				type={currentType}
				value={state.userInput}
				name={id}
				disabled={disabled}
				placeholder={placeholder}
				required={!!validator && !notRequired}
			/>
			{suggestionsListComponent}
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}

export default AutocompleteElement
