const getUserRoot = (state,) => state.user

// eslint-disable-next-line max-len
export const getRootUserSelector = (state,) => getUserRoot(state,).user
export const getUserSelector = (state,) => getRootUserSelector(state,).user
export const getUserRightsSelector = (state,) => getUserSelector(state,).rights
export const getUserRightsObjectSelector = (state,) => (getUserRightsSelector(state,) || [])
	.reduce((acc, name,) => ({ ...acc, [name]: true, }), {},)
export const getUserCompanySelector = (state,) => getRootUserSelector(state,).company
export const getUserContactsSelector = (state,) => getRootUserSelector(state,).contact
export const getTokenSelector = (state,) => getUserRoot(state,).token
export const getUserLoadingSelector = (state,) => getUserRoot(state,).loading
