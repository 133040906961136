import moment from 'moment'
import {
	SESSIONS_BALANCE,
	SESSIONS_BOOKING,
	SESSIONS_EMPLOYEE,
	SESSIONS_GUEST,
} from '../constants/sessionsConstants'
import { numToPriceUtil, } from './numToPriceUtilits'

export const getCurrentGuestUtil = (variables,) => Object.keys(variables,)
	.reduce((acc, key,) => {
		if (key === 'cars') return { ...acc, ...variables[key], }
		if (key === 'bookingTypeId') {
			return {
				...acc,
				[key]: parseInt(variables[key], 10,),
			}
		}
		return {
			...acc,
			[key]: variables[key],
		}
	}, {},)

export const getFormatGuestDateUtil = (variables,) => ({
	...variables,
	validUntil: variables.validUntil ? moment(new Date(variables.validUntil,),)
		.format() : '',
})

export const getFormatGuestUtil = (data,) => ({
	...data.booking,
	...data.booking.guest,
	...data.booking.vehicle,
	vehicleLicensePlate: data.booking.vehicle.licensePlate,
	vehicleModel: data.booking.vehicle.model,
	validUntil: data.booking.validUntil ? moment(new Date(data.booking.validUntil,),) : '',
})

export const getVid = (clientType,) => {
	if (clientType === SESSIONS_GUEST) return 'Скидка'
	if (clientType === SESSIONS_BOOKING) return 'Пригласительный'
	if (clientType === SESSIONS_EMPLOYEE) return 'Списание'
	if (clientType === SESSIONS_BALANCE) return 'Пополнение'
	return ''
}

export const getType = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return source.discount ? source.discount.name : 'Нет'
	if (source.clientType === SESSIONS_BOOKING) return source.booking ? source.booking.name : 'Нет'
	if (source.clientType === SESSIONS_EMPLOYEE) return 'Нет'
	if (source.clientType === SESSIONS_BALANCE) return 'Нет'
	return ''
}

export const getDateStart = (source,) => {
	if (source.clientType === SESSIONS_GUEST) {
		return source.discount ? moment(source.discount.createdAt,)
			.format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'
	}
	if (source.clientType === SESSIONS_BOOKING) {
		return source.booking ? moment(source.booking.createdAt,)
			.format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'
	}
	if (source.clientType === SESSIONS_EMPLOYEE) return 'Нет'
	if (source.clientType === SESSIONS_BALANCE) {
		return source.balance ? moment(source.balance.createdAt,)
			.format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'
	}
	return ''
}

export const getCreator = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return `${source.discount.createdBy.firstName} ${source.discount.createdBy.middleName} ${source.discount.createdBy.lastName}`
	if (source.clientType === SESSIONS_BOOKING) return `${source.booking.createdBy.firstName} ${source.booking.createdBy.middleName} ${source.booking.createdBy.lastName}`
	if (source.clientType === SESSIONS_EMPLOYEE) return ''
	if (source.clientType === SESSIONS_BALANCE) return `${source.balance.createdBy.firstName} ${source.balance.createdBy.middleName} ${source.balance.createdBy.lastName}`
	return ''
}

export const getFio = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return ''
	if (source.clientType === SESSIONS_BOOKING) return `${source.booking.guest.firstName} ${source.booking.guest.middleName} ${source.booking.guest.lastName}`
	if (source.clientType === SESSIONS_EMPLOYEE) return `${source.employee.firstName} ${source.employee.middleName} ${source.employee.lastName}`
	if (source.clientType === SESSIONS_BALANCE) return `${source.balance.createdBy.firstName} ${source.balance.createdBy.middleName} ${source.balance.createdBy.lastName}`
	return ''
}

export const getEmail = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return ''
	if (source.clientType === SESSIONS_BOOKING) return source.booking.guest.email
	if (source.clientType === SESSIONS_EMPLOYEE) return ''
	if (source.clientType === SESSIONS_BALANCE) return ''
	return ''
}
export const getComment = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return source.discount.comment
	if (source.clientType === SESSIONS_BOOKING) return source.booking.comment
	if (source.clientType === SESSIONS_EMPLOYEE) return ''
	if (source.clientType === SESSIONS_BALANCE) return ''
	return ''
}
export const getZone = (source,) => {
	if (source.clientType === SESSIONS_GUEST) return ''
	if (source.clientType === SESSIONS_BOOKING) return (source.booking.area || []).join(', ',)
	if (source.clientType === SESSIONS_EMPLOYEE) return ''
	if (source.clientType === SESSIONS_BALANCE) return ''
	return ''
}
export const getValidate = (source,) => {
	// eslint-disable-next-line max-len
	if (source.clientType === SESSIONS_BOOKING) return !!(source.booking || {}).validUntil && !source.start
	return source.clientType === SESSIONS_BALANCE
}

export const getSum = (source,) => {
	if (source.clientType === SESSIONS_BOOKING && !!source.start) return numToPriceUtil(source.companyPayment, '₽',)
	if (source.clientType === SESSIONS_BOOKING && !source.start) return ''
	if (source.clientType !== SESSIONS_BOOKING && !!source.companyPayment) return numToPriceUtil(source.companyPayment, '₽',)
	return numToPriceUtil(source.balance && source.balance.value ? source.balance.value : 0, '₽',)
}

export const getLicensePlate = ({ vehicle = {}, },) => (vehicle && vehicle.licensePlate ? vehicle.licensePlate : '')

export const getValidContent = (source,) => {
	if (source.clientType === SESSIONS_BOOKING) {
		return (
			source.isActive
				? `Активировать до\n${moment(source.booking.validUntil,)
					.format('DD.MM.YYYY',)}`
				: `Не активировано до\n${moment(source.booking.validUntil,)
					.format('DD.MM.YYYY',)}`
		)
	}
	if (source.clientType === SESSIONS_BALANCE) return source.balance.comment
	return false
}

// eslint-disable-next-line max-len
export const getContentFields = (source,) => (source.clientType === SESSIONS_GUEST || source.clientType === SESSIONS_BOOKING ? ([
	[
		{
			name: 'Создатель:',
			value: getCreator(source,),
		},
		{
			name: 'E-mail:',
			value: getEmail(source,),
		},
	],
	[
		{
			name: 'Комментарий:',
			value: getComment(source,),
		},
		{
			name: 'Зоны доступа:',
			value: getZone(source,),
		},
	],
	[
		{
			name: 'Номер парковочной карты:',
			value: (source.vehicle && source.vehicle.cardId ? source.vehicle.cardId : ''),
		},
		{
			name: 'Марка автомобиля:',
			value: (source.vehicle && source.vehicle.model ? source.vehicle.model : ''),
		},
	],
]) : ([
	[
		{
			name: 'Карта:',
			value: (source.vehicle && source.vehicle.cardId ? source.vehicle.cardId : ''),
		},
		{
			name: 'Автомобиль:',
			value: (source.vehicle && source.vehicle.model ? source.vehicle.model : ''),
		},
	],
	[
		{
			name: 'Гос. Номер:',
			value: (source.vehicle && source.vehicle.licensePlate ? source.vehicle.licensePlate : ''),
		},
		{
			name: 'Телефон:',
			value: '',
		},
	],
	[
		{
			name: 'RFD:',
			value: (source.vehicle && source.vehicle.rfidId ? source.vehicle.rfidId : ''),
		},
		{
			name: 'EMAIL:',
			value: '',
		},
	],
]))
