import { push, } from 'connected-react-router'
import { getLoginQuery, getRecoveryQuery, } from '../../api/rest'
import storageHelper from '../../helpers/storageHelper'
import clientGQL from '../../api/graphql'
import { GET_WHO_AM_I_API, } from '../../api/user/userQuery'
import { getTokenSelector, } from '../selectors/userSelector'

export const SET_USER_CONFIG = 'SET_USER_CONFIG'
export const REMOVE_USER_CONFIG = 'REMOVE_USER_CONFIG'
export const USER_LOADING_UPDATE = 'USER_LOADING_UPDATE'

export const setUserAction = (user = {}, token,) => (dispatch,) => dispatch({
	type: SET_USER_CONFIG,
	user,
	token,
},)

export const removeUserAction = () => (dispatch,) => {
	storageHelper.remove('__token__', 'cookie',)
	storageHelper.remove('__user__',)
	dispatch({
		type: REMOVE_USER_CONFIG,
	},)
}

export const setLoadingUserAction = (loading,) => (dispatch,) => dispatch({
	type: USER_LOADING_UPDATE,
	loading,
},)

export const userAuthAction = (variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUserAction(true,),)
		const { token, } = await getLoginQuery(variables,)
		storageHelper.set('__token__', token, 'cookie',)
		const { data, } = await clientGQL.query({
			query: GET_WHO_AM_I_API,
		},)
		storageHelper.set('__user__', data.user,)
		dispatch(setUserAction(data.user, token,),)
		dispatch(push('/',),)
	} catch (e) {
		console.error(
			'Проблема авторизации',
			e.message || '',
		)
		dispatch(setLoadingUserAction(false,),)
		throw e
	}
	dispatch(setLoadingUserAction(false,),)
	return null
}
export const userRecoveryAction = (variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUserAction(true,),)
		const manager = await getRecoveryQuery(variables,)
		dispatch(setLoadingUserAction(false,),)
		return manager
	} catch (e) {
		console.error(
			'Проблема авторизации',
			e.message || '',
		)
		dispatch(setLoadingUserAction(false,),)
		throw e
		// eslint-disable-next-line no-unreachable
		return null
	}
}

export const getUserAction = () => async (dispatch, getState,) => {
	try {
		dispatch(setLoadingUserAction(true,),)
		const token = getTokenSelector(getState(),)
		const { data, } = await clientGQL.query({
			query: GET_WHO_AM_I_API,
		},)
		storageHelper.set('__user__', data.user,)
		dispatch(setUserAction(data.user, token,),)
	} catch (e) {
		console.error(
			'Проблема авторизации',
			e.message || '',
		)
		e.validation = (e.graphQLErrors || []).reduce((app, { extensions, },) => (
			!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
		), {},)
		dispatch(setLoadingUserAction(false,),)
	}
	dispatch(setLoadingUserAction(false,),)
}
