// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import cn from 'classnames'
import moment from 'moment'
import IconElement from '../../elements/icon/IconElement'
import { ICON_DELETE, ICON_EDIT, } from '../../constants/iconConstants'
import {
	dateValidation,
	emailValidation,
	nameValidation,
	phoneValidation,
} from '../../utilits/validateUtilit'
import FormContentContainer from '../../containers/form/FormContentContainer'
import { getEmployeesDataUtil, } from '../../utilits/getMainDataUtilit'
import styles from './currentEmployer.module.css'
import TextModalComponent from '../modal/TextModalComponent'
import TooltipElement from '../../elements/tooltip/TooltipElement'
import { getVehicleStatusIsActive, getVehicleStatusUtil, } from '../../utilits/getVehicleUtilit'

const FIELDS = [
	{
		id: 'id',
		value: '',
		type: 'hidden',
		error: '',
	},
	{
		id: 'firstName',
		value: '',
		defaultValue: '',
		placeholder: 'Имя',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'lastName',
		value: '',
		defaultValue: '',
		placeholder: 'Фамилия',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'middleName',
		value: '',
		defaultValue: '',
		placeholder: 'Отчество',
		type: 'text',
		error: '',
	},
	{
		id: 'phone',
		value: '',
		defaultValue: '',
		placeholder: 'Телефон',
		validator: phoneValidation,
		type: 'tel',
		error: '',
	},
	{
		id: 'email',
		value: '',
		defaultValue: '',
		placeholder: 'Email',
		validator: emailValidation,
		type: 'phone',
		error: '',
	},
	{
		id: 'blockedDate',
		value: '',
		placeholder: 'Срок действия',
		validator: dateValidation,
		format: 'DD.MM.YYYY HH:mm',
		minDate: moment(),
		nullExist: true,
		type: 'date',
		error: '',
	},
	{
		id: 'status',
		value: false,
		defaultValue: false,
		placeholder: 'Активен',
		classNameLabel: styles.switch,
		type: 'switch',
		error: '',
	},
	{
		id: 'vehicleList',
		value: [],
		type: 'kit',
		className: styles.kit,
		isEdit: true,
		buttonClassName: styles.kitButton,
		buttonText: 'Добавить автомобиль',
		fields: [
			{
				id: 'id',
				value: '',
				type: 'hidden',
				error: '',
			},
			{
				id: 'model',
				value: '',
				defaultValue: '',
				placeholder: 'Марка ТС',
				type: 'text',
				error: '',
			},
			{
				id: 'licensePlate',
				value: '',
				defaultValue: '',
				placeholder: 'Гос. номер ТС',
				type: 'text',
				error: '',
				toUpperCase: true,
			},
			{
				id: 'cardId',
				value: '',
				defaultValue: '',
				placeholder: '0000000',
				type: 'select',
				notFound: 'Нет доступных карт',
				options: [],
				error: '',
			},
			{
				id: 'status',
				value: false,
				defaultValue: false,
				placeholder: 'Активен',
				classNameLabel: styles.switch,
				type: 'switch',
				error: '',
			},
			{
				id: 'includedInCalculation',
				value: false,
				defaultValue: false,
				classNameLabel: styles.switch,
				placeholder: 'На балансе',
				type: 'switch',
				error: '',
			},
			{
				id: 'newCardId',
				value: null,
				type: 'hidden',
			},
			{
				id: 'cardLostDate',
				value: null,
				defaultValue: null,
				classNameLabel: styles.switch,
				placeholder: 'Карта потеряна',
				validator: dateValidation,
				updateFieldId: 'newCardId',
				isActive: true,
				nullExist: true,
				format: 'DD.MM.YYYY',
				type: 'dateSwitch',
				options: [],
				error: '',
			},
		],
	},
]
const 	CurrentEmployerComponent = ({
	onDelete,
	onUpdate,
	content,
	cards,
	id,
},) => {
	const [isEdit, editUpdate, ] = useState(false,)
	const [errors, errorsUpdate, ] = useState({},)
	const [isLoading, updateLoading, ] = useState(false,)
	const [isModalOpen, updateStateModal, ] = useState(false,)

	const onUpdateEmployer = async (variables,) => {
		updateLoading(true,)
		const error = await onUpdate(variables, id,)
		if (!!error && Object.keys(error.validation || {},).length) {
			errorsUpdate(error || {},)
			updateLoading(false,)
		} else {
			editUpdate(false,)
			updateLoading(false,)
		}
	}

	return (
		<div className={styles.row}>
			<TextModalComponent
				isOpen={isModalOpen}
				onClose={() => updateStateModal(false,)}
				title="Удалить сотрудника?"
				textButton="Удалить"
				onClick={() => {
					onDelete()
					updateStateModal(false,)
				}}
			/>
			<ul className={styles.options}>
				{!isEdit && (
					<li>
						{/* eslint-disable-next-line max-len */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
						<span onClick={() => editUpdate(true,)} className={styles.isEdit}><IconElement name={ICON_EDIT} /></span>
					</li>
				)}
				<li>
					{/* eslint-disable-next-line max-len */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
					<span onClick={() => updateStateModal(true,)} className={styles.isDelete}><IconElement name={ICON_DELETE} /></span>
				</li>
			</ul>

			{!isEdit && (
				<div className={styles.table}>
					<table>
						<thead>
							<tr>
								<th>Автомобиль</th>
								<th>Текущая зона</th>
								<th>Гос. номер</th>
								<th>Карта</th>
								<th>RFID метка</th>
								<th>На балансе</th>
								<th>Зоны доступа</th>
								<th>Статус</th>
							</tr>
						</thead>
						<tbody>
							{content.vehicles.map((item, index,) => (
								<tr
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									className={cn(
										!getVehicleStatusIsActive(item,) && styles.disabled,
									)}
								>
									<td>{item.model}</td>
									<td>{item.area}</td>
									<td>
										<span
											className={cn(
												styles.number,
												// eslint-disable-next-line max-len
												(!!item.licensePlate && item.licensePlateId === item.licensePlate) && styles.activeNumber,
											)}
										>
											{item.licensePlate}
										</span>
									</td>
									<td>{item.cardId}</td>
									<td>{item.rfidId}</td>
									<td>
										{/* eslint-disable-next-line max-len */}
										<span className={cn(styles.balance, item.includedInCalculation && styles.active,)} />
									</td>
									<td>
										{item.fullAccess && 'VIP'}
										{!item.fullAccess && !!(item.accessAreas || []).length && (
											<TooltipElement title={(item.accessAreas || []).join(', ',)}>
												<span className={styles.coub} />
											</TooltipElement>
										)}
									</td>
									<td>{getVehicleStatusUtil(item,)}</td>
								</tr>
							),)}
						</tbody>
					</table>
				</div>
			)}

			{isEdit && !isLoading && (
				<FormContentContainer
					content={getEmployeesDataUtil(content, cards, errors,)(FIELDS,)}
					onSubmit={onUpdateEmployer}
					wrapperClassName={styles.wrapper}
					inputWrapperClassName={styles.input}
					buttonWrapperClassName={styles.buttons}
					buttonText="Сохранить"
					previewButtonText="Отмена"
					onClickPreview={() => editUpdate(false,)}
					disabled={!isEdit}
					loading={false}
				/>
			)}
		</div>
	)
}
export default CurrentEmployerComponent
