import { gql, } from 'apollo-boost'

export const REMOVE_BOOKING_API = gql`
	mutation removeBooking(
		$id: Int!
	) {
		removeBooking(
			id: $id
		)
	}
`

export const RESEND_BOOKING_API = gql`
	mutation resendBooking(
		$id: Int!
		$time:Time!
	) {
		resendBooking(
			createdAt: $time, bookingId: $id
		)
	}
`
