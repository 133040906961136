import {
	ACTION_ACTIVATION, ACTION_BOOKING, ACTION_DEACTIVATION,
	ACTION_DELETE, ACTION_DISCOUNT,
	ACTION_EDIT, ACTION_EMPLOYEE, ACTION_LOST,
	ACTION_NEW, ACTION_USER, ACTION_VEHICLE,
} from '../constants/userActionsConstants'

export const getFormatUserActionsUtil = ({ actionType, },) => {
	if (actionType === ACTION_NEW) return 'Новый'
	if (actionType === ACTION_EDIT) return 'Редактирование'
	if (actionType === ACTION_DELETE) return 'Удаление'
	if (actionType === ACTION_ACTIVATION) return 'Активация'
	if (actionType === ACTION_DEACTIVATION) return 'Деактивация'
	if (actionType === ACTION_LOST) return 'Потеря карты'
	return ''
}

export const getFormatUserObjectUtil = ({ objectType, },) => {
	if (objectType === ACTION_DISCOUNT) return 'Скидка'
	if (objectType === ACTION_BOOKING) return 'Приглашение'
	if (objectType === ACTION_EMPLOYEE) return 'Сотрудник'
	if (objectType === ACTION_VEHICLE) return 'Автомобиль'
	if (objectType === ACTION_USER) return 'Пользователь'
	return ''
}
