import { useEffect, } from 'react'

// eslint-disable-next-line max-len
export const useOnClickOutside = (ref, handler,) => {
	useEffect(
		() => {
			const listener = (event,) => {
				if (!ref.current || ref.current.contains(event.target,)) {
					return
				}

				handler(event,)
			}

			document.addEventListener('mousedown', listener,)
			document.addEventListener('touchstart', listener,)

			return () => {
				document.removeEventListener('mousedown', listener,)
				document.removeEventListener('touchstart', listener,)
			}
		},
		[ref, handler, ],
	)
}
