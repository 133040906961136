// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import cn from 'classnames'
import fetchJsonp from 'fetch-jsonp'
import IconElement from '../icon/IconElement'
import AutocompleteElement from '../autocomplete/AutocompleteElement'
import styles from './inputAndCardReader.module.css'
import globalStyles from '../../assets/global.module.css'

const CardReaderElement = ({ onClick, disabled, active, },) => (
	// eslint-disable-next-line max-len
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
	<div
		className={cn(
			styles.buttonCardReader,
			(disabled || !active) && styles.buttonCardReaderDisabled,
			(!disabled && active) && styles.buttonCardReaderActive,
		)}
		onClick={() => (!disabled && active ? onClick() : null)}
	>
		<svg
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
		>
			<g>
				<g>
					<path d="M0,82.758v346.484h512V82.758H0z M482,399.242H30V210.253h452V399.242z M482,180.253H30v-67.495h452V180.253z" />
				</g>
			</g>
			<g>
				<g>
					<rect x="78.07" y="261.23" width="151.49" height="30" />
				</g>
			</g>
			<g>
				<g>
					<rect x="78.07" y="319.73" width="151.49" height="30" />
				</g>
			</g>
		</svg>
	</div>
)

const CardReaderContainer = ({ onUpdate, disabled, },) => {
	const [cardId, updateCardId, ] = useState('',)
	const checkStatus = async () => {
		try {
			const request = await fetchJsonp('http://localhost:3333/GetVersion',)
			const json = await request.json()
			return json.CurrentStatus
		} catch (e) {
			return false
		}
	}
	const updateCardDate = async () => {
		try {
			const request = await fetchJsonp('http://localhost:3333/CheckReaderStatus',)
			const pureText = await request.json()
			let newCardId = ''
			if (pureText && pureText.Result && pureText.Result.CardId) {
				newCardId = pureText.Result.CardId
			}
			updateCardId(/([0-9]+)/g.test(newCardId,) ? newCardId : '',)
		} catch (e) {
			console.log(e,)
		}
	}
	useEffect(() => {
		let interval = 0
		checkStatus().then(async (online,) => {
			if (online) {
				await updateCardDate()
				interval = setInterval(async () => {
					if (!disabled) {
						await updateCardDate()
					}
				}, 1000,)
			}
		},)
		return () => clearInterval(interval,)
	}, [],)

	return (
		<CardReaderElement
			onClick={() => onUpdate(cardId,)}
			disabled={disabled}
			active={!!cardId}
		/>
	)
}

const 	InputAndCardReader = ({
	id,
	name,
	value,
	label,
	type = 'text',
	notRequired,
	className,
	iconName,
	validator,
	disabled,
	error,
	placeholder,
	toUpperCase,
	onChange,
	onKeyPress,
},) => {
	const [currentType, updateCurrentType, ] = useState(type,)
	return (
		<div className={cn(styles.input, label && styles.labelWrapper, className,)}>
			{iconName && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span
					className={cn(styles.inputIcon, type === 'password' && styles.active,)}
					onClick={() => (type === 'password' ? updateCurrentType(currentType === 'password' ? 'text' : 'password',) : '')}
				>
					<IconElement
						name={iconName}
					/>
				</span>
			)}
			{label && <div className={styles.label}>{label}</div>}

			<AutocompleteElement
				// eslint-disable-next-line max-len
				onChange={(val,) => onChange(val, id,)}
				onKeyPress={onKeyPress}
				type={currentType}
				name={id}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				required={!!validator && !notRequired}
			/>

			<CardReaderContainer
				onUpdate={(val,) => onChange(val, id,)}
				disabled={disabled}
			/>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}
export default InputAndCardReader
