// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import moment from 'moment'
import { useQuery, } from '@apollo/react-hooks'
import XLSX from 'xlsx'
import { toast, } from 'react-toastify'
import MainComponent from '../../components/main/MainComponent'
import FilterElement from '../../elements/filter/FilterElement'
import TableComponent from '../../elements/table/TableComponent'
import { GET_USER_ACTIONS_API, } from '../../api/user/userQuery'
import PaginationElement from '../../elements/pagination/PaginationElement'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import {
	getFormatUserActionsUtil,
	getFormatUserObjectUtil,
} from '../../utilits/getUserActionsUtilit'
import {
	ACTION_ACTIVATION,
	ACTION_DEACTIVATION,
	ACTION_DELETE,
	ACTION_EDIT,
	ACTION_LOST,
	ACTION_NEW,
	ACTION_DISCOUNT,
	ACTION_BOOKING,
	ACTION_EMPLOYEE,
	ACTION_VEHICLE,
	ACTION_USER,
} from '../../constants/userActionsConstants'
import { getDefaultFilterUtil, } from '../../utilits/getMainDataUtilit'

const FIELDS_TABLE = [
	{
		name: 'ФИО',
		id: 'USER_NAME',
		isSorted: true,
		getField: (source,) => `${source.userFirstName} ${source.userLastName} ${source.userMiddleName}`,
	},
	{
		name: 'Дата',
		id: 'DATE_TIME',
		isSorted: true,
		getField: (source,) => (
			source.createdAt ? moment(source.createdAt,).format('DD.MM.YYYY',) : 'Нет'
		),
	},
	{
		name: 'Действие',
		id: 'action',
		getField: getFormatUserActionsUtil,
	},
	{
		name: 'Объект',
		id: 'object',
		getField: getFormatUserObjectUtil,
	},
	{
		name: 'До',
		id: 'from',
		getField: ({ data, },) => '', // (data || []).map(({ fieldName, oldValue, },) => `${fieldName}: ${oldValue}`,).join('\n',),
	},
	{
		name: 'После',
		id: 'to',
		getField: ({ data, },) => '', // (data || []).map(({ fieldName, newValue, },) => `${fieldName}: ${newValue}`,).join('\n',),
	},
]

const SEARCH_FIELD = [
	{
		id: 'name',
		value: '',
		defaultValue: '',
		placeholder: 'ФИО',
		type: 'text',
		error: '',
	},
	{
		id: 'inputFrom&inputTo',
		value: [],
		placeholder: 'Дата создания',
		type: 'range',
		format: 'DD.MM.YYYY',
		error: '',
	},
	{
		id: 'actionType',
		value: [],
		defaultValue: [],
		placeholder: 'Тип действия',
		type: 'checkbox',
		isButton: true,
		options: [
			{
				key: ACTION_NEW,
				val: 'Новый',
			},
			{
				key: ACTION_EDIT,
				val: 'Редактирование',
			},
			{
				key: ACTION_DELETE,
				val: 'Удаление',
			},
			{
				key: ACTION_ACTIVATION,
				val: 'Активация',
			},
			{
				key: ACTION_DEACTIVATION,
				val: 'Деактивация',
			},
			{
				key: ACTION_LOST,
				val: 'Потеря карты',
			},
		],
		error: '',
	},
	{
		id: 'objectType',
		value: [],
		defaultValue: [],
		placeholder: 'Объект',
		type: 'checkbox',
		isButton: true,
		options: [
			{
				key: ACTION_DISCOUNT,
				val: 'Скидка',
			},
			{
				key: ACTION_BOOKING,
				val: 'Приглашение',
			},
			{
				key: ACTION_EMPLOYEE,
				val: 'Сотрудник',
			},
			{
				key: ACTION_VEHICLE,
				val: 'Автомобиль',
			},
			{
				key: ACTION_USER,
				val: 'Пользователь',
			},
		],
		error: '',
	},
]

const initState = {
	from: 0,
	size: 10,
	name: '',
	inputFrom: null,
	inputTo: null,
	actionType: [],
	objectType: [],
}

const LogsPageComponent = ({
	content,
	state,
	updateState,
	loading,
	onUpload,
	sort,
	onUpdateSort,
},) => (
	<MainComponent
		isWhite
		isClosed
		title="Действия пользователей"
	>
		<FilterElement
			onDownload={onUpload}
			onUpdate={(variables,) => updateState({ ...state, ...variables, },)}
			fields={getDefaultFilterUtil(state,)(SEARCH_FIELD,)}
		/>

		{!(loading || !content.items.length) && (
			<>
				<TableComponent
					fields={FIELDS_TABLE}
					content={content.items}
					sort={sort}
					onUpdateSort={onUpdateSort}
					isNotCollapsed
				/>
				<PaginationElement
					totalCount={content.pagination.total}
					pageSize={content.pagination.size}
					page={(content.pagination.from / content.pagination.size) + 1}
					onChange={(page,) => {
						updateState({ ...state, from: (page - 1) * content.pagination.size, },)
					}}
				/>
			</>
		)}
	</MainComponent>
)

const Loader = () => {
	const [content, updateContent, ] = useState({
		items: [],
		pagination: {},
	},)

	const [state, updateState, ] = useState(initState,)
	const [sort, updateSort, ] = useState({
		field: 'USER_NAME',
		direction: 'DESC',
	},)

	const { loading, fetchMore, } = useQuery(GET_USER_ACTIONS_API,
		{
			variables: {
				...state,
				...sort,
			},
			onCompleted: (data,) => {
				updateContent(data.users,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const onUpdateSort = (name,) => updateSort({
		field: name,
		// eslint-disable-next-line no-nested-ternary
		direction: name !== sort.field ? 'DESC' : (sort.direction === 'DESC' ? 'ASC' : 'DESC'),
	},)

	const onUpload = async () => {
		try {
			return fetchMore({
				variables: {
					from: 0,
					size: content.pagination.total || 0,
					name: state.name,
					inputFrom: state.inputFrom,
					inputTo: state.inputTo,
					actionType: state.actionType,
					objectType: state.objectType,
					...sort,
				},
				updateQuery: (prev, { fetchMoreResult, },) => {
					if (!fetchMoreResult) return prev
					const users = XLSX.utils.json_to_sheet(fetchMoreResult.users.items.map((item,) => ({
						ФИО: `${item.userFirstName} ${item.userLastName} ${item.userMiddleName}`,
						Дата: item.createdAt ? moment(item.createdAt,).format('DD.MM.YYYY',) : 'Нет',
						Действие: getFormatUserActionsUtil(item,),
						Объект: getFormatUserObjectUtil(item,),
					}),),)
					const wb = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(wb, users, 'Actions',)
					XLSX.writeFile(wb, 'actions.xlsx',)
					return fetchMoreResult
				},
			},)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
		return null
	}
	return (
		<LogsPageComponent
			loading={loading}
			state={state}
			updateState={updateState}
			content={content}
			sort={sort}
			onUpload={onUpload}
			onUpdateSort={onUpdateSort}
		/>
	)
}
export default Loader
