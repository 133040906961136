// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, } from 'react'
import cn from 'classnames'
import { useHover, } from '../../hooks/useHover'
import styles from './tooltip.module.css'

const TooltipElement = ({ title, children, },) => {
	const [hoverRef, isHovered, ] = useHover()

	return (
		// eslint-disable-next-line max-len
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			className={styles.container}
			ref={hoverRef}
		>
			<div data-testid="tooltip-placeholder">{children}</div>
			{isHovered && !!title && (
				<div
					className={cn(styles.tooltipContent, styles.bottom,)}
				>
					{title}
				</div>
			)}
		</div>
	)
}

export default TooltipElement
