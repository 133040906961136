// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import cn from 'classnames'
import { Link, } from 'react-router-dom'
import moment from 'moment'
import { connect, } from 'react-redux'
import { useQuery, } from '@apollo/react-hooks'
import { toast, } from 'react-toastify'
import { numToPriceUtil, } from '../../utilits/numToPriceUtilits'
import IconElement from '../../elements/icon/IconElement'
import { ICON_INDEX_ITEM_ARROW, ICON_SETTING, } from '../../constants/iconConstants'
import MainComponent from '../../components/main/MainComponent'
import { getUserRightsObjectSelector, } from '../../store/selectors/userSelector'
import { GET_SESSION_API, } from '../../api/guest/guestQuery'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import { getFio, getLicensePlate, } from '../../utilits/getGuestUtilit'
import {
	RIGHTS_BOOKING,
	RIGHTS_DISCOUNT,
	RIGHTS_EMPLOYEE,
	RIGHTS_GUEST,
	RIGHTS_HISTORY,
	RIGHTS_LOGS,
	RIGHTS_USERS,
} from '../../constants/rightsConstants'
import styles from './mainPage.module.css'

const blocks = [
	{
		id: RIGHTS_BOOKING,
		title: 'Гостевой пропуск',
		text: 'Заказать разовый пропуск\n с различными вариантами\n скидок',
		to: '/guest',
		size: 'md',
		disabled: false,
		data: null,
	},
	{
		id: RIGHTS_DISCOUNT,
		title: 'Скидка',
		text: 'Предоставление скидки гостю',
		to: '/sale',
		size: 'sm',
		disabled: false,
		data: null,
	},
	{
		id: RIGHTS_GUEST,
		title: 'Гости',
		text: 'Управление гостевыми пропусками и скидками',
		to: '/guests',
		size: 'sm',
		disabled: false,
		data: null,
	},
	{
		id: RIGHTS_EMPLOYEE,
		title: 'Сотрудники',
		text: 'Управление выданными пропусками сотрудников',
		to: '/employers',
		size: 'md',
		disabled: false,
		data: null,
	},
	{
		id: RIGHTS_HISTORY,
		title: 'Начисления',
		text: null,
		to: '/history',
		size: 'lg',
		disabled: false,
		data: [
			{
				fullName: 'Иванов Иван Иванович',
				car: 'Mercedez-Benz',
				carNumber: 'X775MM 750',
				from: '2020-03-22T14:00:00Z',
				to: '2020-03-22T18:00:00Z',
				price: 3000,
			},
			{
				fullName: 'Иванов Иван Иванович',
				car: 'Mercedez-Benz',
				carNumber: 'X775MM 750',
				from: '2020-03-22T19:00:00Z',
				to: '2020-03-22T22:00:00Z',
				price: 4500,
			},
		],
	},
	{
		id: RIGHTS_LOGS,
		title: 'Действия пользователей',
		text: 'Посмотреть информацию о действии пользователей на сайте',
		to: '/logs',
		size: 'md',
		disabled: false,
		data: null,
	},
	{
		id: RIGHTS_USERS,
		title: <IconElement name={ICON_SETTING} />,
		text: 'Управление правами доступа пользователей',
		to: '/users',
		size: 'sm',
		disabled: false,
		data: null,
	},
]

const getSize = (size,) => {
	if (size === 'sm') return styles.colSm
	if (size === 'md') return styles.colMd
	if (size === 'lg') return styles.colLg
	return null
}

const RowElement = ({ fullName, car, carNumber, from, to, price, },) => (
	<tr>
		<td>
			<div className={styles.tableName}>{fullName}</div>
		</td>
		<td>
			{car}
			<br />
			<div className={styles.tableNumber}>{carNumber}</div>
		</td>
		<td>
			{from ? moment(from,)
				.format('DD.MM.YYYY',) : ''}
			<div className={styles.tableTime}>
				{from ? moment(from,)
					.format('HH:mm',) : ''}
			</div>
		</td>
		<td>
			{to ? moment(to,)
				.format('DD.MM.YYYY',) : ''}
			<div className={styles.tableTime}>
				{to ? moment(to,)
					.format('HH:mm',) : ''}
			</div>
		</td>
		<td>
			{price}
		</td>
	</tr>
)

const ItemElement = ({ id, title, text, to = '#', size, disabled, loading, histories = [], },) => (
	<Link className={cn(styles.col, getSize(size,),)} to={to}>
		<div className={cn(styles.item, disabled && styles.disabled,)}>
			<div className={styles.title}>{title}</div>
			{!!text && <div className={styles.text}>{text}</div>}

			{id === 'HISTORY' && !loading && (
				<div className={styles.table}>
					<table>
						<tbody>
							{/* eslint-disable-next-line max-len */}
							{histories.map((content, index,) => (index > 1 ? null : (
							// eslint-disable-next-line react/no-array-index-key
								<RowElement {...content} key={`${content.id}-${index}`} />
							)),)}
						</tbody>
					</table>
				</div>
			)}
			<div className={styles.link}>
				<IconElement name={ICON_INDEX_ITEM_ARROW} />
			</div>
		</div>
	</Link>
)

const MainPageComponent = ({ loading, rights, histories, },) => (
	<MainComponent
		isSideBarExist
		isWhite={false}
	>
		<div className={styles.index}>
			{blocks.map(({ id, ...config },) => (!rights[id] ? null : (
				<ItemElement
					{...config}
					id={id}
					key={id}
					disabled={!rights[id]}
					histories={histories}
					loading={loading}
				/>
			)),)}
		</div>
		<div className={styles.instructionLink}>
			<a href="/instruction.pdf" target="_blank">Инструкция пользователя</a>
		</div>
	</MainComponent>
)

const Loader = ({ rights, ...props },) => {
	const [items, updateItems, ] = useState([],)

	const { loading, } = useQuery(GET_SESSION_API,
		{
			variables: {
				from: 0,
				size: 2,
				field: 'START_SESSION',
				direction: 'DESC',
				clientType: ['EMPLOYEE', 'BALANCE', ],
			},
			onCompleted: (data,) => {
				if (data) {
					updateItems(data.guests.items.map((item,) => ({
						fullName: getFio(item,),
						car: (item.vehicle && item.vehicle.model ? item.vehicle.model : ''),
						carNumber: getLicensePlate(item,),
						from: item.start,
						to: item.end,
						price: numToPriceUtil(item.balance && item.balance.value ? item.balance.value : 0, '₽',),
					}),),)
				}
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
			// eslint-disable-next-line react/destructuring-assignment
			skip: !rights.HISTORY,
		},)

	return (
		<MainPageComponent
			loading={loading}
			histories={items}
			rights={rights}
			{...props}
		/>
	)
}

export default connect(
	(state,) => ({
		rights: getUserRightsObjectSelector(state,),
	}),
)(Loader,)
