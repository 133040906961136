const initialState = {
	balance: 23000,
	places: 7,
	allPlaces: 10,
	cards: 5,
	zone: 'Вездеход',
	billStop: false,
	blockDate: null,
	mainZone: 6,
	businessZone: 10,
	rate: 'Предоплатный',
	contactName: 'Иванов Иван Иванович',
	contactPhone: '+7 (911) 111-11-11',
	contactEmail: 'example@mail.ru',
}

export default (state = initialState, action = {},) => {
	switch (action.type) {
	default:
		return state
	}
}
