import moment from 'moment'

const validRegexp = {
	// eslint-disable-next-line no-control-regex,no-useless-escape
	emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	numberRegexp: /[0-9]/,
	isOnlyNumberRegexp: /^\d+$/,
	alphabetRegexp: /[a-z]/i,
	// eslint-disable-next-line no-useless-escape
	phoneRegexp: /^\+?[0-9]{7,16}$/im,
	carNumber: /(([А-ЯA-Z]\d{3}[А-ЯA-Z]{1,2})(\d{2,3})|(\d{4}[А-Я]{2})(\d{2})|(\d{3}C?D{1,2}\d{3})(\d{2})|([А-ЯA-Z]{2}\d{3}[А-ЯA-Z])(\d{2})|([А-ЯA-Z]\d{4})(\d{2})|(\d{3}[А-ЯA-Z])(\d{2})|(\d{4}[А-ЯA-Z])(\d{2}))/i,
}

const rangeReplace = (str, ...props) => props.reduce((acc, item, index,) => acc.replace(`{${index}}`, item,), str,)

export const validationByError = (action, error,) => (...props) => action(...props, error,)

const validMessage = {
	required: 'Это поле обязательно к заполнению.',
	select: 'Пожалуйста, выберите вариант.',
	remote: 'Пожалуйста, исправьте это поле.',
	email: 'Пожалуйста, введите действительный адрес электронной почты.',
	url: 'Пожалуйста, введите корректный адрес.',
	phone: 'Пожалуйста, введите корректный телефон.',
	date: 'Пожалуйста, введите правильную дату.',
	dateISO: 'Пожалуйста, введите действительную дату (ISO).',
	number: 'Пожалуйста, введите корректное число.',
	digits: 'Пожалуйста, вводите только цифры.',
	equalTo: 'Пожалуйста, введите то же значение снова.',
	maxlength: (max,) => rangeReplace('Пожалуйста, вводите не больше {0} символов.', max,),
	minlength: (min,) => rangeReplace('Пожалуйста, вводите не меньше {0} символов.', min,),
	rangelength: (max, min,) => rangeReplace('Введите значение от {0} до {1}.', max, min,),
	range: (max, min,) => rangeReplace('Пожалуйста, введите значение между {0} и {1}.', max, min,),
	max: (max,) => rangeReplace('Пожалуйста, введите значение меньше или равное {0}.', max,),
	min: (min,) => rangeReplace('Пожалуйста, введите значение больше или равное {0}.', min,),
}

export const passwordValidation = ({ value, error, },) => {
	const errors = []
	if (value.length < 4) {
		errors.push('Ваш пароль должен быть длиннее 4-ех символов',)
	}
	if (!!errors.length && error) {
		return error
	}
	return errors.join(' ',)
}

export const nameValidation = ({ value, error, },) => {
	const errors = []
	if (value.length <= 1) {
		errors.push(validMessage.minlength(1,),)
	}
	if (!!errors.length && error) {
		return error
	}
	return errors.join(' ',)
}

export const emailValidation = ({ value, error, },) => {
	const errors = []
	if (value.search(validRegexp.emailRegex,) < 0) {
		errors.push(validMessage.email,)
	}
	if (!!errors.length && error) {
		return error
	}
	return errors.join(' ',)
}

export const phoneValidation = ({ value, error, },) => {
	const errors = []
	if (value.search(validRegexp.phoneRegexp,) < 0) {
		errors.push(validMessage.phone,)
	}
	if (!!errors.length && error) {
		return error
	}
	return errors.join(' ',)
}

export const newPhoneValidation = (mask,) => ({ value, error, },) => {
	const errors = []
	const val = mask.split('',).reduce((acc, v, index,) => acc + (v === '9' || v === 'a' || v === '*' ? value[index] : ''), '',)
	if (val.length < 10) {
		errors.push(validMessage.phone,)
	}
	if (!!errors.length && error) {
		return error
	}
	return errors.join(' ',)
}

export const dateValidation = ({ value, format = 'DD.MM.YYYY HH:mm', nullExist, },) => {
	const errors = []
	if (!nullExist && (!value || !moment(value, format,).isValid())) {
		errors.push(validMessage.date,)
	}
	if (errors.length) {
		return errors.join(', ',)
	}
	return errors.join('',)
}

export const selectValidation = ({ value, },) => {
	const errors = []
	if (!value) {
		errors.push(validMessage.select,)
	}
	if (errors.length) {
		return errors.join(',',)
	}
	return errors.join(' ',)
}

export const carValidation = ({
	value: {
		number,
		region,
		mark,
	},
},) => {
	const errors = []
	if (`${number + region}`.search(validRegexp.carNumber,) < 0) {
		errors.push(validMessage.number,)
	}

	if (mark.length <= 1) {
		errors.push(validMessage.minlength(1,),)
	}

	console.log(errors, `${number + region}`,)

	return errors.join(' ',)
}
export const miniCarValidation = ({
	value: {
		vehicleLicensePlate,
		vehicleModel,
	},
	nullExist,
},) => {
	const errors = []
	// eslint-disable-next-line max-len
	if (!nullExist && (!vehicleLicensePlate || vehicleLicensePlate.search(validRegexp.carNumber,) < 0)) {
		errors.push(validMessage.number,)
	}

	if (!nullExist && (!vehicleModel || vehicleModel.length <= 1)) {
		errors.push(validMessage.minlength(1,),)
	}
	console.log(errors,)

	return errors.join(' ',)
}
