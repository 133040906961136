// eslint-disable-next-line no-unused-vars
import React from 'react'
import SVGIcon from './SVGIcon'

const PickerInput = ({
	onClear,
	value = '',
	onClick,
	placeholder,
},) => {
	const handleClear = (e,) => {
		e.stopPropagation()
		if (onClear) onClear()
	}

	return (
		// eslint-disable-next-line max-len
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className="picker-input_button" onClick={onClick}>
			{!value && <span className="picker-input_button-placeholder">{placeholder}</span>}
			{!!value && <span className="picker-input_button-value">{value}</span>}
			{!!value && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span className="picker-input__clear" onClick={handleClear}>
					<SVGIcon id="clear" />
				</span>
			)}
		</div>
	)
}

export default PickerInput
