import { ApolloClient, } from 'apollo-client'
import { InMemoryCache, } from 'apollo-cache-inmemory'
import { setContext, } from 'apollo-link-context'
import { createUploadLink, } from 'apollo-upload-client'
import { onError, } from 'apollo-link-error'
import { portLink, } from '../utilits/envUtilit'
import storageHelper from '../helpers/storageHelper'

const authLink = setContext((_, { headers, },) => {
	const token = storageHelper.get('__token__', 'cookie',) || ''
	return {
		headers: {
			...headers,
			'X-AUTH': token,
		},
	}
},)

const link = createUploadLink({
	uri: `${portLink}8080/query`,
},)

const onErrorLink = onError(({ graphQLErrors, networkError, response, operation, forward, },) => {
	let goTo = null
	const errors = []
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path, },) => {
			if (message === 'Access denied ') {
				goTo = '/'
			}
			errors.push(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			)
			return null
		},)
	}

	if (networkError) {
		if (networkError.statusCode === 401) {
			storageHelper.remove('__token__', 'cookie',)
			goTo = '/'
		}
		errors.push(`[Network error]: ${networkError}`,)
	}

	if (goTo) {
		window.location.pathname = goTo
	}
	console.log(errors.join(', ',),)
},)
const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
}

const clientGQL = new ApolloClient({
	link: authLink.concat(onErrorLink,).concat(link,),
	cache: new InMemoryCache(),
	defaultOptions,
},)

export default clientGQL
