// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, } from 'react'
import moment from 'moment'
import * as CX from 'classnames'
import Calendar from './Calendar'
import TimeContainer from './TimeContainer'
import Picker from './Picker'
import { ifExistCall, } from '../utils/FunctionUtil'
import { formatDate, } from '../utils/DateUtil'
import { DatePickerDefaults, } from '../common/Constant'
import PickerInput from './PickerInput'
import SVGIcon from './SVGIcon'

const getDateFormat = ({ dateFormat, includeTime, showTimeOnly, },) => {
	if (!dateFormat) {
		if (includeTime) {
			return DatePickerDefaults.dateTimeFormat
		}
		if (showTimeOnly) {
			return DatePickerDefaults.timeFormat
		}
		return DatePickerDefaults.dateFormat
	}
	return dateFormat
}

const getDateSelector = (value, dateFormat, includeTime, showTimeOnly,) => {
	const selected = []
	let date

	if (value) {
		date = value
		selected.push(date,)
	}

	return {
		date,
		selected,
		inputValue: formatDate(date, getDateFormat({ dateFormat, includeTime, showTimeOnly, },),),
	}
}

const DatePicker = ({
	includeTime,
	showMonthCnt,
	locale,
	portal,
	showDefaultIcon,
	initialDate,
	value,
	showTimeOnly,
	dateFormat,
	onChange,
	inputComponent,
	readOnly,
	disabled,
	clear,
	autoFocus,
	placeholder,
	direction,
	...props
},) => {
	if (includeTime && showTimeOnly) {
		throw new Error('incldueTime & showTimeOnly cannot be used together',)
	}

	const [state, updateState, ] = useState(getDateSelector(
		value, dateFormat, includeTime, showTimeOnly,
	),)

	useEffect(() => {
		updateState(getDateSelector(value, dateFormat, includeTime, showTimeOnly,),)
	}, [value, ],)

	const handleDateChange = (currentDate,) => {
		// eslint-disable-next-line max-len
		const currentValue = moment(currentDate,).format(getDateFormat({ dateFormat, includeTime, showTimeOnly, },),)

		ifExistCall(onChange, currentDate, currentValue,)

		updateState({
			...state,
			date: currentDate,
			inputValue: currentValue,
			selected: [currentDate, ],
		},)
	}

	const handleTimeChange = (hour, minute,) => {
		let { date: stateDate, selected: stateSelected, } = state

		if (!stateDate) {
			stateDate = moment()
			stateSelected = [stateDate, ]
		}

		stateDate = stateDate.set({ hour, minute, },)
		const inputValue = stateDate.format(getDateFormat({ dateFormat, includeTime, showTimeOnly, },),)

		ifExistCall(onChange, stateDate, inputValue,)

		updateState({
			...state,
			date: stateDate,
			selected: stateSelected,
			inputValue,
		},)
	}

	const handleInputChange = (currentValue,) => {
		ifExistCall(onChange, currentValue, undefined,)

		updateState({
			...state,
			inputValue: currentValue,
		},)
	}

	const handleInputClear = () => {
		ifExistCall(onChange, '', undefined,)

		updateState({
			...state,
			inputValue: '',
		},)
	}

	const handleInputBlur = (e,) => {
		// eslint-disable-next-line max-len
		const parsedDate = moment(new Date(e.currentTarget.value,),)
		let updateDate

		updateDate = state.date

		if (moment(parsedDate,).isValid()) {
			updateDate = parsedDate
		}

		// eslint-disable-next-line max-len
		const currentValue = moment(updateDate,).format(getDateFormat({ dateFormat, includeTime, showTimeOnly, },),)
		updateState({
			...state,
			date: updateDate,
			inputValue: currentValue,
		},)

		ifExistCall(onChange, currentValue, undefined,)
	}

	const renderInputComponent = () => {
		const inputProps = {
			readOnly,
			autoFocus,
			disabled,
			clear,
			placeholder,
			onChange: handleInputChange,
			onClear: handleInputClear,
			onBlur: handleInputBlur,
			dateFormat,
			value: state.inputValue,
			icon: showDefaultIcon ? <SVGIcon id="calendar" /> : undefined,
		}

		return inputComponent ? inputComponent({ ...inputProps, },) : <PickerInput {...inputProps} />
	}

	const renderCalendar = (actions,) => (
		<Calendar
			{...{
				includeTime,
				showMonthCnt,
				locale,
				portal,
				showDefaultIcon,
				initialDate,
				showTimeOnly,
				dateFormat,
				onChange,
				inputComponent,
				readOnly,
				disabled,
				clear,
				autoFocus,
				placeholder,
				direction,
				...props,
			}}
			base={state.date}
			onChange={(e,) => {
				handleDateChange(e,)
				actions.hide()
			}}
			selected={state.selected}
		/>
	)

	const renderTime = () => {
		const currentDate = state.date || moment()
		return (
			<TimeContainer
				hour={currentDate.hour()}
				minute={currentDate.minute()}
				onChange={handleTimeChange}
			/>
		)
	}

	const renderContents = (actions,) => {
		let component

		component = <div className="picker__container__calonly">{renderCalendar(actions,)}</div>

		if (showTimeOnly) {
			component = <div className="picker__container__timeonly">{renderTime()}</div>
		}

		if (includeTime) {
			component = (
				<div className="picker__container__include-time">
					{renderCalendar(actions,)}
					{renderTime()}
				</div>
			)
		}
		return component
	}

	return (
		<Picker
			portal={portal}
			direction={direction}
			readOnly={readOnly}
			disabled={disabled}
			className={CX({ include__time: includeTime, },)}
			renderTrigger={() => renderInputComponent()}
			renderContents={({ actions, },) => renderContents(actions,)}
		/>
	)
}

DatePicker.defaultProps = {
	includeTime: false,
	showMonthCnt: 1,
	locale: DatePickerDefaults.locale,
	portal: false,
	showDefaultIcon: false,
}

export default DatePicker
