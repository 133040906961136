// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import cn from 'classnames'
import { connect, } from 'react-redux'
import { useMutation, } from '@apollo/react-hooks'
import { toast, } from 'react-toastify'
import MainComponent from '../../components/main/MainComponent'
import { nameValidation, } from '../../utilits/validateUtilit'
import ButtonElement from '../../elements/button/ButtonElement'
import { ICON_INDEX_ITEM_ARROW, } from '../../constants/iconConstants'
import FormContentContainer from '../../containers/form/FormContentContainer'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onGetDiscountCardsAction, } from '../../store/actions/contentActions'
import SaleHeaderElement from '../../elements/saleHeader/SaleHeaderElement'
import SelectElement from '../../elements/select/SelectElement'
import InputElement from '../../elements/input/InputElement'
import { ADD_DISCOUNT_API, } from '../../api/guest/saleQuery'
import TextModalComponent from '../../components/modal/TextModalComponent'
import { getSaleDataUtil, } from '../../utilits/getMainDataUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import globalStyles from '../../assets/global.module.css'
import styles from './salePage.module.css'

const FIELDS = [
	{
		id: 'cardId',
		value: '',
		defaultValue: '',
		placeholder: 'Номер карты',
		validator: nameValidation,
		className: styles.input,
		type: 'cardReader',
		error: '',
	},
]

const SalePageComponent = ({
	onGetCard,
	onSetDiscount,
	card,
	cardState,
	state,
	onUpdate,
	isModalOpen,
	history,
},) => {
	const onSet = async (variables,) => {
		await onGetCard(variables,)
	}

	const checked = ((card && card.discountTypes) || []).reduce((acc, { id, ...props },) => (`${id}` === `${state.discountTypeId}` ? props : acc), {},)

	return (
		<MainComponent
			isSideBarExist
			isWhite
			isClosed
			title="Предоставить скидку"
		>

			<TextModalComponent
				isOpen={isModalOpen}
				onClose={() => history.push('/guests',)}
				title="Скидка успешно добавлена"
				textButton="Готово"
				onClick={() => history.push('/guests',)}
			/>

			<FormContentContainer
				content={getSaleDataUtil(cardState.item, cardState.errors,)(FIELDS,)}
				onSubmit={onSet}
				wrapperClassName={styles.wrapper}
				loading={false}
				disabled={!!card}
				buttonText={!card ? 'Добавить' : ''}
			/>

			{!!card && (
				<>
					<SaleHeaderElement
						{...card}
					/>
					<SelectElement
						id="discountTypeId"
						name=""
						value={state.discountTypeId}
						label="Скидка"
						placeholder="Предоставить скидку"
						className={styles.select}
						notFound="Нет доступных скидок"
						options={(card.discountTypes || []).map((item,) => ({
							val: item.name,
							key: item.id,
						}),)}
						onChange={onUpdate}
					/>
					{!!state.discountTypeId && (
						<SaleHeaderElement
							applicationArea={(card && card.discountTypes).applicationArea}
							{...checked}
						/>
					)}

					{!!state.discountTypeId && (
						<InputElement
							id="comment"
							value={state.comment}
							defaultValue=""
							placeholder="Комментарий"
							className={styles.comment}
							type="text"
							error=""
							onChange={onUpdate}
							onKeyPress={() => ({})}
						/>
					)}
					<div className={cn('global-form', styles.wrapper,)}>
						<div className={cn(globalStyles.formButtonWrapper,)}>
							<ButtonElement
								type="button"
								disabled={!state.discountTypeId}
								className={cn(globalStyles.btnSubmit,)}
								iconName={ICON_INDEX_ITEM_ARROW}
								onClick={onSetDiscount}
								text="Предоставить"
							/>
						</div>
					</div>
				</>
			)}
		</MainComponent>
	)
}

const Loader = ({ onGetDiscountCard, ...props },) => {
	const [addDiscount, ] = useMutation(ADD_DISCOUNT_API,)
	const [isModalOpen, updateStateModal, ] = useState(false,)
	const [cardState, updateUserState, ] = useState({
		item: {},
		errors: {},
	},)
	const [state, updateState, ] = useState({
		cardId: '',
		discountTypeId: '',
		comment: '',
	},)
	const [card, updateCards, ] = useState(null,)
	const onGetCard = async (variables,) => {
		let errors = {}
		try {
			const currentCard = await onGetDiscountCard(variables,)
			updateCards(currentCard,)
			updateState({
				cardId: variables.cardId,
				discountTypeId: '',
				comment: '',
			},)
		} catch (e) {
			errors = (e.graphQLErrors || []).reduce((app, { extensions, },) => (
				!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
			), {},)
			console.log(e,)
		}

		updateUserState({
			item: variables,
			errors,
		},)
	}

	const onUpdate = (val, id,) => {
		const values = {
			...state,
			[id]: val,
		}
		if (id === 'discountTypeId') values.comment = ''

		updateState(values,)
	}

	const onSetDiscount = async () => {
		try {
			await addDiscount({
				variables: {
					cardId: state.cardId,
					discountTypeId: parseInt(state.discountTypeId, 10,),
					comment: state.comment,
				},
			},)
			updateState({
				cardId: '',
				discountTypeId: '',
				comment: '',
			},)
			updateStateModal(true,)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
	}

	return (
		<SalePageComponent
			card={card}
			state={state}
			cardState={cardState}
			isModalOpen={isModalOpen}
			onSetDiscount={onSetDiscount}
			onGetCard={onGetCard}
			onUpdate={onUpdate}
			{...props}
		/>
	)
}
export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onGetDiscountCard: (variables,) => dispatch(onGetDiscountCardsAction(variables,),),
	}),
)(Loader,)
