// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import cn from 'classnames'
import InputMask from 'react-input-mask'
import IconElement from '../icon/IconElement'
import styles from './input.module.css'
import globalStyles from '../../assets/global.module.css'

const 	InputElement = ({
	id,
	name,
	value,
	label,
	type = 'text',
	notRequired,
	className,
	iconName,
	validator,
	disabled,
	error,
	placeholder,
	toUpperCase,
	onChange,
	onKeyPress,
},) => {
	const [currentType, updateCurrentType, ] = useState(type,)
	return (
		<div className={cn(styles.input, label && styles.labelWrapper, className,)}>
			{iconName && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span
					className={cn(styles.inputIcon, type === 'password' && styles.active,)}
					onClick={() => (type === 'password' ? updateCurrentType(currentType === 'password' ? 'text' : 'password',) : '')}
				>
					<IconElement
						name={iconName}
					/>
				</span>
			)}
			{label && <div className={styles.label}>{label}</div>}
			<input
				// eslint-disable-next-line max-len
				onChange={(e,) => onChange(toUpperCase ? e.target.value.toUpperCase() : e.target.value, id,)}
				onKeyPress={onKeyPress}
				type={currentType}
				name={id}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				required={!!validator && !notRequired}
			/>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}

export const 	InputMaskElement = ({
	id,
	name,
	value,
	label,
	type = 'text',
	mask,
	notRequired,
	className,
	iconName,
	validator,
	disabled,
	error,
	placeholder,
	toUpperCase,
	onChange,
	onKeyPress,
},) => {
	const [currentType, updateCurrentType, ] = useState(type,)
	return (
		<div className={cn(styles.input, label && styles.labelWrapper, className,)}>
			{iconName && (
				// eslint-disable-next-line max-len
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span
					className={cn(styles.inputIcon, type === 'password' && styles.active,)}
					onClick={() => (type === 'password' ? updateCurrentType(currentType === 'password' ? 'text' : 'password',) : '')}
				>
					<IconElement
						name={iconName}
					/>
				</span>
			)}
			{label && <div className={styles.label}>{label}</div>}
			<InputMask
				mask={mask}
				value={value}
				onChange={(e,) => (
					onChange(toUpperCase ? e.target.value.toUpperCase() : e.target.value, id,)
				)}
			>
				{(inputProps,) => (
					<input
						{...inputProps}
						onKeyPress={onKeyPress}
						type={currentType}
						name={id}
						disabled={disabled}
						placeholder={placeholder}
						required={!!validator && !notRequired}
					/>
				)}
			</InputMask>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			{!!error && (
				<label className={cn(globalStyles.labelError,)} htmlFor={name}>
					{error}
				</label>
			)}
		</div>
	)
}

export default InputElement
