import { applyMiddleware, combineReducers, compose, createStore, } from 'redux'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware, } from 'connected-react-router'
import config from './reducers/configReducer'
import user from './reducers/userReducer'
import info from './reducers/infoReducer'
import content from './reducers/contentReducer'
import history from './history'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(combineReducers({
	config,
	user,
	info,
	content,
	router: connectRouter(history,),
},),
{},
composeEnhancers(applyMiddleware(thunk, routerMiddleware(history,),),),)
