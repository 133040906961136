// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import moment from 'moment'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import XLSX from 'xlsx'
import { toast, } from 'react-toastify'
import MainComponent from '../../components/main/MainComponent'
import FilterElement from '../../elements/filter/FilterElement'
import TableComponent from '../../elements/table/TableComponent'
import { DELETE_USER_API, GET_USERS_API, UPDATE_USER_API, } from '../../api/user/userQuery'
import PaginationElement from '../../elements/pagination/PaginationElement'
import CurrentUserComponent from '../../components/currentUser/CurrentUserComponent'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onUserAction, } from '../../store/actions/contentActions'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import { RIGHTS_USERS, rightsContent, } from '../../constants/rightsConstants'

const FIELDS_TABLE = [
	{
		name: 'Сотрудник',
		id: 'NAME',
		isSorted: true,
		getField: (source,) => `${source.firstName} ${source.lastName}`,
	},
	{
		name: 'Статус',
		id: 'status',
		getField: (source,) => (source.status ? 'Активен' : 'Не активен'),
	},
	{
		name: 'Срок действия',
		id: 'BLOCKED_DATE',
		isSorted: true,
		getField: (source,) => (
			source.blockDate ? moment(source.blockedDate,)
				.format('DD.MM.YYYY',) : 'Бессрочный'
		),
	},
	{
		name: 'Уровень доступа',
		id: 'rights',
		getField: (source,) => (source.rights && source.rights.indexOf(RIGHTS_USERS,) >= 0 ? 'Адиминистратор' : 'Пользователь'),
		getTooltip: (source,) => (source.rightsContent || []).join(', ',),
	},
	{
		name: '',
		id: 'arrow',
	},
]

const initState = {
	from: 0,
	size: 10,
}

// eslint-disable-next-line max-len
const UsersPageComponent = ({ content, state, sort, updateState, loading, onUpdate, onDelete, onUnload, onUpdateSort, },) => {
	const [openId, onOpenId, ] = useState(null,)

	const onDeleteElement = () => {
		onDelete({ id: openId, },)
		onOpenId(null,)
	}
	return (
		<MainComponent
			isWhite
			isClosed
			addLink="/user"
			title="ПОЛЬЗОВАТЕЛИ"
		>
			<FilterElement
				onDownload={onUnload}
				fields={[]}
			/>

			{!(loading || !content.items.length) && (
				<>
					<TableComponent
						fields={FIELDS_TABLE}
						content={content.items}
						openId={openId}
						sort={sort}
						disabledField={['status', ]}
						onUpdateSort={onUpdateSort}
						onCollapse={(id,) => onOpenId(id,)}
					>
						{!!openId && !!content.itemsContent[openId] && (
							<CurrentUserComponent
								content={content.itemsContent[openId]}
								onUpdate={onUpdate}
								onDelete={onDeleteElement}
							/>
						)}
					</TableComponent>
					<PaginationElement
						totalCount={content.pagination.total}
						pageSize={content.pagination.size}
						page={(content.pagination.from / content.pagination.size) + 1}
						onChange={(page,) => {
							onOpenId(null,)
							updateState({
								...state,
								from: (page - 1) * content.pagination.size,
							},)
						}}
					/>
				</>
			)}
		</MainComponent>
	)
}

const Loader = ({ onCallActionUser, },) => {
	const [content, updateContent, ] = useState({
		itemsContent: {},
		items: [],
		pagination: {},
	},)

	const [state, updateState, ] = useState(initState,)
	const [sort, updateSort, ] = useState({
		field: 'NAME',
		direction: 'DESC',
	},)

	const [updateUser, ] = useMutation(UPDATE_USER_API,)
	const [deleteUser, ] = useMutation(DELETE_USER_API,)

	const onUpdateContent = (users,) => {
		const items = users.items.map((item,) => ({
			...item,
			rights: (item.rights || [])
				.reduce((acc, right,) => (right ? [...acc, right, ] : acc), [],),
			rightsContent: (item.rights || [])
				.reduce((acc, right,) => (right ? [...acc, rightsContent[right], ] : acc), [],),
		}),)
		const { pagination, } = users
		if (!items.length && state.from > 0) {
			updateState({ ...state, from: state.from - state.size, },)
		} else {
			updateContent({
				itemsContent: items.reduce((acc, item,) => ({
					...acc,
					[item.id]: item,
				}), {},),
				items,
				pagination,
			},)
		}
	}

	const { loading, refetch, fetchMore, } = useQuery(GET_USERS_API,
		{
			variables: {
				...state,
				sort,
			},
			onCompleted: (data,) => {
				onUpdateContent(data.users,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const onUpdateUser = async (variables,) => {
		try {
			const user = await onCallActionUser(updateUser,)(variables,)
			user.rightsContent = (user.rights || [])
				.reduce((acc, right,) => (right ? [...acc, rightsContent[right], ] : acc), [],)
			updateContent({
				// eslint-disable-next-line max-len
				itemsContent: content.items.reduce((acc, item,) => ({
					...acc,
					[item.id]: user.id === item.id ? user : item,
				}), {},),
				items: content.items.map((item,) => (user.id === item.id ? user : item),),
				pagination: content.pagination,
			},)
		} catch (e) {
			console.log(e,)
		}
		return null
	}

	const onUpdateSort = (name,) => updateSort({
		field: name,
		// eslint-disable-next-line no-nested-ternary
		direction: name !== sort.field ? 'DESC' : (sort.direction === 'DESC' ? 'ASC' : 'DESC'),
	},)

	const onUnloadUsers = async () => {
		try {
			return fetchMore({
				variables: {
					from: 0,
					size: content.pagination.total || 0,
					...sort,
				},
				updateQuery: (prev, { fetchMoreResult, },) => {
					if (!fetchMoreResult) return prev
					const users = XLSX.utils.json_to_sheet(fetchMoreResult.users.items.map((item,) => ({
						id: item.id,
						Имя: item.firstName,
						Фамилия: item.lastName,
						Отчество: item.middleName,
						Телефон: item.phone,
						Email: item.email,
						Блокировка: item.blockDate ? moment(item.blockedDate,)
							.format('DD.MM.YYYY',) : 'Бессрочный',
						Статус: item.status ? 'Активен' : 'Не активен',
						Права: (item.rights || [])
							.reduce((acc, right,) => (right ? [...acc, rightsContent[right], ] : acc), [],)
							.join(', ',),
					}),),)
					const wb = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(wb, users, 'Users',)
					XLSX.writeFile(wb, 'users.xlsx',)
					return fetchMoreResult
				},
			},)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
		return null
	}

	const onDeleteUser = async (variables,) => {
		try {
			await onCallActionUser(deleteUser,)(variables,)
			await refetch()
		} catch (e) {
			console.log(e,)
		}
	}

	return (
		<UsersPageComponent
			loading={loading}
			state={state}
			updateState={updateState}
			content={content}
			sort={sort}
			onUpdate={onUpdateUser}
			onDelete={onDeleteUser}
			onUnload={onUnloadUsers}
			onUpdateSort={onUpdateSort}
		/>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onCallActionUser: (action,) => (variables,) => dispatch(onUserAction(action, variables,),),
	}),
)(Loader,)
