import { getInitialState, } from '../../hooks/useWindowSize'
import { DRAWER_VISIBLE_BEFORE_WIDTH, } from '../../constants/configConstants'
import { CONFIG_UPDATE_SIDE_BOARD_VISIBILITY, } from '../actions/configActions'

const initialState = {
	isSideBoardVisible: getInitialState().width > DRAWER_VISIBLE_BEFORE_WIDTH,
}

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case CONFIG_UPDATE_SIDE_BOARD_VISIBILITY:
		return {
			...state,
			isSideBoardVisible: 'isVisible' in action ? action.isVisible : !state.isSideBoardVisible,
		}
	default:
		return state
	}
}
