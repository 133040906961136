import moment from 'moment'
import 'moment/locale/ru'
import { range, } from './ArrayUtil'

export const getMonthShort = (locale,) => {
	moment.locale(locale,)
	return range(0, 12,).map((v,) => moment()
		.localeData()
		.monthsShort(moment().month(v,),),)
}

export const getWeekDays = (locale,) => {
	moment.locale(locale,)
	return range(7,).map((v,) => moment()
		.localeData()
		.weekdaysShort(moment().weekday(v,),),)
}

export const getToday = (locale,) => moment()
	.locale(locale,)
	.format('LL',)
