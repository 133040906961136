import { portLink, } from '../utilits/envUtilit'

const url = `${portLink}8080`
const responseHandler = async (response, resolve, reject,) => {
	try {
		const res = await response.json()
		const error = {
			code: 200,
			message: '',
		}
		console.log(response,)
		console.log(res,)
		if (response.status === 200) {
			resolve(res,)
		} else if (response.status === 423) {
			error.code = response.status
			const manager = res.contact
			const message = manager.phone
				? `Обратитесь к вашему менеджеру ${manager.firstName} ${manager.middleName} ${manager.lastName} ${manager.phone}`
				: `Обратитесь к администратору личного кабинета ${manager.firstName} ${manager.middleName} ${manager.lastName}`
			error.message = `
				Пользователь заблокирован или срок действия истек.
				${message}
			`
			reject(error,)
		} else {
			if (response.status) error.code = response.status
			if (res.code) error.code = res.code
			if (res.message) error.message = res.message
			if (res.error) error.message = res.error
			reject(error,)
		}
	} catch (err) {
		console.log(err,)
		reject({
			code: 600,
			message: 'Возникла проблема',
		},)
	}
}

// eslint-disable-next-line no-async-promise-executor,max-len
export const getLoginQuery = async ({ login, password, },) => new Promise(async (resolve, reject,) => {
	try {
		const response = await fetch(`${url}/login`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({ login: login.replace(/\D/g, '',), password, },),
		},)
		await responseHandler(response, resolve, reject,)
	} catch (err) {
		console.log(err,)
		// eslint-disable-next-line prefer-promise-reject-errors
		reject({
			code: err.code,
			error: err.error,
			message: 'Возникла проблема',
		},)
	}
},)

// eslint-disable-next-line no-async-promise-executor,max-len
export const getRecoveryQuery = async ({ login, },) => new Promise(async (resolve, reject,) => {
	try {
		const response = await fetch(`${url}/recovery`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({ login, },),
		},)
		await responseHandler(response, resolve, reject,)
	} catch (err) {
		console.log(err,)
		// eslint-disable-next-line prefer-promise-reject-errors
		reject({
			code: 600,
			message: 'Возникла проблема',
		},)
	}
},)
