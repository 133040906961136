// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import moment from 'moment'
import { range, } from '../utils/ArrayUtil'
import CalendarContainer from './CalendarContainer'

const Calendar = ({
	base,
	showMonthCnt,
	showToday,
	...props
},) => {
	const [currentBase, updateBase, ] = useState(base,)
	const setBase = (b,) => {
		updateBase(b,)
	}

	return (
		<div className="calendar">
			<div className="calendar__list">
				{range(showMonthCnt,).map((idx,) => (
					<div className="calendar__item" key={idx}>
						<CalendarContainer
							{...props}
							base={currentBase}
							current={moment(currentBase,).add(idx, 'month',)}
							prevIcon={idx === 0}
							nextIcon={idx === showMonthCnt - 1}
							setBase={setBase}
						/>
					</div>
				),)}
			</div>
		</div>
	)
}

Calendar.defaultProps = {
	base: moment(),
	showMonthCnt: 1,
	showToday: false,
}

export default Calendar
