// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import moment from 'moment'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import XLSX from 'xlsx'
import { toast, } from 'react-toastify'
import MainComponent from '../../components/main/MainComponent'
import FilterElement from '../../elements/filter/FilterElement'
import TableComponent from '../../elements/table/TableComponent'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onUserAction, } from '../../store/actions/contentActions'
import { GET_GUEST_API, GET_SESSION_API, } from '../../api/guest/guestQuery'
import PaginationElement from '../../elements/pagination/PaginationElement'
import CurrentGuestComponent from '../../components/currentGuest/CurrentGuestComponent'
import { REMOVE_BOOKING_API, RESEND_BOOKING_API, } from '../../api/guest/bookingQuery'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import {
	SESSIONS_BALANCE,
	SESSIONS_BOOKING,
	SESSIONS_EMPLOYEE,
	SESSIONS_GUEST,
} from '../../constants/sessionsConstants'
import { getDefaultFilterUtil, } from '../../utilits/getMainDataUtilit'
import {
	getContentFields,
	getDateStart,
	getFio, getLicensePlate,
	getSum,
	getType,
	getValidate,
	getValidContent,
	getVid,
} from '../../utilits/getGuestUtilit'
import styles from './guestsPage.module.css'

const FIELDS_TABLE = [
	{
		name: 'ФИО',
		id: 'userId',
		getField: getFio,
	},
	{
		name: 'НОМЕР ТС',
		id: 'car',
		getField: getLicensePlate,
	},
	{
		name: 'Вид',
		id: 'vid',
		getField: (source,) => getVid(source.clientType,),
	},
	{
		name: 'Дата\nсоздания',
		id: 'CREATED_AT',
		isSorted: true,
		getField: (source,) => getDateStart(source,),
	},
	{
		type: 'if',
		validate: getValidate,
		line: 2,
		headers: [{
			name: 'ВЪЕЗД',
			id: 'START_SESSION',
			isSorted: true,
		},
		{
			name: 'ВЫЕЗД',
			id: 'END_SESSION',
			isSorted: true,
		}, ],
		if: [
			{
				colspan: '2',
				id: 'START_SESSION',
				className: styles.start,
				getField: getValidContent,
			},
		],
		else: [
			{
				name: 'ВЪЕЗД',
				id: 'START_SESSION',
				getField: (source,) => (
					source.start ? moment(source.start,).format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'
				),
			},
			{
				name: 'ВЫЕЗД',
				id: 'offDate',
				isSorted: true,
				getField: (source,) => (
					source.end ? moment(source.end,).format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'
				),
			},
		],
	},
	{
		name: 'Начислено',
		id: 'COMPANY_PAYMENT',
		isSorted: true,
		getField: getSum,
	},
	{
		name: 'Тип',
		id: 'type',
		className: styles.type,
		getField: (source,) => getType(source,),
	},
	{
		name: '',
		id: 'arrow',
	},
]

const initState = {
	name: '',
	licensePlate: '',
	clientType: [],
	createdAtFrom: null,
	createdAtTo: null,
	startSessionFrom: null,
	startSessionTo: null,
	endSessionFrom: null,
	endSessionTo: null,
	from: 0,
	size: 10,
}

const getSearchField = (isGuests,) => ([
	{
		id: 'name',
		value: '',
		defaultValue: '',
		placeholder: 'ФИО',
		type: 'text',
		error: '',
	},
	{
		id: 'startSessionFrom&startSessionTo',
		value: [],
		placeholder: 'Дата въезда',
		format: 'DD.MM.YYYY',
		type: 'range',
		error: '',
	},
	{
		id: 'licensePlate',
		value: '',
		defaultValue: '',
		placeholder: 'Номер ТС',
		type: 'text',
		error: '',
	},
	{
		id: 'endSessionFrom&endSessionTo',
		value: [],
		placeholder: 'Дата выезда',
		format: 'DD.MM.YYYY',
		type: 'range',
		error: '',
	},
	{
		id: 'clientType',
		value: [],
		defaultValue: [],
		placeholder: 'Вид',
		type: 'checkbox',
		isButton: true,
		options: isGuests ? [
			{
				key: SESSIONS_GUEST,
				val: 'Скидка',
			},
			{
				key: SESSIONS_BOOKING,
				val: 'Приглашение',
			},
		] : [
			{
				key: SESSIONS_GUEST,
				val: 'Скидка',
			},
			{
				key: SESSIONS_BOOKING,
				val: 'Приглашение',
			},
			{
				key: SESSIONS_EMPLOYEE,
				val: 'Списание',
			},
			{
				key: SESSIONS_BALANCE,
				val: 'Пополнение',
			},
		],
		error: '',
	},
	{
		id: 'createdAtFrom&createdAtTo',
		value: [],
		placeholder: 'Дата создания',
		format: 'DD.MM.YYYY',
		type: 'range',
		error: '',
	},
])

const GuestsPageComponent = ({
	loading,
	state,
	updateState,
	content,
	onUpload,
	isGuests,
	onDeleteBooking,
	onResendBooking,
	history,
	sort,
	onUpdateSort,
},) => {
	const [openId, onOpenId, ] = useState(null,)

	const onEdit = () => {
		history.push({
			pathname: '/guest',
			state: {
				item: content.itemsContent[openId],
				type: 'edit',
			},
		},)
	}

	const onRecreate = () => {
		history.push({
			pathname: '/guest',
			state: {
				item: content.itemsContent[openId],
				type: 'recreate',
			},
		},)
	}

	return (
		<MainComponent
			isWhite
			isClosed
			addLink={isGuests ? '/guest' : ''}
			title={isGuests ? 'Гости' : 'Начисления'}
		>
			<FilterElement
				onDownload={onUpload}
				fields={getDefaultFilterUtil(state,)(getSearchField(isGuests,),)}
				onUpdate={(variables,) => updateState({ ...state, ...variables, },)}
			/>

			{!(loading || !content.items.length) && (
				<>
					<TableComponent
						fields={FIELDS_TABLE}
						content={content.items}
						openId={openId}
						currentItem="i"
						disabledField={['isActive', ]}
						collapsedField={({ clientType, },) => clientType !== SESSIONS_BALANCE}
						fieldsLength={9}
						sort={sort}
						onUpdateSort={onUpdateSort}
						onCollapse={(id,) => onOpenId(id,)}
					>
						{!!openId && !!content.itemsContent[openId] && (
							<CurrentGuestComponent
								fields={getContentFields(content.itemsContent[openId],)}
								transfer={content.itemsContent[openId].transfer}
								currentId={content.itemsContent[openId].id}
								type={content.itemsContent[openId].clientType}
								vehicle={content.itemsContent[openId].vehicle}
								content={content.itemsContent[openId]}
								onEdit={onEdit}
								onRecreate={onRecreate}
								id={openId}
								onResend={() => onResendBooking(
									content.itemsContent[openId].booking.id,
									content.itemsContent[openId].booking.createdAt,
								)}
								onDelete={() => onDeleteBooking(content.itemsContent[openId].booking.id,)}
							/>
						)}
					</TableComponent>
					<PaginationElement
						totalCount={content.pagination.total}
						pageSize={content.pagination.size}
						page={(content.pagination.from / content.pagination.size) + 1}
						onChange={(page,) => {
							onOpenId(null,)
							updateState({
								...state,
								from: (page - 1) * content.pagination.size,
							},)
						}}
					/>
				</>
			)}
		</MainComponent>
	)
}
const Loader = ({ onCallActionUser, location, ...props },) => {
	const [isGuests, ] = useState(location.pathname === '/guests',)
	const [content, updateContent, ] = useState({
		itemsContent: {},
		items: [],
		pagination: {},
	},)

	const [state, updateState, ] = useState(initState,)
	const [sort, updateSort, ] = useState({
		field: 'CREATED_AT',
		direction: 'DESC',
	},)

	const [deleteBooking, ] = useMutation(REMOVE_BOOKING_API,)
	const [resendBooking, ] = useMutation(RESEND_BOOKING_API,)

	const getIsValidDate = (item,) => {
		if (item.clientType !== SESSIONS_BOOKING) return true
		// eslint-disable-next-line max-len
		if ((item.booking || {}).validUntil && new Date((item.booking || {}).validUntil,) > new Date()) return false
		if (item.start) return false
		if (item.end) return false
		return true
	}
	const onUpdateContent = ({ items, pagination, },) => {
		if (!items.length && state.from > 0) {
			updateState({ ...state, from: state.from - state.size, },)
		} else {
			updateContent({
				itemsContent: items.reduce((acc, item,) => ({
					...acc,
					[item.i]: ({
						...item,
						isActive: getIsValidDate(item,),
					}),
				}), {},),
				items: items.map((item,) => ({
					...item,
					isActive: getIsValidDate(item,),
				}),),
				pagination,
			},)
		}
	}

	const { loading, fetchMore, refetch, } = useQuery(isGuests ? GET_GUEST_API : GET_SESSION_API,
		{
			variables: {
				...state,
				...sort,
			},
			onCompleted: (data,) => {
				onUpdateContent(data.guests,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const onUpdateSort = (name,) => updateSort({
		field: name,
		// eslint-disable-next-line no-nested-ternary
		direction: name !== sort.field ? 'DESC' : (sort.direction === 'DESC' ? 'ASC' : 'DESC'),
	},)

	const onDeleteBooking = async (id,) => {
		try {
			await deleteBooking({ variables: { id, }, },)
			await refetch()
		} catch (e) {
			console.log(e,)
		}
	}
	const onResendBooking = async (id, time,) => {
		try {
			await resendBooking({ variables: { id, time, }, },)
			await refetch()
		} catch (e) {
			console.log(e,)
		}
	}

	const onUpload = async () => {
		try {
			return fetchMore({
				variables: {
					from: 0,
					size: content.pagination.total || 0,
					name: state.name,
					licensePlate: state.licensePlate,
					clientType: state.clientType.length > 0 ? state.clientType : ['GUEST', 'BOOKING', 'EMPLOYEE', 'BALANCE', ],
					createdAtFrom: state.createdAtFrom,
					createdAtTo: state.createdAtTo,
					startSessionFrom: state.startSessionFrom,
					startSessionTo: state.startSessionTo,
					endSessionFrom: state.endSessionFrom,
					endSessionTo: state.endSessionTo,
					...sort,
				},
				updateQuery: (prev, { fetchMoreResult, },) => {
					if (!fetchMoreResult) return prev
					const users = XLSX.utils.json_to_sheet(fetchMoreResult.guests.items.map((item,) => ({
						ФИО: getFio(item,),
						'Номер ТС': getLicensePlate(item,),
						Вид: getVid(item.clientType,),
						'Дата создания': getDateStart(item,),
						// eslint-disable-next-line no-nested-ternary
						Въезд: getValidate(item,) ? getValidContent(item,) : (item.start ? moment(item.start,).format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'),
						// eslint-disable-next-line no-nested-ternary
						Выезд: getValidate(item,) ? getValidContent(item,) : (item.end ? moment(item.end,).format('DD.MM.YYYY\n HH:mm:ss',) : 'Нет'),
						Начислено: getSum(item,),
						Тип: getType(item,),
					}),),)
					const wb = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(wb, users, isGuests ? 'Guests' : 'History',)
					XLSX.writeFile(wb, `${isGuests ? 'guests' : 'history'}.xlsx`,)
					return fetchMoreResult
				},
			},)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
		return null
	}
	return (
		<GuestsPageComponent
			loading={loading}
			state={state}
			updateState={updateState}
			content={content}
			isGuests={isGuests}
			sort={sort}
			onUpload={onUpload}
			onDeleteBooking={onDeleteBooking}
			onResendBooking={onResendBooking}
			onUpdateSort={onUpdateSort}
			{...props}
		/>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onCallActionUser: (action,) => (variables,) => dispatch(onUserAction(action, variables,),),
	}),
)(Loader,)
