import { CONTENT_LOADING_UPDATE, } from '../actions/contentActions'

const initialState = {
	loading: false,
}

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case CONTENT_LOADING_UPDATE:
		return {
			...state,
			loading: action.loading,
		}
	default:
		return state
	}
}
