export const VEHICLE_WAIT = 'WAIT'
export const VEHICLE_ACCEPTED = 'ACCEPTED'
export const VEHICLE_NOT_ACCEPTED = 'NOT_ACCEPTED'
export const VEHICLE_BLOCKED = 'BLOCKED'
export const VEHICLE_ACTIVE = 'ACTIVE'
export const VEHICLE_NOT_ACTIVE = 'NOT_ACTIVE'
export const VEHICLE_NOT_DEFINED = 'NOT_DEFINED'

export const vehicleContent = {
	[VEHICLE_WAIT]: 'Ожидает подтверждения',
	[VEHICLE_NOT_ACCEPTED]: 'Не подтвержден',
	[VEHICLE_BLOCKED]: 'Заблокирован',
	[VEHICLE_ACTIVE]: 'Активен',
	[VEHICLE_NOT_ACTIVE]: 'Не активен',
	[VEHICLE_NOT_DEFINED]: 'Не определен',
}

export const vehicleMassContent = {
	4: VEHICLE_WAIT,
	2: VEHICLE_NOT_ACCEPTED,
	1: VEHICLE_BLOCKED,
	0: VEHICLE_NOT_DEFINED,
	5: VEHICLE_ACTIVE,
	3: VEHICLE_NOT_ACTIVE,
}
