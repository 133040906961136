// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import cn from 'classnames'
import moment from 'moment'

import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { removeUserAction, } from '../../store/actions/userActions'
import { numToPriceUtil, } from '../../utilits/numToPriceUtilits'
import globalStyles from '../../assets/global.module.css'
import styles from './main.module.css'
import IconElement from '../../elements/icon/IconElement'
import { ICON_ADD, ICON_INDEX_ITEM_ARROW, } from '../../constants/iconConstants'
import {
	getUserCompanySelector,
	getUserContactsSelector,
} from '../../store/selectors/userSelector'

const contacts = [
	{
		name: 'Контактное лицо',
		getValue: (source,) => `${source.firstName} ${source.lastName}`,
		fieldName: 'contacts',
		getFootnote: null,
		isNumber: false,
	},
	{
		name: 'Телефон',
		getValue: (source,) => source.phone,
		fieldName: 'contacts',
		getFootnote: null,
		isNumber: false,
	},
	{
		name: 'E-mail',
		getValue: (source,) => source.email,
		fieldName: 'contacts',
		getFootnote: null,
		isNumber: false,
	},
]
const configs = [
	{
		name: 'Текущий баланс',
		getValue: (source,) => numToPriceUtil(source.balanceValue, '₽',),
		getFootnote: null,
		isNumber: true,
	},
	{
		name: 'Занято мест',
		getValue: (source,) => `${source.placesUsed} / ${source.placesTotal}`,
		getFootnote: null,
		isNumber: true,
	},
	{
		name: 'Использовано карт',
		getValue: (source,) => `${source.cardUsed} / ${source.cardTotal}`,
		getFootnote: null,
		isNumber: true,
	},
	// {
	// 	name: 'Зоны доступа',
	// 	getValue: (source,) => source.zone,
	// 	getFootnote: null,
	// 	isNumber: false,
	// },
	{
		name: 'Ограничения по балансу',
		getValue: (source,) => (source.balanceLimit ? numToPriceUtil(source.balanceLimit, '₽',) : 'Нет'),
		getFootnote: null,
		isNumber: false,
	},
	{
		name: 'Дата следующей блокировки',
		getValue: (source,) => (
			source.blockDate ? moment(source.blockedDate,).format('DD.MM.YYYY',) : 'Нет'
		),
		getFootnote: null,
		isNumber: false,
	},
	{
		name: 'Тариф',
		getValue: (source,) => (source.postpay ? 'Постоплатный' : 'Предоплатный'),
		getFootnote: null,
		isNumber: false,
	},
	{
		getContent: (source,) => source.tariff.map((tariff,) => ({
			footer: `${numToPriceUtil(tariff.cost, '₽',)} / ${tariff.interval}`,
			value: tariff.area,
		}),),
	},
]

const SideElement = ({ name, value, footnote, isNumber, },) => (
	<div className={cn(styles.block, styles.margin,)}>
		{name && (<div className={styles.name}>{name}</div>)}
		{value && (<div className={cn(styles.value, isNumber && styles.number,)}>{value}</div>)}
		{footnote && (<div className={styles.footnote}>{footnote}</div>)}
	</div>
)

// eslint-disable-next-line max-len
const MainComponent = ({
	fields,
	company,
	children,
	isSideBarExist = false,
	isWhite = false,
	isClosed = false,
	addLink = '',
	onClickBack = null,
	title = '',
},) => {
	const [isMenuOpen, updateMenuOpen, ] = useState(false,)
	return (
		<div className={cn(styles.content, isMenuOpen && styles.isShowInfo,)}>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					{isSideBarExist && (
						// eslint-disable-next-line max-len
						// eslint-disable-next-line react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
						<div
							className={cn(styles.sideBtn, globalStyles.visibleXs,)}
							onClick={() => updateMenuOpen(true,)}
						>
							Показать информацию
						</div>
					)}
					{isSideBarExist && (
						<div className={styles.side}>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								className={cn(styles.close, globalStyles.visibleXs,)}
								type="button"
								onClick={() => updateMenuOpen(false,)}
							/>
							<div className={styles.config}>
								{configs.map(({
									name,
									getContent,
									getValue,
									fieldName = 'company',
									getFootnote,
									isNumber,
								},) => (getContent ? getContent(fields[fieldName],)
									.map(({ footer, value, },) => (
										<SideElement
											name={null}
											value={value}
											footnote={footer}
											isNumber={isNumber}
											key={value + footer}
										/>
									),)
									: (
										<SideElement
											name={name || null}
											value={getValue ? getValue(fields[fieldName],) : null}
											footnote={getFootnote ? getFootnote(fields[fieldName],) : null}
											isNumber={isNumber}
											key={name || getFootnote(fields[fieldName],)}
										/>
									)),)}
							</div>
							<div className={styles.contact}>
								{contacts.map(({
									name,
									getValue,
									fieldName = 'company',
									getFootnote,
									isNumber,
								},) => (
									<SideElement
										name={name || null}
										value={getValue ? getValue(fields[fieldName],) : null}
										footnote={getFootnote ? getFootnote(fields[fieldName],) : null}
										isNumber={isNumber}
										key={name || getFootnote(fields[fieldName],)}
									/>
								),)}
							</div>
						</div>
					)}
					<div className={cn(styles.wrapperContent, isWhite && styles.wrapperContentWhite,)}>
						{onClickBack && (
							// eslint-disable-next-line max-len
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
							<div className={globalStyles.back} onClick={onClickBack}>
								<IconElement name={ICON_INDEX_ITEM_ARROW} />
							</div>
						)}
						{isClosed && <Link className={globalStyles.close} to="/" />}
						{!!title && (
							<div className={globalStyles.title}>
								{title}
								{/* eslint-disable-next-line max-len */}
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/control-has-associated-label,max-len */}
								{!!addLink && <Link className={globalStyles.titleAdd} to={addLink}><IconElement name={ICON_ADD} /></Link>}
							</div>
						)}
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(
	(state,) => ({
		fields: {
			company: getUserCompanySelector(state,),
			contacts: getUserContactsSelector(state,),
		},
	}),
	(dispatch,) => ({
		onRemoveUser: () => dispatch(removeUserAction(),),
	}),
)(MainComponent,)
