export const chunk = (arr, n,) => {
	const result = []
	let i = 0
	while (i < arr.length / n) {
		result.push(arr.slice(i * n, i * n + n,),)
		i += 1
	}

	return result
}

export const range = (n1, n2,) => {
	const result = []
	let first = !n2 ? 0 : n1
	let last = n2

	if (!last) {
		last = n1
	}

	while (first < last) {
		result.push(first,)
		first += 1
	}
	return result
}

export const repeat = (el, n,) => range(n,).map(() => el,)
