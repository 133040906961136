// eslint-disable-next-line no-unused-vars
import React from 'react'
import cn from 'classnames'
import IconElement from '../icon/IconElement'
import styles from './button.module.css'

const ButtonElement = ({
	type = 'submit',
	isAdd = false,
	isPrimary = false,
	className,
	iconName,
	onClick,
	disabled = false,
	text,
},) => (
	// eslint-disable-next-line react/button-has-type
	<button
		className={cn(
			className,
			styles.btn,
			isPrimary && styles.btnPrimary,
			isAdd && styles.addBtn,
			!text && iconName && styles.onlyIcon,
		)}
		type={type}
		onClick={onClick}
		disabled={disabled}
	>
		{text}
		<i />
		{!!iconName && (
			<IconElement
				className={cn(styles.btnArrow,)}
				name={iconName}
			/>
		)}
	</button>
)

export default ButtonElement
