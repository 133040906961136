import React, { useState, } from 'react'
import styles from './paginationElement.module.css'

const getPages = (totalCounts, page, pageSize,) => {
	const totalPages = Math.ceil(totalCounts / pageSize,)

	let startPage
	let endPage

	if (totalPages <= 5) {
		startPage = 1
		endPage = totalPages
	} else if (page <= 3) {
		startPage = 1
		endPage = 5
	} else if (page + 2 >= totalPages) {
		startPage = totalPages - 3
		endPage = totalPages
	} else {
		startPage = page - 2
		endPage = page + 2
	}

	const startIndex = (page - 1) * pageSize
	const endIndex = Math.min(startIndex + pageSize - 1, totalCounts - 1,)
	const pages = [...Array(endPage + 1 - startPage,).keys(), ].map((i,) => startPage + i,)
	return {
		totalItems: totalCounts,
		currentPage: page,
		pageSize,
		totalPages,
		startPage,
		endPage,
		startIndex,
		endIndex,
		pages,
	}
}

const DotsElement = () => (
	<li>...</li>
)
const PageLinkElement = ({ onChange, currentPage, page, },) => (
	// eslint-disable-next-line max-len
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
	<li onClick={() => onChange(page,)}>
		<span href="#" className={currentPage === page && styles.isActive}>{page}</span>
	</li>
)

const PaginationElement = ({ totalCount, pageSize, page, onChange, },) => {
	const [{ currentPage, endPage, pages, startPage, totalPages, }, updatePagesInfo, ] = useState(
		getPages(totalCount, page, pageSize,),
	)

	React.useEffect(() => {
		updatePagesInfo(getPages(totalCount, page, pageSize,),)
	}, [totalCount, page, pageSize, ],)

	if (totalCount <= pageSize) return null
	return (
		<div className={styles.pager}>
			{/* eslint-disable-next-line max-len */}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
			{currentPage > 1 && (<div className={styles.pagerPrev} onClick={() => onChange(page - 1,)} />)}
			<ul>
				{startPage !== 1 && (
					<PageLinkElement onChange={onChange} currentPage={currentPage} page={1} />
				)}
				{startPage > 2 && <DotsElement />}
				{pages.map((key,) => (
					<PageLinkElement
						key={key}
						onChange={onChange}
						currentPage={currentPage}
						page={key}
					/>
				),)}
				{endPage < totalPages - 1 && <DotsElement />}
				{endPage < totalPages && (
					<PageLinkElement onChange={onChange} currentPage={page} page={totalPages} />
				)}

			</ul>
			{/* eslint-disable-next-line max-len */}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,max-len */}
			{endPage > currentPage && (<div className={styles.pagerNext} onClick={() => onChange(page + 1,)} />)}
		</div>
	)
}

export default PaginationElement
