// eslint-disable-next-line no-unused-vars
import React, { useRef, useState, } from 'react'
import * as CX from 'classnames'
import { getDivPosition, getDomHeight, } from '../utils/DOMUtil'
import Backdrop from './Backdrop'

const Picker = ({
	portal,
	disabled,
	direction,
	readOnly,
	className,
	renderTrigger,
	renderContents,
},) => {
	const triggerRef = useRef(null,)
	const contentsRef = useRef(null,)
	const [state, updateState, ] = useState({
		show: false,
		position: {
			left: '',
			top: '',
		},
	},)

	// eslint-disable-next-line no-return-await
	const hideContents = async () => {
		await updateState(
			{
				...state,
				show: false,
			},
		)
	}

	const showContents = async (e,) => {
		e.stopPropagation()
		if (disabled || readOnly) return
		const content = {
			...state,
			show: true,
		}

		if (!portal) {
			content.position = getDivPosition(
				triggerRef.current,
				direction,
				getDomHeight(contentsRef.current,),
			)
		}

		await updateState(
			content,
		)
	}

	return (
		<div className="picker">
			{/* eslint-disable-next-line max-len */}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div className="picker__trigger" onClick={showContents} ref={triggerRef}>
				{renderTrigger({
					actions: {
						show: showContents,
						hide: hideContents,
					},
				},)}
			</div>
			{state.show && (
				<div
					className={CX('picker__container', portal, className,)}
					role="dialog"
					aria-modal="true"
					style={state.position}
					ref={contentsRef}
				>
					{renderContents({
						actions: {
							show: showContents,
							hide: hideContents,
						},
					},)}
				</div>
			)}
			<Backdrop show={state.show} invert={portal} onClick={hideContents} />
		</div>
	)
}

export default Picker
