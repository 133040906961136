import {
	VEHICLE_ACCEPTED,
	VEHICLE_BLOCKED,
	VEHICLE_NOT_ACCEPTED,
	VEHICLE_WAIT,
	vehicleContent,
	vehicleMassContent,
} from '../constants/vehiclesConstants'

export const getVehicleMass = (vehicle,) => {
	if (vehicle.systemStatus === VEHICLE_BLOCKED) return 1
	if (vehicle.systemStatus === VEHICLE_WAIT) return 4
	if (vehicle.systemStatus === VEHICLE_NOT_ACCEPTED) return 2
	if (vehicle.systemStatus === VEHICLE_ACCEPTED) return vehicle.status ? 5 : 3
	return 0
}

export const getVehicleTranslate = (mass,) => vehicleContent[vehicleMassContent[mass]]

export const getVehicleStatusUtil = (vehicle = {},) => getVehicleTranslate(
	getVehicleMass(vehicle,),
)

export const getEmployeeStatusUtil = (vehicles = [],) => getVehicleTranslate(
	vehicles.reduce((acc, vehicle,) => (
		getVehicleMass(vehicle,) > acc ? getVehicleMass(vehicle,) : acc
	), 0,),
)

export const getVehicleStatusIsActive = (vehicle = {},) => getVehicleMass(vehicle,) >= 5

export const getEmployeeStatusIsActive = (vehicles = [],) => vehicles.reduce((acc, vehicle,) => (
	getVehicleMass(vehicle,) > acc ? getVehicleMass(vehicle,) : acc
), 0,) >= 5
