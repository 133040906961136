// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import { connect, } from 'react-redux'
import { toast, } from 'react-toastify'
import styles from './employeePage.module.css'
import MainComponent from '../../components/main/MainComponent'
import FormContentContainer from '../../containers/form/FormContentContainer'
import {
	emailValidation,
	miniCarValidation,
	nameValidation,
	phoneValidation,
} from '../../utilits/validateUtilit'
import { ADD_EMPLOYEE_API, GET_FREE_CARDS_API, } from '../../api/employee/employeeQuery'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onUserAction, } from '../../store/actions/contentActions'
import { getEmployeeDataUtil, } from '../../utilits/getMainDataUtilit'
import TextModalComponent from '../../components/modal/TextModalComponent'
import { getFormatEmployeeDateUtil, } from '../../utilits/getEmployeeUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'

const FIELDS = [
	{
		id: 'firstName',
		value: '',
		defaultValue: '',
		placeholder: 'Имя',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'lastName',
		value: '',
		defaultValue: '',
		placeholder: 'Фамилия',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'middleName',
		value: '',
		defaultValue: '',
		placeholder: 'Отчество',
		type: 'text',
		error: '',
	},
	{
		id: 'phone',
		value: '',
		defaultValue: '',
		placeholder: 'Телефон',
		validator: phoneValidation,
		type: 'tel',
		error: '',
	},
	{
		id: 'email',
		value: '',
		defaultValue: '',
		placeholder: 'Email',
		validator: emailValidation,
		type: 'phone',
		error: '',
	},
	{
		id: 'vehicleList',
		value: [
			{
				car: {
					vehicleLicensePlate: '',
					vehicleModel: '',
				},
				cardId: '',
			},
		],
		type: 'kit',
		buttonText: 'Добавить автомобиль',
		fields: [
			{
				id: 'car',
				value: {
					vehicleLicensePlate: '',
					vehicleModel: '',
				},
				defaultValue: {
					vehicleLicensePlate: '',
					vehicleModel: '',
				},
				label: {
					vehicleLicensePlate: 'Гос. номер ТС',
					vehicleModel: 'Марка ТС',
				},
				placeholder: {
					vehicleLicensePlate: 'A000AA000',
					vehicleModel: 'BMW',
				},
				nullExist: true,
				validator: miniCarValidation,
				type: 'carMini',
				error: '',
			},
			{
				id: 'cardId',
				value: '',
				defaultValue: '',
				placeholder: '0000000',
				label: 'Карта',
				type: 'select',
				notFound: 'Нет доступных карт',
				options: [],
				error: '',
			},
		],
	},
]

const EmployeePageComponent = ({ history, loading, cards, onAdd, isModalOpen, state, },) => (
	<MainComponent
		isWhite
		onClickBack={history.goBack}
		isClosed
		title="Новый сотрудник"
	>
		<TextModalComponent
			isOpen={isModalOpen}
			onClose={() => history.push('/employers',)}
			title="Новый сотрудник успешно добавлен"
			textButton="Готово"
			onClick={() => history.push('/employers',)}
		/>
		{!(loading) && (
			<FormContentContainer
				content={getEmployeeDataUtil(cards, state.item, state.errors,)(FIELDS,)}
				onSubmit={onAdd}
				wrapperClassName={styles.wrapper}
				loading={false}
				buttonText="Добавить"
			/>
		)}
	</MainComponent>
)

const Loader = ({ onCallActionUser, ...props },) => {
	const [cards, updateCards, ] = useState([],)
	const [addEmployee, ] = useMutation(ADD_EMPLOYEE_API,)
	const [isModalOpen, updateStateModal, ] = useState(false,)
	const [state, updateState, ] = useState({
		item: {},
		errors: {},
	},)
	const onAdd = async (variables,) => {
		try {
			await onCallActionUser(addEmployee,)({
				...variables,
				vehicleList: variables.vehicleList.reduce(
					(acc, { car, cardId, },) => ([...acc, {
						cardId,
						licensePlate: car.vehicleLicensePlate,
						model: car.vehicleModel,
					}, ]
					), [],
				),
			},)
			updateStateModal(true,)
		} catch (e) {
			updateState({
				...state,
				item: getFormatEmployeeDateUtil(variables,),
				errors: {
					variables,
					// eslint-disable-next-line max-len
					addVehicle: variables.vehicleList.reduce((acc, item, index,) => ({ ...acc, [index]: index, }), {},),
					validation: e.validation,
				},
			},)
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
	}
	const { loading, } = useQuery(GET_FREE_CARDS_API,
		{
			onCompleted: (data,) => {
				updateCards(data.cards,)
			},
			notifyOnNetworkStatusChange: true,
		},)

	return (
		<EmployeePageComponent
			loading={loading}
			cards={cards}
			onAdd={onAdd}
			isModalOpen={isModalOpen}
			state={state}
			{...props}
		/>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onCallActionUser: (action,) => (variables,) => dispatch(onUserAction(action, variables,),),
	}),
)(Loader,)
