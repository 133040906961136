// eslint-disable-next-line no-unused-vars
import React from 'react'

const defaultProps = {
	title: '',
}

const CalendarHead = ({
	onPrev,
	onNext,
	title,
	month,
	year,
	onTitleClick,
},) => (
	<div className="calendar__head">
		{!!title && (
			<>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
				<h2 className="calendar__head--title" onClick={onTitleClick}>
					{title}
				</h2>
			</>
		)}
		{!title && (
			<>
				<h2 className="calendar__head--title">
					{month}
				</h2>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
				<h2 className="calendar__head--title" onClick={onTitleClick}>
					{year}
				</h2>
			</>
		)}
		<div className="calendar__head--buttons">
			<div className="calendar__head--prev">
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button type="button" onClick={onPrev} className="calendar__head--button" />
			</div>
			<div className="calendar__head--next">
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button type="button" onClick={onNext} className="calendar__head--button" />
			</div>
		</div>
	</div>
)

CalendarHead.defaultProps = defaultProps

export default CalendarHead
