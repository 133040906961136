export const CONFIG_UPDATE_SIDE_BOARD_VISIBILITY = 'CONFIG_UPDATE_SIDE_BOARD_VISIBILITY'

export const updateSideBoardVisibilityAction = (isVisible = true,) => (dispatch,) => dispatch({
	type: CONFIG_UPDATE_SIDE_BOARD_VISIBILITY,
	isVisible,
},)

export const toggleSideBoardVisibilityAction = () => (dispatch,) => dispatch({
	type: CONFIG_UPDATE_SIDE_BOARD_VISIBILITY,
},)
