import clientGQL from '../../api/graphql'
import { GET_DISCOUNT_TYPE_API, } from '../../api/guest/saleQuery'

export const CONTENT_LOADING_UPDATE = 'CONTENT_LOADING_UPDATE'

export const setLoadingContentAction = (loading,) => (dispatch,) => dispatch({
	type: CONTENT_LOADING_UPDATE,
	loading,
},)

export const onUserAction = (action, variables,) => async (dispatch,) =>
	// eslint-disable-next-line no-async-promise-executor,implicit-arrow-linebreak
	 new Promise(async (resolve, reject,) => {
		try {
			dispatch(setLoadingContentAction(true,),)
			const { data, } = await action({
				variables,
			},)
			dispatch(setLoadingContentAction(false,),)
			resolve(data.user,)
		} catch (e) {
			console.error(
				'Проблема: ',
				e || '',
			)
			e.validation = (e.graphQLErrors || []).reduce((app, { extensions, },) => (
				!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
			), {},)
			dispatch(setLoadingContentAction(false,),)
			reject(e,)
		}
	},)

export const onGuestAction = (action, variables,) => async (dispatch,) =>
	// eslint-disable-next-line no-async-promise-executor,implicit-arrow-linebreak
	 new Promise(async (resolve, reject,) => {
		try {
			dispatch(setLoadingContentAction(true,),)
			const { data, } = await action({
				variables,
			},)
			dispatch(setLoadingContentAction(false,),)
			resolve(data.guest,)
		} catch (e) {
			console.error(
				'Проблема: ',
				e || '',
			)
			e.validation = (e.graphQLErrors || []).reduce((app, { extensions, },) => (
				!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
			), {},)
			dispatch(setLoadingContentAction(false,),)
			reject(e,)
		}
	},)

export const onGetDiscountCardsAction = (variables,) => async (dispatch,) =>
	// eslint-disable-next-line no-async-promise-executor,implicit-arrow-linebreak
	 new Promise(async (resolve, reject,) => {
		try {
			dispatch(setLoadingContentAction(true,),)
			const { data, } = await clientGQL.query({
				query: GET_DISCOUNT_TYPE_API,
				variables,
			},)
			dispatch(setLoadingContentAction(false,),)
			resolve(data.cards,)
		} catch (e) {
			console.error(
				'Проблема: ',
				e || '',
			)

			e.validation = (e.graphQLErrors || []).reduce((app, { extensions, },) => (
				!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
			), {},)
			dispatch(setLoadingContentAction(false,),)
			reject(e,)
		}
	},)
