// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import { connect, } from 'react-redux'
import { useMutation, } from '@apollo/react-hooks'
import { toast, } from 'react-toastify'
import moment from 'moment'
import MainComponent from '../../components/main/MainComponent'
import FormContentContainer from '../../containers/form/FormContentContainer'
import styles from '../EmployeePage/employeePage.module.css'
import {
	dateValidation,
	emailValidation,
	nameValidation, passwordValidation,
	phoneValidation,
} from '../../utilits/validateUtilit'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onUserAction, } from '../../store/actions/contentActions'
import { ADD_USER_API, } from '../../api/user/userQuery'
import TextModalComponent from '../../components/modal/TextModalComponent'
import { getFormatUserDateUtil, } from '../../utilits/getUserUtilit'
import { getUserDataUtil, } from '../../utilits/getMainDataUtilit'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import {
	RIGHTS_BOOKING,
	RIGHTS_DISCOUNT,
	RIGHTS_EMPLOYEE,
	RIGHTS_GUEST, RIGHTS_HISTORY, RIGHTS_LOGS,
} from '../../constants/rightsConstants'

const FIELDS = [
	{
		id: 'lastName',
		value: '',
		defaultValue: '',
		placeholder: 'Фамилия',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'firstName',
		value: '',
		defaultValue: '',
		placeholder: 'Имя',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'middleName',
		value: '',
		defaultValue: '',
		placeholder: 'Отчество',
		validator: nameValidation,
		type: 'text',
		error: '',
	},
	{
		id: 'phone',
		value: '',
		defaultValue: '',
		placeholder: 'Телефон',
		validator: phoneValidation,
		type: 'tel',
		error: '',
	},
	{
		id: 'email',
		value: '',
		defaultValue: '',
		placeholder: 'Email',
		validator: emailValidation,
		type: 'phone',
		error: '',
	},
	{
		id: 'password',
		value: '',
		defaultValue: '',
		placeholder: 'Пароль',
		validator: passwordValidation,
		type: 'password',
		error: '',
	},
	{
		id: 'rights',
		value: ['BOOKING', ],
		defaultValue: ['BOOKING', ],
		placeholder: 'Права пользователя',
		type: 'checkbox',
		options: [
			{
				key: RIGHTS_BOOKING,
				val: 'Гостевой пропуск',
			},
			{
				key: RIGHTS_DISCOUNT,
				val: 'Скидка',
			},
			{
				key: RIGHTS_GUEST,
				val: 'Гости',
			},
			{
				key: RIGHTS_EMPLOYEE,
				val: 'Сотрудники',
			},
			{
				key: RIGHTS_HISTORY,
				val: 'Начисления',
			},
			{
				key: RIGHTS_LOGS,
				val: 'Действия пользователей',
			},
		],
		error: '',
	},
	{
		id: 'blockedDate',
		value: '',
		placeholder: 'Срок действия',
		nullExist: true,
		validator: dateValidation,
		format: 'DD.MM.YYYY HH:mm',
		minDate: moment(),
		type: 'date',
		error: '',
	},
]

const UserPageComponent = ({ loading, onAddUser, history, },) => {
	const [addUser, ] = useMutation(ADD_USER_API,)
	const [isModalOpen, updateStateModal, ] = useState(false,)
	const [state, updateState, ] = useState({
		item: {},
		errors: {},
	},)
	const onAdd = async (variables,) => {
		try {
			await onAddUser(addUser,)(variables,)
			updateStateModal(true,)
		} catch (e) {
			updateState({
				...state,
				item: getFormatUserDateUtil(variables,),
				errors: e.validation || {},
			},)
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
	}

	return (
		<MainComponent
			isWhite
			isClosed
			title="НОВЫЙ ПОЛЬЗОВАТЕЛЬ"
		>
			<TextModalComponent
				isOpen={isModalOpen}
				onClose={() => history.push('/users',)}
				title="Пользователь успешно добавлен"
				textButton="Готово"
				onClick={() => history.push('/users',)}
			/>
			<FormContentContainer
				content={getUserDataUtil(state.item, state.errors,)(FIELDS,)}
				onSubmit={onAdd}
				wrapperClassName={styles.wrapper}
				loading={loading}
				buttonText="Добавить"
			/>
		</MainComponent>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
	}),
	(dispatch,) => ({
		onAddUser: (action,) => (variables,) => dispatch(onUserAction(action, variables,),),
	}),
)(UserPageComponent,)
