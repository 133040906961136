import moment from 'moment'
import { chunk, repeat, range, } from './ArrayUtil'
import { getMonthShort, } from './LocaleUtil'

export const getDayMatrix = (year, month, locale,) => {
	const date = moment()
		.isoWeekday(1,)
		.year(year,)
		.month(month,)

	const startOfMonth = date.startOf('month',).date()
	const endOfMonth = date.endOf('month',).date()

	let startDay = date.startOf('month',).day() - 1
	if (startDay < 0) startDay = 6
	const remain = (startDay + endOfMonth) % 7

	return chunk(
		[
			...repeat(' ', startDay,),
			...range(startOfMonth, endOfMonth + 1,).map((v,) => `${v}`,),
			...(7 - remain === 7 ? [] : repeat(' ', 7 - remain,)),
		],
		7,
	)
}

export const getMonthMatrix = (locale,) => chunk(getMonthShort(locale,), 3,)

export const getYearMatrix = (year,) => chunk(range(year - 4, year + 5,).map((v,) => `${v}`,), 3,)

export const isDayEqual = (day1, day2,) => {
	if (!day1 || !day2) return false
	return moment(day1,).isSame(day2, 'date',)
}

export const isDayAfter = (day1, day2,) => moment(day1,).isAfter(day2, 'date',)

export const isDayBefore = (day1, day2,) => moment(day1,).isBefore(day2, 'date',)

export const isDayRange = (date, start, end,) => {
	if (!start || !end) return false

	return isDayAfter(date, start,) && isDayBefore(date, end,)
}

export const formatDate = (date, format,) => {
	if (date === undefined) return ''
	return moment(date,).format(format,)
}
