// eslint-disable-next-line no-unused-vars
import React from 'react'

const IconBase = ({ size, color, children, ...props },) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill={color}
		{...props}
		viewBox="0 0 24 24"
	>
		{children}
	</svg>
)

export default IconBase
