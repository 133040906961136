// eslint-disable-next-line no-unused-vars
import React from 'react'

const TimeInput = ({ onChange, onBlur, value, },) => {
	const handleKeyDown = (e,) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			e.stopPropagation()
		}
	}
	return (
		<div className="time-input">
			<div className="time-input__text">
				<input type="text" value={value} onChange={onChange} onBlur={onBlur} onKeyDown={handleKeyDown} />
			</div>
		</div>
	)
}

TimeInput.defaultProps = {
	value: 0,
}

export default TimeInput
