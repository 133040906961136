// eslint-disable-next-line no-unused-vars
import React, { useEffect, } from 'react'
import { Link, withRouter, } from 'react-router-dom'

import { connect, } from 'react-redux'
import Logo from '../../assets/images/logo.svg'
import styles from './header.module.css'
import {
	getTokenSelector,
	getUserCompanySelector,
	getUserSelector,
} from '../../store/selectors/userSelector'
import { getUserAction, removeUserAction, } from '../../store/actions/userActions'

// eslint-disable-next-line max-len
const HeaderComponent = ({
	children,
	user,
	token,
	company,
	onRemoveUser,
	onGetUser,
	history,
},) => {
	useEffect(() => {
		if (token) {
			onGetUser()
		}
	}, [history.location.pathname, ],)
	return (
		<div>
			<div className={styles.header}>
				<div className={styles.top}>
					<div className={styles.container}>
						<div className={styles.left}>
							<Link to="/" className={styles.logo}><img src={Logo} alt="" /></Link>
							<div className={styles.link}>
								<a href="/instruction.pdf" target="_blank">Инструкция пользователя</a>
							</div>
						</div>
						<ul className={styles.menu}>
							<li><Link to="/">{`${user.firstName} ${user.lastName}`}</Link></li>
							<li><Link to="#" onClick={onRemoveUser}>Выход</Link></li>
						</ul>
					</div>
				</div>
				<div className={styles.bottom}>
					<div className={styles.container}>
						<h1 className={styles.title}>{company.name}</h1>
					</div>
				</div>
			</div>
			<>
				{children}
			</>
		</div>
	)
}

export default withRouter(connect(
	(state,) => ({
		user: getUserSelector(state,),
		company: getUserCompanySelector(state,),
		token: getTokenSelector(state,),
	}),
	(dispatch,) => ({
		onRemoveUser: () => dispatch(removeUserAction(),),
		onGetUser: () => dispatch(getUserAction(),),
	}),
)(HeaderComponent,),)
