// eslint-disable-next-line no-unused-vars
import React from 'react'
import {
	ICON_ADD, ICON_ARROW,
	ICON_DELETE,
	ICON_EDIT,
	ICON_INDEX_ITEM_ARROW,
	ICON_LOGIN_NAME,
	ICON_LOGIN_PASSWORD, ICON_LOGIN_PHONE, ICON_MAIL,
	ICON_REFRESH,
	ICON_SEARCH,
	ICON_SESSION_1,
	ICON_SESSION_2,
	ICON_SESSION_3,
	ICON_SESSION_4,
	ICON_SETTING,
} from '../../constants/iconConstants'

const IconAdd = ({ className, },) => (
	<svg className={className} viewBox="0 0 38 38">
		<path fill="#0000BC" d="M0 20c0 9.941 8.059 18 18 18v-1C8.611 37 1 29.389 1 20H0z" />
		<path
			fill="transparent"
			stroke="#0000BC"
			d="M37.5 17.5c0 9.389-7.611 17-17 17s-17-7.611-17-17 7.611-17 17-17 17 7.611 17 17z"
		/>
		<path
			fill="#0000BC"
			d="M20.9 17.098v-7.2h-.8v7.2h-7.2v.8h7.2v7.2h.8v-7.2h7.2v-.8h-7.2zm0 0h-.8v.8h.8v-.8z"
		/>
		<path
			fill="#0000BC"
			d="M20.9 17.098v-7.2h-.8v7.2h-7.2v.8h7.2v7.2h.8v-7.2h7.2v-.8h-7.2zm0 0h-.8v.8h.8v-.8z"
		/>
	</svg>
)

const IconDelete = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			d="M15 3h-5v2H4v1h2v16h13V6h2V5h-6V3zm-1 2h-3V4h3v1zm-4 1h8v15H7V6h3zm3 3h-1v9h1V9zM9 9h1v9H9V9zm7 0h-1v9h1V9z"
			clipRule="evenodd"
		/>
		<path
			d="M15 3h-5v2H4v1h2v16h13V6h2V5h-6V3zm-1 2h-3V4h3v1zm-4 1h8v15H7V6h3zm3 3h-1v9h1V9zM9 9h1v9H9V9zm7 0h-1v9h1V9z"
			clipRule="evenodd"
		/>
	</svg>
)

const IconEdit = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			d="M16.589 2.826l5.657 5.657L10.47 20.257l-7.206 1.55 1.55-7.207L16.588 2.826zM5.756 15.073l-.026.026-1.162 5.404 5.404-1.161.026-.026-4.242-4.243zm.707-.707l4.242 4.243L20.831 8.483 16.59 4.24 6.463 14.366z"
			clipRule="evenodd"
		/>
		<path
			d="M16.589 2.826l5.657 5.657L10.47 20.257l-7.206 1.55 1.55-7.207L16.588 2.826zM5.756 15.073l-.026.026-1.162 5.404 5.404-1.161.026-.026-4.242-4.243zm.707-.707l4.242 4.243L20.831 8.483 16.59 4.24 6.463 14.366z"
			clipRule="evenodd"
		/>
	</svg>
)
const IconIndexItemAlert = ({ className, },) => (
	<svg className={className} viewBox="0 0 16 14">
		<path
			d="M8.57 13.94l-.752-.688 5.368-5.764H0V6.464h13.137L7.818.748 8.57.06l6.46 6.943-6.46 6.938z"
			clipRule="evenodd"
		/>
	</svg>
)
const IconLoginName = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 26">
		<path
			fillRule="evenodd"
			d="M10.09 3.5l-.4.199L6.862 7.46l-.094.382 1.014 6.059.118.249 1.896 2.143-6.146 3.363-.25.336-.4 1.906.489.602h18.754l.488-.606-.412-1.905-.248-.333-6.146-3.363 1.896-2.143.118-.249 1.014-6.059-.093-.382-2.83-3.761-.4-.199h-5.54zM4.332 20.423l6.493-3.553.134-.769-2.214-2.507-.955-5.705L10.339 4.5h5.042l2.551 3.389-.955 5.705-2.217 2.507.135.769 6.495 3.554.233 1.076H4.105l.227-1.077z"
			clipRule="evenodd"
		/>
		<path
			fillRule="evenodd"
			d="M10.09 3.5l-.4.199L6.862 7.46l-.094.382 1.014 6.059.118.249 1.896 2.143-6.146 3.363-.25.336-.4 1.906.489.602h18.754l.488-.606-.412-1.905-.248-.333-6.146-3.363 1.896-2.143.118-.249 1.014-6.059-.093-.382-2.83-3.761-.4-.199h-5.54zM4.332 20.423l6.493-3.553.134-.769-2.214-2.507-.955-5.705L10.339 4.5h5.042l2.551 3.389-.955 5.705-2.217 2.507.135.769 6.495 3.554.233 1.076H4.105l.227-1.077z"
			clipRule="evenodd"
		/>
	</svg>
)
const IconLoginPhone = ({ className, },) => (
	<svg className={className} viewBox="0 0 34 34">
		<path
			fillRule="evenodd"
			d="M25.043,0H9.439c-1.3,0-2.364,1.063-2.364,2.364v7.483v14.789v7.483c0,1.301,1.064,2.363,2.364,2.363h15.604c1.3,0,2.364-1.062,2.364-2.363v-7.483V9.847V2.364C27.407,1.063,26.343,0,25.043,0z M12.804,31.76h-2.548c-0.469,0-0.85-0.38-0.85-0.849c0-0.47,0.381-0.851,0.85-0.851h2.548c0.469,0,0.85,0.381,0.85,0.851C13.654,31.38,13.273,31.76,12.804,31.76z M12.804,28.52h-2.548c-0.469,0-0.85-0.381-0.85-0.85s0.381-0.85,0.85-0.85h2.548c0.469,0,0.85,0.381,0.85,0.85S13.273,28.52,12.804,28.52z M12.804,25.28h-2.548c-0.469,0-0.85-0.38-0.85-0.849c0-0.471,0.381-0.851,0.85-0.851h2.548c0.469,0,0.85,0.38,0.85,0.851C13.654,24.9,13.273,25.28,12.804,25.28z M12.804,22.041h-2.548c-0.469,0-0.85-0.381-0.85-0.85s0.381-0.851,0.85-0.851h2.548c0.469,0,0.85,0.382,0.85,0.851S13.273,22.041,12.804,22.041z M18.515,31.76h-2.547c-0.47,0-0.85-0.38-0.85-0.849c0-0.47,0.38-0.851,0.85-0.851h2.547c0.47,0,0.85,0.381,0.85,0.851C19.364,31.38,18.984,31.76,18.515,31.76z M18.515,28.52h-2.547c-0.47,0-0.85-0.381-0.85-0.85s0.38-0.85,0.85-0.85h2.547c0.47,0,0.85,0.381,0.85,0.85S18.984,28.52,18.515,28.52z M18.515,25.28h-2.547c-0.47,0-0.85-0.38-0.85-0.849c0-0.471,0.38-0.851,0.85-0.851h2.547c0.47,0,0.85,0.38,0.85,0.851C19.364,24.9,18.984,25.28,18.515,25.28z M18.515,22.041h-2.547c-0.47,0-0.85-0.381-0.85-0.85s0.38-0.851,0.85-0.851h2.547c0.47,0,0.85,0.382,0.85,0.851S18.984,22.041,18.515,22.041z M24.226,31.76h-2.549c-0.469,0-0.85-0.38-0.85-0.849c0-0.47,0.381-0.851,0.85-0.851h2.549c0.469,0,0.85,0.381,0.85,0.851C25.075,31.38,24.694,31.76,24.226,31.76z M24.226,28.52h-2.549c-0.469,0-0.85-0.381-0.85-0.85s0.381-0.85,0.85-0.85h2.549c0.469,0,0.85,0.381,0.85,0.85S24.694,28.52,24.226,28.52z M24.226,25.28h-2.549c-0.469,0-0.85-0.38-0.85-0.849c0-0.471,0.381-0.851,0.85-0.851h2.549c0.469,0,0.85,0.38,0.85,0.851C25.075,24.9,24.694,25.28,24.226,25.28z M24.226,22.041h-2.549c-0.469,0-0.85-0.381-0.85-0.85s0.381-0.851,0.85-0.851h2.549c0.469,0,0.85,0.382,0.85,0.851S24.694,22.041,24.226,22.041z M25.126,18.38H9.356V2.771h15.77V18.38z"
			clipRule="evenodd"
		/>
	</svg>
)
const IconLoginPassword = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 26">
		<path
			fillRule="evenodd"
			d="M12.501 18.5c-3.31 0-6.428-1.951-8.808-5.503C6.073 9.449 9.19 7.5 12.5 7.5c3.31 0 6.426 1.95 8.807 5.502-2.382 3.549-5.5 5.498-8.807 5.498zm-9.827-5.232C5.266 17.287 8.756 19.5 12.5 19.5c3.743 0 7.232-2.211 9.825-6.227l.174-.271-.174-.272c-2.59-4.017-6.08-6.23-9.825-6.23-3.744 0-7.234 2.211-9.827 6.226l-.174.271.174.271zM10.002 13c0-1.38 1.12-2.5 2.498-2.5A2.502 2.502 0 0115 13c0 1.379-1.121 2.5-2.5 2.5a2.502 2.502 0 01-2.498-2.5zm-1 0c0 1.93 1.57 3.5 3.498 3.5 1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5A3.503 3.503 0 009.003 13z"
			clipRule="evenodd"
		/>
		<path
			fillRule="evenodd"
			d="M12.501 18.5c-3.31 0-6.428-1.951-8.808-5.503C6.073 9.449 9.19 7.5 12.5 7.5c3.31 0 6.426 1.95 8.807 5.502-2.382 3.549-5.5 5.498-8.807 5.498zm-9.827-5.232C5.266 17.287 8.756 19.5 12.5 19.5c3.743 0 7.232-2.211 9.825-6.227l.174-.271-.174-.272c-2.59-4.017-6.08-6.23-9.825-6.23-3.744 0-7.234 2.211-9.827 6.226l-.174.271.174.271zM10.002 13c0-1.38 1.12-2.5 2.498-2.5A2.502 2.502 0 0115 13c0 1.379-1.121 2.5-2.5 2.5a2.502 2.502 0 01-2.498-2.5zm-1 0c0 1.93 1.57 3.5 3.498 3.5 1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5A3.503 3.503 0 009.003 13z"
			clipRule="evenodd"
		/>
	</svg>
)

const IconRefresh = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			d="M12.26 20.52c-4.55 0-8.26-3.7-8.26-8.26C4 7.701 7.71 4 12.26 4c3.742 0 6.908 2.499 7.918 5.916l-1.522-.845-.486.875 2.819 1.565 1.696-2.726-.85-.528-.745 1.199C19.9 5.712 16.387 3 12.26 3 7.16 3 3 7.15 3 12.26c0 5.111 4.16 9.26 9.26 9.26 4.2 0 7.76-2.81 8.89-6.649h-1.04c-1.1 3.279-4.2 5.649-7.85 5.649z"
		/>
		<path
			d="M12.26 20.52c-4.55 0-8.26-3.7-8.26-8.26C4 7.701 7.71 4 12.26 4c3.742 0 6.908 2.499 7.918 5.916l-1.522-.845-.486.875 2.819 1.565 1.696-2.726-.85-.528-.745 1.199C19.9 5.712 16.387 3 12.26 3 7.16 3 3 7.15 3 12.26c0 5.111 4.16 9.26 9.26 9.26 4.2 0 7.76-2.81 8.89-6.649h-1.04c-1.1 3.279-4.2 5.649-7.85 5.649z"
		/>
	</svg>
)

const IconSearch = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 26">
		<path
			fill="#0000bc"
			fillRule="evenodd"
			d="M16.247 16.035a7.558 7.558 0 10-.713.713l5.753 5.752.713-.713-5.753-5.752zm-5.689 1.574a6.55 6.55 0 110-13.101 6.55 6.55 0 010 13.1z"
			clipRule="evenodd"
		/>
		<path
			fill="#0000bc"
			fillRule="evenodd"
			d="M16.247 16.035a7.558 7.558 0 10-.713.713l5.753 5.752.713-.713-5.753-5.752zm-5.689 1.574a6.55 6.55 0 110-13.101 6.55 6.55 0 010 13.1z"
			clipRule="evenodd"
		/>
	</svg>
)
const IconSession1 = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			fill="#0000bc"
			d="M18.775 17l-.762-.682 3.162-3.337H22v-.984h-.836l-3.15-3.295.761-.702 4.256 4.493L18.775 17zm2.39-5.003H8v.984h13.175l.463-.489-.474-.495z"
		/>
		<path
			fill="#0000bc"
			d="M18.775 17l-.762-.682 3.162-3.337H22v-.984h-.836l-3.15-3.295.761-.702 4.256 4.493L18.775 17zm2.39-5.003H8v.984h13.175l.463-.489-.474-.495z"
		/>
	</svg>
)
const IconSession2 = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			fill="#0000bc"
			d="M10 14.98h12v-.983H10v.984zm8.013 3.338l.762.682 4.256-4.507L18.775 10l-.762.702 3.625 3.79-3.625 3.826zM15 10.981H3v-.984h12v.984zm-7.982 3.337L6.256 15 2 10.493 6.256 6l.762.702-3.625 3.79 3.625 3.826z"
		/>
	</svg>
)
const IconSession3 = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			fill="#0000bc"
			d="M10.348 9.745c0 .962.66 1.43 1.75 1.786l.216-3.462c-1.306.069-1.966.783-1.966 1.676zm4.447 5.097c0-.838-.416-1.4-1.764-1.827l-.215 3.669c1.161-.083 1.98-.701 1.98-1.842zM12.658 19.5h-1.019l.115-1.882c-1.477-.206-2.367-.99-2.754-1.814v-.77h.904c.3.798.918 1.402 1.907 1.595l.23-3.902h-.029c-1.994-.536-2.854-1.457-2.854-2.9 0-1.538 1.205-2.65 3.199-2.733l.1-1.594h1.004l-.1 1.649c1.276.192 2.022.851 2.28 1.73v.77h-.903c-.144-.618-.531-1.25-1.435-1.484l-.2 3.655.129.041C15.039 12.37 16 13.25 16 14.842c0 1.677-1.32 2.748-3.242 2.83l-.1 1.828z"
		/>
	</svg>
)
const IconSession4 = ({ className, },) => (
	<svg className={className} viewBox="0 0 25 25">
		<path
			fill="#0000bc"
			d="M17.03 12.98h-14v-.983h14v.984zM7.019 16.319L6.256 17 2 12.493 6.256 8l.762.702-3.625 3.79 3.625 3.826z"
		/>
	</svg>
)

const IconSetting = ({ className, },) => (
	<svg className={className} viewBox="0 0 36 35">
		<path
			d="M17.917 10.62c-3.92 0-7.11 3.086-7.11 6.88s3.19 6.88 7.11 6.88c3.92 0 7.11-3.086 7.11-6.88s-3.19-6.88-7.11-6.88zm0 15.48c-4.9 0-8.887-3.858-8.887-8.6 0-4.742 3.987-8.6 8.887-8.6s8.887 3.858 8.887 8.6c0 4.742-3.987 8.6-8.887 8.6zm-1.187 6.88h2.374l1.214-2.788.45-.095a13.477 13.477 0 004.331-1.74l.388-.241 2.902 1.144 1.68-1.626-1.182-2.808.248-.375a12.583 12.583 0 001.8-4.192l.097-.435 2.88-1.175v-2.298l-2.88-1.173-.098-.437a12.583 12.583 0 00-1.799-4.191l-.248-.377 1.181-2.807-1.68-1.624-2.901 1.144-.388-.243a13.45 13.45 0 00-4.331-1.739l-.45-.096-1.214-2.788H16.73l-1.214 2.788-.45.096a13.45 13.45 0 00-4.331 1.74l-.388.242-2.902-1.144-1.68 1.624 1.182 2.807-.249.377A12.582 12.582 0 004.9 14.74l-.098.437-2.881 1.173v2.298l2.88 1.175.099.435c.334 1.484.94 2.895 1.798 4.192l.25.375-1.183 2.808 1.68 1.626 2.902-1.144.388.24c1.34.831 2.797 1.418 4.331 1.741l.45.095 1.214 2.788zm3.554 1.72H15.55l-1.318-3.025a15.168 15.168 0 01-4.059-1.634l-3.151 1.241-3.347-3.238 1.283-3.05a14.156 14.156 0 01-1.688-3.928L.144 19.79V15.21l3.126-1.274c.357-1.38.924-2.697 1.688-3.93L3.675 6.957l3.347-3.24 3.151 1.24a15.242 15.242 0 014.06-1.632L15.549.3h4.735l1.317 3.026a15.24 15.24 0 014.06 1.632l3.15-1.24 3.348 3.24-1.284 3.046a14.209 14.209 0 011.689 3.93l3.126 1.275v4.582l-3.126 1.275a14.157 14.157 0 01-1.689 3.928l1.284 3.05-3.347 3.238-3.151-1.241a15.167 15.167 0 01-4.06 1.634L20.284 34.7z"
			clipRule="evenodd"
		/>
	</svg>
)

const IconArrow = ({ className, },) => (
	<svg className={className} viewBox="0 0 490.688 490.688">
		<path d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262
	c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667
	c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262
	L472.328,120.529z"
		/>
		<path d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262
	c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279
	C250.748,372.281,248.039,373.408,245.213,373.415z"
		/>
	</svg>
)

const IconMail = ({ className, },) => (
	<svg className={className} viewBox="0 0 512 512">
		<path d="M485.743,85.333H26.257C11.815,85.333,0,97.148,0,111.589V400.41c0,14.44,11.815,26.257,26.257,26.257h459.487
		c14.44,0,26.257-11.815,26.257-26.257V111.589C512,97.148,500.185,85.333,485.743,85.333z M475.89,105.024L271.104,258.626
		c-3.682,2.802-9.334,4.555-15.105,4.529c-5.77,0.026-11.421-1.727-15.104-4.529L36.109,105.024H475.89z M366.5,268.761
		l111.59,137.847c0.112,0.138,0.249,0.243,0.368,0.368H33.542c0.118-0.131,0.256-0.23,0.368-0.368L145.5,268.761
		c3.419-4.227,2.771-10.424-1.464-13.851c-4.227-3.419-10.424-2.771-13.844,1.457l-110.5,136.501V117.332l209.394,157.046
		c7.871,5.862,17.447,8.442,26.912,8.468c9.452-0.02,19.036-2.6,26.912-8.468l209.394-157.046v275.534L381.807,256.367
		c-3.42-4.227-9.623-4.877-13.844-1.457C363.729,258.329,363.079,264.534,366.5,268.761z"
		/>
	</svg>
)

const IconElement = ({ name, className, },) => {
	if (name === ICON_ADD) return <IconAdd className={className} />
	if (name === ICON_DELETE) return <IconDelete className={className} />
	if (name === ICON_EDIT) return <IconEdit className={className} />
	if (name === ICON_INDEX_ITEM_ARROW) return <IconIndexItemAlert className={className} />
	if (name === ICON_LOGIN_NAME) return <IconLoginName className={className} />
	if (name === ICON_LOGIN_PHONE) return <IconLoginPhone className={className} />
	if (name === ICON_LOGIN_PASSWORD) return <IconLoginPassword className={className} />
	if (name === ICON_REFRESH) return <IconRefresh className={className} />
	if (name === ICON_SEARCH) return <IconSearch className={className} />
	if (name === ICON_SESSION_1) return <IconSession1 className={className} />
	if (name === ICON_SESSION_2) return <IconSession2 className={className} />
	if (name === ICON_SESSION_3) return <IconSession3 className={className} />
	if (name === ICON_SESSION_4) return <IconSession4 className={className} />
	if (name === ICON_SETTING) return <IconSetting className={className} />
	if (name === ICON_ARROW) return <IconArrow className={className} />
	if (name === ICON_MAIL) return <IconMail className={className} />
	return null
}

export default IconElement
