// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, } from 'react'
import cn from 'classnames'
import { createPortal, } from 'react-dom'
import styles from './modal.module.css'

const getSideStyle = (side, menu,) => {
	if (side === 'right') return styles.sideRight
	if (menu) return styles.sideMenu
	return styles.sideCenter
}

const WrapperElement = ({ children, side, menu, },) => (
	<div className={cn(styles.wrapper, getSideStyle(side, menu,),)}>{children}</div>
)

const ContainerElement = ({ children, },) => (
	<div className={cn(styles.container,)}>{children}</div>)

// eslint-disable-next-line max-len
// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
const MaskElement = ({ onClose, },) => <div className={styles.mask} onClick={onClose} />

const ModalComponent = ({
	children,
	onClose,
	isOpen,
	side,
	menu,
},) => {
	const el = useRef(typeof window !== 'undefined' ? document.createElement('div',) : null,)
	useEffect(() => {
		document.body.appendChild(el.current,)
		// eslint-disable-next-line no-return-assign
		return () => {
			if (el.current.parentElement) {
				el.current.parentElement.removeChild(el.current,)
			}
		}
	}, [],)
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'inherit'
		}
	}, [isOpen, ],)

	useEffect(() => () => {
		document.body.style.overflow = 'inherit'
	}, [],)

	if (isOpen === false) {
		return null
	}
	return createPortal(
		<WrapperElement side={side} menu={menu}>
			<MaskElement onClose={onClose} />
			<ContainerElement>
				{children}
			</ContainerElement>
		</WrapperElement>,
		el.current,
	)
}

ModalComponent.defaultProps = {
	domNode: 'root',
	side: 'center',
	title: '',
	isOpen: false,
	context: false,
	search: false,
	searchClick: () => {},
}

export default ModalComponent
