import { useState, useEffect, } from 'react'

export const getInitialState = (ssrWidth = 1280, ssrHeight = 1024,) => (typeof window !== 'undefined'
	? {
		width: window.innerWidth,
		height: window.innerHeight,
	}
	: {
		width: ssrWidth,
		height: ssrHeight,
	})

export const useWindowSize = (ssrWidth = 1280, ssrHeight = 1024,) => {
	const [windowSize, setWindowSize, ] = useState(getInitialState(ssrWidth, ssrHeight,),)

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (typeof window !== 'undefined') {
			// eslint-disable-next-line max-len
			const onResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight, },)
			window.addEventListener('resize', onResize,)
			return () => window.removeEventListener('resize', onResize,)
		}
	},)

	return windowSize
}
