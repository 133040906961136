export const ICON_ADD = 'add'
export const ICON_DELETE = 'delete'
export const ICON_EDIT = 'edit'
export const ICON_INDEX_ITEM_ARROW = 'index-item-arrow'
export const ICON_LOGIN_NAME = 'login-name'
export const ICON_LOGIN_PHONE = 'login-phone'
export const ICON_LOGIN_PASSWORD = 'login-password'
export const ICON_REFRESH = 'refresh'
export const ICON_SEARCH = 'search'
export const ICON_SESSION_1 = 'session-1'
export const ICON_SESSION_2 = 'session-2'
export const ICON_SESSION_3 = 'session-3'
export const ICON_SESSION_4 = 'session-4'
export const ICON_SETTING = 'setting'
export const ICON_ARROW = 'arrow'
export const ICON_MAIL = 'mail'
