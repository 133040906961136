// eslint-disable-next-line no-unused-vars
import React from 'react'
import cn from 'classnames'
import styles from './smallSwitch.module.css'

const SmallSwitchElement = ({
	id,
	value,
	placeholder,
	disabled,
	className,
	classNameLabel,
	onChange,
},) => (disabled ? null : (
	<div className={cn(styles.input, className,)}>
		{/* eslint-disable-next-line max-len */}
		{/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
		<label
			className={cn(styles.smallSwitch, styles.smallSwitchInRow, classNameLabel,)}
		>
			<input type="checkbox" checked={!!value} onChange={() => onChange(!value, id,)} />
			<div className={styles.smallSwitchIcon}><i /></div>
			<div className={styles.smallSwitchLabel}>{placeholder}</div>
		</label>
	</div>
))

export default SmallSwitchElement
