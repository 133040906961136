// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, } from 'react'
import ModalComponent from './ModalComponent'
import styles from './modal.module.css'

const TextModalComponent = ({
	title,
	description,
	textButton,
	cancelButton,
	onClick,
	onClose,
	children,
	...props
},) => (
	<ModalComponent {...props} onClose={onClose}>
		<div>
			{!!title && <div className={styles.title}>{title}</div>}
			{!!description && <div className={styles.text}>{description}</div>}
			{!!children && children}
			<div className={styles.buttonWrapper}>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
				{!!cancelButton && <div className={styles.cancel} onClick={onClose}>{cancelButton}</div>}
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
				{!!textButton && <div className={styles.footer} onClick={onClick}>{textButton}</div>}
			</div>
		</div>
	</ModalComponent>
)

export default TextModalComponent
