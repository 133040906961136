import { gql, } from 'apollo-boost'

export const GET_DISCOUNT_TYPE_API = gql`
	query getDiscountTypeListBlocks($cardId: String!) {
		cards: getDiscountTypeList(cardId: $cardId) {
			paymentAmount
			area
			transfer {
				datetime
				area
				paymentAmount
				duration
			}
			licensePlate
			discountTypes {
				id
				name
				description
				applicationArea
				paymentAmount
				transfer {
					datetime
					area
					paymentAmount
					duration
				}
			}
		}
	}
`

export const ADD_DISCOUNT_API = gql`
	mutation registerDiscount(
		$cardId: String!
		$discountTypeId: Int!
		$comment: String!
	) {
		discount: registerDiscount(
			cardId: $cardId
			discountTypeId: $discountTypeId
			comment: $comment
		)
	}
`
