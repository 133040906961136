// eslint-disable-next-line no-unused-vars
import React from 'react'
import { ifExistCall, } from '../utils/FunctionUtil'
import SVGIcon from './SVGIcon'

const RangePickerInput = ({
	onClick,
	onClear,
	startValue,
	endValue,
	placeholder,
},) => {
	// eslint-disable-next-line max-len
	const handleClick = (fieldType,) => () => ifExistCall(onClick, fieldType,)
	const handleClear = (e,) => {
		e.stopPropagation()
		ifExistCall(onClear,)
	}

	return (
		// eslint-disable-next-line max-len
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className="picker-input_button" onClick={handleClick('',)}>
			{!startValue && !endValue && <span className="picker-input_button-placeholder">{placeholder}</span>}
			{!!startValue && <span>{startValue}</span>}
			{!!endValue && <span>{endValue}</span>}
			{/* eslint-disable-next-line max-len */}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<span className="picker-input__clear" onClick={handleClear}>
				<SVGIcon id="clear" />
			</span>
		</div>
	)
}

export default RangePickerInput
