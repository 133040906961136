import { USER_LOADING_UPDATE, REMOVE_USER_CONFIG, SET_USER_CONFIG, } from '../actions/userActions'
import storageHelper from '../../helpers/storageHelper'

const userEmpty = {
	token: '',
	user: { user: {}, contact: {}, company: {}, },
	loading: false,
}

const getInitialUserState = () => {
	const token = storageHelper.get('__token__', 'cookie',) || ''
	const user = storageHelper.get('__user__', 'localStorage',) || userEmpty.user
	if (!token) return userEmpty
	return {
		token,
		user,
		loading: false,
	}
}

const initialState = getInitialUserState()

export default (state = initialState, action = {},) => {
	switch (action.type) {
	case SET_USER_CONFIG:
		return {
			...state,
			user: action.user,
			token: action.token,
		}
	case REMOVE_USER_CONFIG:
		return userEmpty
	case USER_LOADING_UPDATE:
		return {
			...state,
			loading: action.loading,
		}
	default:
		return state
	}
}
