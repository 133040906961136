// eslint-disable-next-line no-unused-vars
import React from 'react'
import { ifExistCall, } from '../utils/FunctionUtil'

const Cell = ({ className, text, subText, onClick, onMouseOver, },) => (
	// eslint-disable-next-line max-len
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
	<td
		onClick={() => ifExistCall(onClick, text,)}
		onMouseOver={() => ifExistCall(onMouseOver, text,)}
		className={className}
	>
		<div>{text}</div>
		{subText && <span className="sub__text">{subText}</span>}
	</td>
)

export default Cell
