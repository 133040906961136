// eslint-disable-next-line no-unused-vars
import React from 'react'
import moment from 'moment'
import { DatePickerDefaults, } from '../common/Constant'
import { getMonthMatrix, getYearMatrix, } from '../utils/DateUtil'
import DayView from './DayView'
import TableCell from './TableCell'
import TableMatrixView from './TableMatrixView'

const YEAR_VIEW_CLASS = 'calendar__year'
const MONTH_VIEW_CLASS = 'calendar__month'

const buildMatrixView = (
	matrix,
	className,
	onClick,
) => (
	<TableMatrixView
		matrix={matrix}
		cell={(value, key,) => (
			<TableCell key={key} className={className} text={value} onClick={onClick(key, value,)} />
		)}
	/>
)

const CalendarBody = ({ current, onClick, locale, viewMode, ...props },) => {
	const viewMap = {
		YEAR: buildMatrixView(
			getYearMatrix(moment(current,).year(),),
			YEAR_VIEW_CLASS,
			(_, v,) => () => onClick(v,),
		),
		MONTH: buildMatrixView(
			getMonthMatrix(locale,),
			MONTH_VIEW_CLASS,
			(k, _,) => () => onClick(String(k,),),
		),
		DAY: <DayView {...{ ...props, current, onClick, locale, viewMode, }} />,
	}

	return <div className="calendar__body">{viewMap[viewMode]}</div>
}

CalendarBody.defaultProps = {
	viewMode: 'DAY',
	locale: DatePickerDefaults.locale,
}

export default CalendarBody
