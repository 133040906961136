// eslint-disable-next-line no-unused-vars
import React from 'react'
import classNames from 'classnames'

const TableMatrixView = ({ className, matrix, cell, headers, },) => (
	<table className={classNames('calendar__body--table', className,)}>
		{headers && (
			<thead>
				<tr>
					{headers.map((v, i,) => (
						// eslint-disable-next-line react/no-array-index-key
						<th key={i}>{v}</th>
					),)}
				</tr>
			</thead>
		)}
		<tbody>
			{matrix.map((row, i,) => (
				// eslint-disable-next-line react/no-array-index-key
				<tr key={i}>{row.map((v, j,) => cell(v, i * matrix[i].length + j,),)}</tr>
			),)}
		</tbody>
	</table>
)

export default TableMatrixView
