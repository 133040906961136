// eslint-disable-next-line no-unused-vars
import React, { Fragment, } from 'react'
import cn from 'classnames'
import styles from './table.module.css'
import TooltipElement from '../tooltip/TooltipElement'

const Row = ({
	getField,
	getTooltip,
	id,
	colspan = 1,
	onCollapse,
	isNotCollapsed,
	collapsedField,
	className,
	item,
	openId,
	currentItem,
},) => (
	// eslint-disable-next-line max-len
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
	<td
		key={id}
		colSpan={colspan}
		// eslint-disable-next-line max-len
		className={cn((!onCollapse || (!!collapsedField && !collapsedField(item,))) && styles.notHovered, className,)}
		onClick={() => (
			// eslint-disable-next-line max-len,no-nested-ternary
			isNotCollapsed ? null
				: (!collapsedField || (!!collapsedField && collapsedField(item,)))
					? onCollapse(openId !== item[currentItem] ? item[currentItem] : null,) : null)}
	>
		{id === 'arrow' && (!collapsedField || (!!collapsedField && collapsedField(item,))) && (<div className={styles.staffItemArrow} />)}
		{id !== 'arrow' && !getTooltip && getField(item,)}
		{id !== 'arrow' && !!getTooltip && (
			<TooltipElement title={getTooltip(item,)}>{getField(item,)}</TooltipElement>
		)}
	</td>
)

const Header = ({
	name,
	isSorted,
	onUpdateSort,
	sort,
	id,
},) => (
	<th>
		{!!name && (
			// eslint-disable-next-line max-len
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
			<span
				onClick={() => {
					if (isSorted) onUpdateSort(id,)
				}}
				className={cn(
					styles.staffHeader,
					isSorted && styles.staffSort,
					isSorted && id === sort.field && styles.staffSortCurrent,
					isSorted && id === sort.field && sort.direction === 'DESC' && styles.isDesc,
				)}
			>
				{name}
			</span>
		)}
	</th>
)

const TableComponent = ({
	fields,
	fieldsLength,
	content,
	currentItem = 'id',
	isNotCollapsed,
	collapsedField = null,
	openId,
	disabledField = [],
	sort = {},
	onUpdateSort,
	onCollapse,
	children,
},) => (
	<div className={styles.staff}>
		<table>
			<thead>
				<tr>
					{/* eslint-disable-next-line max-len */}
					{fields.map((contentHeader,) => (contentHeader.headers ? contentHeader.headers.map((props,) => (
						// eslint-disable-next-line react/jsx-key
						<Header
							name={props.name}
							isSorted={!!props.isSorted}
							onUpdateSort={onUpdateSort}
							sort={sort}
							key={props.id + props.id}
							id={props.id}
						/>
					),) : (
						<Header
							name={contentHeader.name}
							isSorted={!!contentHeader.isSorted}
							onUpdateSort={onUpdateSort}
							sort={sort}
							key={contentHeader.name}
							id={contentHeader.id}
						/>
					)),)}
				</tr>
			</thead>
			<tbody>
				{content.map((item, index,) => (
					// eslint-disable-next-line react/no-array-index-key
					<Fragment key={`${(item.id || item.i)}-${index}`}>
						<tr
							className={cn(
								styles.staffItem,
								!disabledField.reduce((acc, name,) => acc[name],
									item,) && styles.staffItemDisabled,
							)}
						>
							{/* eslint-disable */}
								{fields.map(({type, ...props}) => type === 'if' ? (
									 props[props.validate(item) ? 'if' : 'else'].map((content) => (
									 	<Row
											getField={content.getField}
											getTooltip={content.getTooltip}
											id={content.id}
											colspan={content.colspan}
											onCollapse={onCollapse}
											isNotCollapsed={isNotCollapsed}
											collapsedField={collapsedField}
											className={content.className || ''}
											item={item}
											openId={openId}
											currentItem={currentItem}
											key={content.id}
										/>
									 ))
									 ) : (
									<Row
										getField={props.getField}
										getTooltip={props.getTooltip}
										id={props.id}
										colspan={props.colspan}
										className={props.className || ''}
										onCollapse={onCollapse}
										collapsedField={collapsedField}
										isNotCollapsed={isNotCollapsed}
										item={item}
										openId={openId}
										key={props.id}
										currentItem={currentItem}
									/>
								),)}
								{/* eslint-enable */}
						</tr>

						{/* eslint-disable-next-line max-len */}
						<tr
							className={cn(
								styles.staffItemContent,
								(!onCollapse || openId !== item[currentItem]) && styles.isHidden,
							)}
						>
							<td colSpan={fieldsLength || fields.length}>
								{!!onCollapse && openId === item[currentItem] && children}
							</td>
						</tr>
					</Fragment>
				),)}
			</tbody>
		</table>
	</div>
)

export default TableComponent
