// eslint-disable-next-line no-unused-vars
import React, { useState, } from 'react'
// import styles from './guestPage.module.css'
import moment from 'moment'
import _ from 'lodash'
import { useMutation, useQuery, } from '@apollo/react-hooks'
import XLSX from 'xlsx'
import { connect, } from 'react-redux'
import { toast, } from 'react-toastify'
import MainComponent from '../../components/main/MainComponent'
import FilterElement from '../../elements/filter/FilterElement'
import TableComponent from '../../elements/table/TableComponent'
import {
	GET_EMPLOYEE_API,
	GET_FREE_CARDS_API,
	DELETE_EMPLOYEE_API,
	UPDATE_EMPLOYEE_API,
	GET_AREA_LIST_API,
} from '../../api/employee/employeeQuery'
import PaginationElement from '../../elements/pagination/PaginationElement'
import CurrentEmployerComponent from '../../components/currentEmployer/CurrentEmployerComponent'
import { getContentLoadingSelector, } from '../../store/selectors/contentSelector'
import { onUserAction, } from '../../store/actions/contentActions'
import {
	getUserCompanySelector,
} from '../../store/selectors/userSelector'
import { getMessageError, } from '../../utilits/getMessageTextUtilit'
import { getEmployersFilterUtil, } from '../../utilits/getMainDataUtilit'
import {
	getEmployeeStatusIsActive,
	getEmployeeStatusUtil,
	getVehicleMass,
} from '../../utilits/getVehicleUtilit'

const FIELDS_TABLE = [
	{
		name: 'ФИО',
		id: 'NAME',
		isSorted: true,
		getField: (source,) => `${source.firstName} ${source.middleName} ${source.lastName}`,
	},
	{
		name: 'EMAIL',
		id: 'EMAIL',
		getField: (source,) => source.email,
	},
	{
		name: 'Телефон',
		id: 'USER_ID',
		getField: (source,) => source.phone,
	},
	{
		name: 'Срок действия',
		id: 'BLOCKED_DATE',
		isSorted: true,
		getField: (source,) => (
			source.blockedDate ? moment(source.blockedDate,)
				.format('DD.MM.YYYY',) : 'Нет'
		),
	},
	{
		name: 'Статус',
		id: 'STATUS',
		getField: (source,) => getEmployeeStatusUtil(source.vehicles,),
	},
	{
		name: '',
		id: 'arrow',
	},
]

const SEARCH_FIELD = [
	{
		id: 'name',
		value: '',
		defaultValue: '',
		placeholder: 'ФИО',
		type: 'text',
		error: '',
	},
	{
		id: 'includedInCalculation',
		value: null,
		defaultValue: null,
		placeholder: 'На балансе',
		type: 'boolButton',
		error: '',
	},
	{
		id: 'area',
		value: [],
		defaultValue: [],
		placeholder: 'Зона парковки',
		type: 'select',
		notFound: 'Нет доступных зон',
		isButton: true,
		options: [],
		error: '',
	},
	{
		id: 'blockedDateFrom&blockedDateTo',
		value: [],
		placeholder: 'Срок действия',
		type: 'range',
		format: 'DD.MM.YYYY',
		error: '',
	},
]

const getIsActive = (source,) => getEmployeeStatusIsActive(source.vehicles,)

const initState = {
	name: '',
	area: null,
	includedInCalculation: null,
	blockedDateFrom: null,
	blockedDateTo: null,
	from: 0,
	size: 10,
}

// eslint-disable-next-line max-len
const EmployersPageComponent = ({ content, state, updateState, loading, cards, areas, onDelete, onUpdate, onUnload, company, sort, onUpdateSort, },) => {
	const [openId, onOpenId, ] = useState(null,)

	return (
		<MainComponent
			isWhite
			isClosed
			addLink="/employee"
			title="Сотрудники"
		>
			<FilterElement
				onDownload={onUnload}
				// eslint-disable-next-line max-len
				onUpdate={(variables,) => updateState({ ...state, ...variables, area: variables.area ? parseInt(variables.area, 10,) : null, },)}
				fields={getEmployersFilterUtil(state, areas,)(SEARCH_FIELD,)}
				buttonName="Применить"
			/>
			{!(loading || !content.items.length) && (
				<>
					<TableComponent
						fields={FIELDS_TABLE}
						content={content.items.map((item,) => ({
							...item,
							blockedDate: item.blockedDate ? item.blockedDate : company.blockedDate,
							isActive: getIsActive(item,),
						}),)}
						openId={openId}
						sort={sort}
						disabledField={['isActive', ]}
						onCollapse={(id,) => onOpenId(id,)}
						onUpdateSort={onUpdateSort}
					>
						{!!openId && !!content.itemsContent[openId] && (
							<CurrentEmployerComponent
								id={openId}
								content={{
									...content.itemsContent[openId],
									blockedDate: content.itemsContent[openId].blockedDate
										? content.itemsContent[openId].blockedDate : company.blockedDate,
								}}
								onUpdate={onUpdate}
								onDelete={() => onDelete(openId,)}
								cards={cards}
							/>
						)}
					</TableComponent>
					<PaginationElement
						totalCount={content.pagination.total}
						pageSize={content.pagination.size}
						page={(content.pagination.from / content.pagination.size) + 1}
						onChange={(page,) => {
							onOpenId(null,)
							updateState({
								...state,
								from: (page - 1) * content.pagination.size,
							},)
						}}
					/>
				</>
			)}
		</MainComponent>
	)
}

const Loader = ({ onCallActionUser, company, },) => {
	const [content, updateContent, ] = useState({
		itemsContent: {},
		items: [],
		pagination: {},
	},)

	const [cards, updateCards, ] = useState([],)
	const [areas, updateAreas, ] = useState([],)
	const [sort, updateSort, ] = useState({
		field: 'NAME',
		direction: 'DESC',
	},)
	const [state, updateState, ] = useState(initState,)

	const [updateEmployee, ] = useMutation(UPDATE_EMPLOYEE_API,)
	const [deleteEmployee, ] = useMutation(DELETE_EMPLOYEE_API,)

	const onUpdateContent = (users,) => {
		const items = users.items.map((item,) => ({
			...item,
			rights: (item.rights || [])
				.reduce((acc, right,) => (right ? [...acc, right, ] : acc), [],),
		}),)
		const { pagination, } = users
		if (!items.length && state.from > 0) {
			updateState({
				...state,
				from: state.from - state.size,
			},)
		} else {
			updateContent({
				itemsContent: items.reduce((acc, item,) => ({
					...acc,
					[item.id]: item,
				}), {},),
				items,
				pagination,
			},)
		}
	}

	const { loading: cardLoading, } = useQuery(GET_FREE_CARDS_API,
		{
			onCompleted: (data,) => {
				updateCards(data.cards,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const { loading: areaLoading, } = useQuery(GET_AREA_LIST_API,
		{
			onCompleted: (data,) => {
				updateAreas(data.areas,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const { loading, refetch, fetchMore, } = useQuery(GET_EMPLOYEE_API,
		{
			variables: {
				...state,
				...sort,
			},
			onCompleted: (data,) => {
				onUpdateContent(data.employees,)
			},
			onError: (e,) => {
				toast.error(getMessageError(e.message,), {
					position: toast.POSITION.TOP_RIGHT,
				},)
			},
			notifyOnNetworkStatusChange: true,
		},)

	const onUpdateSort = (name,) => updateSort({
		field: name,
		// eslint-disable-next-line no-nested-ternary
		direction: name !== sort.field ? 'DESC' : (sort.direction === 'DESC' ? 'ASC' : 'DESC'),
	},)

	const onUpdateUser = async (variables, id,) => {
		const indexes = {
			addVehicle: {},
			updateVehicle: {},
		}
		try {
			const { vehicles, } = content.itemsContent[id]
			const deleted = []
			const updated = []
			const added = []
			const allVehicles = vehicles.reduce((acc, item,) => ({ ...acc, [item.id]: item, }), {},)
			const newVehiclesIds = variables.vehicleList.reduce((acc, item,) => [ ...acc, item.id, ], [],)
			variables.vehicleList.forEach((item, index,) => {
				if (!item.id && !!item.model && !!item.licensePlate) {
					added.push({
						model: item.model,
						licensePlate: item.licensePlate,
						cardId: item.cardId,
						includedInCalculation: item.includedInCalculation,
						status: item.status,
					},)
					indexes.addVehicle[index] = added.length - 1
					return
				}

				if (!_.isEqualWith({
					id: item.id,
					model: item.model,
					licensePlate: item.licensePlate,
					cardId: item.cardId,
					includedInCalculation: item.includedInCalculation,
					status: item.status,
					newCardId: item.newCardId,
					cardLostDate: item.cardLostDate,
				}, {
					id: allVehicles[item.id].id,
					model: allVehicles[item.id].model,
					licensePlate: allVehicles[item.id].licensePlate,
					cardId: allVehicles[item.id].cardId,
					includedInCalculation: allVehicles[item.id].includedInCalculation,
					status: allVehicles[item.id].status,
					newCardId: allVehicles[item.id].newCardId,
					cardLostDate: allVehicles[item.id].cardLostDate,
				},) && getVehicleMass(allVehicles[item.id],) !== 1) {
					updated.push({
						id: item.id,
						model: item.model,
						licensePlate: item.licensePlate,
						cardId: item.cardId,
						includedInCalculation: item.includedInCalculation,
						status: item.status,
						newCardId: item.newCardId,
						cardLostDate: item.cardLostDate,
					},)
					indexes.updateVehicle[index] = updated.length - 1
				}
			},)

			vehicles.forEach((item,) => {
				if (newVehiclesIds.indexOf(item.id,) === -1) {
					deleted.push(item.id,)
				}
			},)
			const { data, } = await updateEmployee({
				variables: {
					...variables,
					addVehicle: added,
					updateVehicle: updated,
					deleteVehicle: deleted,
				},
			},)

			updateContent({
				// eslint-disable-next-line max-len
				itemsContent: content.items.reduce((acc, item,) => ({
					...acc,
					[item.id]: data.user.id === item.id ? data.user : item,
				}), {},),
				items: content.items.map((item,) => (data.user.id === item.id ? data.user : item),),
				pagination: content.pagination,
			},)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)

			return {
				variables,
				...indexes,
				validation: (e.graphQLErrors || []).reduce((app, { extensions, },) => (
					!!extensions && extensions.validation ? { ...app, ...extensions.validation, } : app
				), {},),
			}
		}
		return null
	}

	const onUnloadUsers = async () => {
		try {
			return fetchMore({
				variables: {
					from: 0,
					size: content.pagination.total || 0,
					name: state.name,
					area: state.area,
					includedInCalculation: state.includedInCalculation,
					blockedDateFrom: state.blockedDateFrom,
					blockedDateTo: state.blockedDateTo,
					...sort,
				},
				updateQuery: (prev, { fetchMoreResult, },) => {
					if (!fetchMoreResult) return prev
					// eslint-disable-next-line max-len
					const employees = XLSX.utils.json_to_sheet(fetchMoreResult.employees.items.map((item,) => ({
						id: item.id,
						Имя: item.firstName,
						Фамилия: item.lastName,
						Отчество: item.middleName,
						Телефон: item.phone,
						Email: item.email,
						Блокировка: item.blockDate ? moment(item.blockedDate,)
							.format('DD.MM.YYYY',) : 'Бессрочный',
						Статус: item.status ? 'Активен' : 'Не активен',
					}),),)
					const wb = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(wb, employees, 'employees',)
					XLSX.writeFile(wb, 'employees.xlsx',)
					return fetchMoreResult
				},
			},)
		} catch (e) {
			toast.error(getMessageError(e.message,), {
				position: toast.POSITION.TOP_RIGHT,
			},)
			console.log(e,)
		}
		return null
	}

	const onDeleteUser = async (id,) => {
		try {
			await onCallActionUser(deleteEmployee,)({ id, },)
			await refetch()
		} catch (e) {
			console.log(e,)
		}
	}

	return (
		<EmployersPageComponent
			loading={loading || cardLoading || areaLoading}
			state={state}
			updateState={updateState}
			content={content}
			cards={cards}
			areas={areas}
			company={company}
			sort={sort}
			onUpdate={onUpdateUser}
			onDelete={onDeleteUser}
			onUnload={onUnloadUsers}
			onUpdateSort={onUpdateSort}
		/>
	)
}

export default connect(
	(state,) => ({
		loading: getContentLoadingSelector(state,),
		company: getUserCompanySelector(state,),
	}),
	(dispatch,) => ({
		onCallActionUser: (action,) => (variables,) => dispatch(onUserAction(action, variables,),),
	}),
)(Loader,)
